<template>
    <div class="d-flex flex-column wizard wizard-resume mt-5" v-if="wizard.steps && wizard.steps.length > 0">
        <div class="py-4 px-sm d-flex flex-column steps-wrapper">
            <div class="d-flex w-100">
                <div>
                    <span class="text-muted">{{ activeWizardStep.title }}</span>
                    <p class="m-0">{{ activeWizardStep.description }}</p>
                </div>

                <div class="button-wrapper ml-auto d-flex">
                    <b-button class="my-auto" variant="success" size="md" @click="activeWizardStep.action">{{ activeWizardStep.actionLabel ? activeWizardStep.actionLabel : 'Update Now' }}</b-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        resume: {
            type: Object,
        },
        card: {
            type: Object,
        },
    },

    data() {
        return {
            errors: {
                resumeUpdate: null,
            },

            state: {
                activeWizardStepIndex: 0,
            },
        }
    },

    computed: {
        wizard() {
            const result = {
                completed_count: 0,
                steps: [
                {
                    title: `Start connecting with sponsors today`,
                    description: `Your professional media kit that updates automatically after every stream.`,
                    condition: (resume) => {
                        if (this.resume) return true
                        return false
                    },
                    value: false,
                    action: () => {
                        this.$router.push({
                            name: 'resume',
                        })
                    },
                    actionLabel: 'Get started',
                },

                {
                    title: `Create your Stream Card`,
                    description: `One simple link to guide viewers to all your content.`,
                    condition: (resume) => {
                        if (this.card) return true
                        return false
                    },
                    value: false,
                    action: () => {
                        this.$router.push({
                            name: 'streamcards',
                        })
                    },
                    actionLabel: 'Get started',
                },

                // {
                //     title: `Interests & Attributes`,
                //     description: `This important information is used to quickly convey to a sponsor what you`,
                //     condition: (resume) => {
                //         if (this.resume.interests && this.resume.attributes) return true
                //         return false
                //     },
                //     value: false,
                //     action: () => {
                //         this.$router.push({
                //             name: 'resume',
                //         })
                //     },
                // },

                // {
                //     title: `Language`,
                //     description: `This important information is used to quickly convey to a sponsor what you`,
                //     condition: (resume) => {
                //         if (this.resume.language) return true
                //         return false
                //     },
                //     value: false,
                //     action: () => {
                //         this.$router.push({
                //             name: 'resume',
                //         })
                //     },
                // },

                // {
                //     title: `Audience Demographics`,
                //     description: `You can provide this data by joining the Twitch team. Enabling this will greatly increase the chance sponsors will want to work with you.`,
                //     condition: (resume) => {
                //         if (this.resume.audience_demographics) return true
                //         return false
                //     },
                //     value: false,
                //     action: () => {
                //
                //     },
                // }
                ],
            }

            if (this.resume || this.card) {
                result.steps = _.reduce(result.steps, (incompleteSteps, step) => {
                    step.value = step.condition()
                    if (step.value === true) result.completed_count++

                    if (step.value === false) {
                        incompleteSteps.push(step)
                    }

                    return incompleteSteps
                }, [])
            }

            return result
        },

        activeWizardStep() {
            return this.wizard.steps[this.state.activeWizardStepIndex]
        },
    },

    methods: {
        changeActiveWizardStepIndex(amount) {
            const isIncrease = amount > 0
            const isDecrease = amount < 0

            if (!isIncrease && !isDecrease) return

            let newIndex = this.state.activeWizardStepIndex

            // Since negative is provided as argument in decrease cases, this works
            newIndex += amount

            // Don't go past bounds
            if (newIndex < 0 || newIndex > (this.wizard.steps.length - 1)) return
            this.state.activeWizardStepIndex = newIndex
        },
    }
}
</script>

<style lang="scss" scoped>
.wizard-resume {
    .steps-wrapper {
        border-radius: 12px;
        border: 1px solid #2dce89;
    }

    .steps-navigation {
        .prev, .next {
            cursor: pointer;
            transition: 0.25s all;

            &:hover:not(.disabled) {
                color: white;
            }

            &.disabled {
                opacity: 0.5;
                cursor: default;
            }
        }
    }
}
</style>
