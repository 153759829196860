import Vue from 'vue'
import _ from 'lodash'

export default {
    state: {
        user: false,
        connections: false,
        avatar: `/img/brand/streamforge-logo.png`,
        superviews: false,
    },

    mutations: {
        'self:user'(state, { user }) {
            Vue.set(state, 'user', user)
        },
        'self:clear'(state) {
            Vue.set(state, 'user', false)
            Vue.set(state, 'quests', [])
            Vue.set(state, 'avatar', `/img/brand/streamforge-logo.png`)
        },
        'self:avatar'(state, { id, avatar }) {
            Vue.set(state, 'avatar', avatar)
        },
        'self:superviews'(state, { superviews }) {
            Vue.set(state, 'superviews', superviews)
        },
        'self:achievements'(state, { achievements }) {
            Vue.set(state, 'achievements', achievements)
        },
        'self:points'(state, { points }) {
            Vue.set(state.user, 'points', points)
        },
        'self:points:add'(state, { points }) {
            Vue.set(state.user, 'points', state.user.points + points)
        },
        'self:points:subtract'(state, { points }) {
            Vue.set(state.user, 'points', state.user.points - points)
        },
    },

    getters: {
        self(state) {
            return state
        },
        selfAvatar(state) {
            return state.avatar
        },
        userRank(state) {
            const rank = _.get(state, 'superviews.twitch.rank')

            if (!rank) {
                return {
                    name: 'member',
                    index: 8,
                }
            }

            return rank
        },
        userAchievements(state) {
            return state.achievements
        },
        selfSuperviews(state) {
            return state.superviews
        },
    }
}
