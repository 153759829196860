<template>
    <SlideYUpTransition :duration="animationDuration">
        <div
            class="modal fade"
            @click.self="closeModal"
            :class="[{
                'show d-flex': show
            }, {
                'd-none': !show
            }]"
            v-show="show"
            tabindex="-1"
            role="dialog"
            :aria-hidden="!show"
        >
            <div class="m-auto">
                <slot></slot>
            </div>
        </div>
    </SlideYUpTransition>
</template>
<script>
import { SlideYUpTransition } from "vue2-transitions"

export default {
    name: 'player-modal',
    components: {
        SlideYUpTransition,
    },
    props: {
        show: Boolean,
        showClose: {
            type: Boolean,
            default: true
        },
        animationDuration: {
            type: Number,
            default: 500,
            description: "Modal transition duration"
        }
    },
    methods: {
        closeModal() {
            this.$emit("update:show", false)
            this.$emit("close")
        }
    },
    watch: {
        show(val) {
            let documentClasses = document.body.classList

            if (val) {
                documentClasses.add("modal-open")
            } else {
                documentClasses.remove("modal-open")
            }
        }
    }
}
</script>
<style lang="scss">
.modal.show {
    background-color: rgba(0, 0, 0, 0.3);
}
</style>
