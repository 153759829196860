<template>
    <div class="page-dashboard">
        <router-view :key="$route.path"/>
    </div>
</template>
<script>
import _ from 'lodash'
import moment from 'moment'
import numeral from 'numeral'
import { mapGetters } from 'vuex'

export default {
    name: 'dashboard',

    metaInfo() {
        return {
            title: 'Dashboard'
        }
    },
}
</script>
<style lang="scss">
    .page-dashboard {

        .box-promotion {
            height: 250px;
            border-radius: 12px;
            color: #fff;
            position: relative;
            overflow: hidden;
            position: relative;

            &:hover {
                .gradient {
                    opacity: 0.6;
                }

                .foreground {
                    // opacity: 0.1;
                }
            }

            .backdrop {
                transition: 0.25s all;
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                background-size: cover;
                background-position: center;
                // z-index: -5;
            }

            .gradient {
                transition: 0.25s all;
                opacity: 0.8;
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                background: linear-gradient(to right, #1e2029 0%, rgba(30, 32, 41, 0.95) 50%, rgba(30, 32, 41, 0.8) 100%);
            }

            .foreground {
                position: relative;
                z-index: 5;
                height: 100%;
                width: 100%;
                display: flex;
                transition: 0.25s all;

                .corner-content {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    max-width: 50%;
                }
            }
        }


        .row-loading-box {
            position: absolute;
            top: 0;
            left: 15px;
            width: 100%;
            height: 100%;
        }

        .loading-box {
            background: rgba(0, 0, 0, 0.1) !important;
            animation: fade 0.15s forwards !important;

            .loading {
                @keyframes fade-in-out {
                    0% {
                        opacity: 1;
                    }

                    50% {
                        opacity: 0.5;
                    }

                    100% {
                        opacity: 100%;
                    }
                }

                .loading {
                    display: flex;
                    height: 15px;
                    flex: 1;

                    &:before {
                        content: '';
                        // background: linear-gradient(to right, #12141d 10%, #151515 50%, #12141d 90%);
                        background: #12141d;
                        height: 100%;
                        width: 100%;
                        border-radius: 12px;
                        animation-name: moving-gradient;
                        animation-duration: 2s;
                        animation-iteration-count: infinite;
                        animation-timing-function: linear;
                        animation-fill-mode: forwards;
                        background: linear-gradient(to right, rgba(255, 255, 255, 0.025) 20%, rgba(255, 255, 255, 0.075) 50%, rgba(255, 255, 255, 0.025) 80%);
                        background-size: 500px 100%;
                    }

                    &:not(:last-child) {
                        margin-bottom: 15px;
                    }

                    &.right {
                        &:before {
                            margin-left: auto;
                        }
                    }
                }

                .loading-group {
                    display: flex;
                    flex-direction: row;

                    div:not(:last-child) {
                        margin-right: 10px;
                    }
                }

                .loading.long {
                    &:before {
                        width: 100%;
                    }
                }

                .loading.medium {
                    &:before {
                        width: 75%;
                    }
                }

                .loading.small {
                    &:before {
                        width: 50%;
                    }
                }
            }
        }

        .row:hover .header-info {
            opacity: 0.5;
        }

        .header-info {
            position: absolute;
            top: -8px;
            right: -16px;
            z-index: 100;
            cursor: pointer;
            opacity: 0.5;
            font-size: 14px;
            opacity: 0;
            transition: opacity 0.5s;
        }

    }

</style>
