<template>
<header class="header-user pt-2" :class="{ 'expanded': isExpanded }" v-if="$route.name !== 'browser-view'">
    <div class="mobile-brand">
        <img src="/img/brand/streamforge-logo-text.png" height="40px">
    </div>

    <div class="mobile-expand" @click="expand">
        <font-awesome-icon :icon="['fas', 'bars']" class="mobile-expand-icon" v-if="!isExpanded" />
        <font-awesome-icon :icon="['fas', 'xmark']" class="mobile-expand-icon" v-if="isExpanded" />
    </div>

    <div class="navigation-wrapper">
        <div class="navigation-scrollbox">
            <div class="navigation-sub-wrapper">
                <div class="navigation-link" @click="expand">
                    <router-link :to="{ name: 'home' }">
                        <font-awesome-icon :icon="['fas', 'house']" class="mr-2" /><span>Home</span>
                    </router-link>
                </div>

                <div class="navigation-link" @click="expand">
                    <router-link :to="{ name: 'resume' }">
                        <font-awesome-icon :icon="['fas', 'bolt']" class="mr-2" /><span>Connect</span><small class="text-primary ml-2">New</small>
                    </router-link>
                </div>

                <div class="navigation-link" @click="expand">
                    <router-link :to="{ name: 'streamcards' }">
                        <font-awesome-icon :icon="['fas', 'address-card']" class="mr-2" /><span>Stream Cards</span>
                    </router-link>
                </div>

                <div class="navigation-link" @click="expand">
                    <router-link :to="{ name: 'sponsorships' }">
                        <font-awesome-icon :icon="['fas', 'dollar-sign']" class="mr-2" /><span>Sponsorships</span>
                    </router-link>
                </div>

                <div class="navigation-link" @click="expand">
                    <router-link :to="{ name: 'steam-broadcasts' }">
                        <font-awesome-icon :icon="['fab', 'steam']" class="mr-2" /><span>Steam Broadcasts</span>
                    </router-link>
                </div>

                <div class="navigation-link" @click="expand">
                    <router-link :to="{ name: 'game-keys' }">
                        <font-awesome-icon :icon="['fas', 'key']" class="mr-2" /><span>Game Keys</span>
                    </router-link>
                </div>

                <div class="navigation-link" @click="expand">
                    <router-link :to="{ name: 'analytics' }">
                        <font-awesome-icon :icon="['fas', 'chart-bar']" class="mr-2" /><span>Analytics</span>
                    </router-link>
                </div>

                <div class="navigation-link" @click="expand">
                    <router-link :to="{ name: 'connections' }" class="d-flex">
                        <font-awesome-icon :icon="['fas', 'plug']" class="mr-2 my-auto" /><span>Accounts</span>
                        <b-badge variant="primary" pill class="ml-2" v-if="profileCount !== 5">{{ profileCount }} / 5</b-badge>
                    </router-link>
                </div>

                <!-- <div class="navigation-link" @click="expand">
                    <div class="support-link" @click="openSupport">
                        <font-awesome-icon :icon="['fas', 'circle-question']" class="mr-2" />
                        <span>Support</span>
                    </div>
                </div> -->
            </div>

            <!-- <div class="navigation-sub-wrapper mt-5">

                <div class="navigation-link" @click="expand">
                    <router-link :to="{ name: 'streamcards' }">
                        <font-awesome-icon :icon="['fas', 'address-card']" class="mr-2" /><span>Stream Cards</span>
                    </router-link>
                </div>

                <div class="navigation-link" @click="expand">
                    <router-link :to="{ path: 'resume' }">
                        <font-awesome-icon :icon="['fas', 'user-circle']" class="mr-2" /><span>Connect</span>
                    </router-link>
                </div>
            </div> -->

            <div class="navigation-sub-wrapper mt-5" v-if="self.user.is_moderator">
                <h3 class="navigation-title mb-2">Admin</h3>
                <div class="navigation-link" @click="expand">
                    <router-link :to="{ name: 'admin-user-list' }">
                        <font-awesome-icon :icon="['fas', 'tools']" class="mr-2" /><span>Users</span>
                    </router-link>
                </div>
            </div>

            <!-- <div class="navigation-sub-wrapper mt-5">
                <h3 class="navigation-title mb-2">Actions</h3>

                <div class="navigation-link" @click="expand">
                    <router-link :to="{ name: 'connections' }" class="d-flex">
                        <font-awesome-icon :icon="['fas', 'plug']" class="mr-2 my-auto" /><span>Accounts</span>
                        <b-badge variant="primary" pill class="ml-2">{{ profileCount }} / 5</b-badge>
                    </router-link>
                </div>

                <div class="navigation-link" @click="expand">
                    <div class="support-link" @click="openSupport">
                        <font-awesome-icon :icon="['fas', 'circle-question']" class="mr-2" />
                        <span>Support</span>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</header>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'user-sidebar',

    components: {
    },

    data() {
        return {
            isExpanded: false
        }
    },

    computed: {
        ...mapGetters([
            'self',
            'getColors',
            'userProfiles',
        ]),

        profileCount() {
            let count = 0

            _.forEach(this.userProfiles, (profile, key) => {
                if (profile) count++
            })

            return count
        },
    },

    methods: {
        expand() {
            this.isExpanded = !this.isExpanded
            this.$intercom.update({
                "hide_default_launcher": this.isExpanded
            })
            this.isExpanded && this.$intercom.hide()
        },
        openSupport() {
            this.$intercom.update({
                "hide_default_launcher": true
            })
            this.$intercom.show()
        }
    }
}
</script>

<style lang="scss">
header.header-user {
    .mobile-expand {
        display: none;
    }

    .mobile-brand {
        display: none;
    }

    .navigation-sub-wrapper {
        .navigation-title {
            color: #bec2cc;
            font-size: 14px;
            line-height: 22px;
            font-weight: 600;
        }

        .navigation-link {
            font-size: 16px;
            line-height: 24px;
            font-weight: 600;
            padding: 5px 0;
            cursor: pointer;

            .support-link {
                width: fit-content;
            }

            &:hover {
                svg {
                    color: #fcd878;
                }

                a {
                    opacity: 1;
                }
            }

            a {
                color: #fff;
                opacity: 0.75;

                -webkit-transition: opacity 200ms ease;
                transition: opacity 200ms ease;
                font-weight: 600;

                &.active {
                    color: #fff;
                    font-weight: 600;
                    opacity: 1;

                    svg {
                        color: #fcd878;
                    }
                }
            }

            svg {
                transition: 0.25s all;
                width: 1em;
            }

            .new {
                font-size: 0.8em;
                color: #fcd878;
                font-weight: 500 !important;
                line-height: 0em;
            }

            .badge {
                padding-top: 0;
                padding-bottom: 0;
                font-size: 60%;
                line-height: 25px;
            }
        }
    }
}
</style>
