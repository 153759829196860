import {
    BootstrapVue,
    TabsPlugin,
    BreadcrumbPlugin,
    DropdownPlugin,
    CalendarPlugin,
    InputGroupPlugin,
    ModalPlugin,
    AlertPlugin,
    AvatarPlugin,
    PopoverPlugin,
    FormTagsPlugin,
} from 'bootstrap-vue'


export default {
    install(Vue) {
        Vue.use(BootstrapVue)
        Vue.use(TabsPlugin)
        Vue.use(InputGroupPlugin)
        Vue.use(BreadcrumbPlugin)
        Vue.use(DropdownPlugin)
        Vue.use(CalendarPlugin)
        Vue.use(ModalPlugin)
        Vue.use(AlertPlugin)
        Vue.use(AvatarPlugin)
        Vue.use(PopoverPlugin)
        Vue.use(FormTagsPlugin)
    }
}
