<template>
    <div class="user-topbar py-xs-3 py-lg-5" :class="{
        'fixed-width-1080': isCreatorProfile,
    }">
        <transition name="fade">
            <div
                v-if="background"
                class="page-backdrop"
                :style="{
                    'background-image': `url(${background})`
                }"
            >
            </div>
        </transition>

        <div class="container">
            <div class="topbar-wrapper my-auto">
                <div class="brand pb-1">
                    <a href="/">
                        <img src="/img/brand/streamforge-logo-text.png" height="50px">
                    </a>
                </div>

                <div class="row row-topbar">
                    <div class="col-lg-9 offset-lg-3 d-flex justify-content-end">
                        <user-top-info></user-top-info>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import _ from 'lodash'

import UserTopInfo from '@/components/UserTopInfo'

export default {
    components: {
        UserTopInfo
    },

    data() {
        return {
            background: null
        }
    },

    computed: {
        ...mapGetters([
            'self'
        ]),

        backgrounds() {
            if (this.self && this.self.rating && this.self.rating.game_breakdown) {
                return _(this.self.rating.game_breakdown).filter((item) => {
                    return item.game && item.game.igdb_payload && item.game.igdb_payload.cover
                }).map((item) => {
                    return item.game.igdb_payload.cover.url.replace('t_thumb', 't_screenshot_big')
                }).value()
            }
        },

        isCreatorProfile() {
            const creatorProfileRouteNames = ['creator-platform-twitch']
            return creatorProfileRouteNames.includes(this.$route.name)
        },
    },

    watch: {
        backgrounds(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.background = _.sample(this.backgrounds)
            }
        },

        $route(to, from) {
            if (to !== from) {
                const oldBackground = this.background
                this.background = null

                this.$nextTick(() => {
                    this.background = _.sample(_.filter(this.backgrounds, item => item !== oldBackground))
                })
            }
        }
    },
}
</script>
<style lang="scss">
.user-topbar {
    position: relative;

    &.fixed-width-1080 {
        .container {
            margin-left: auto;
            margin-right: auto;
            width: 1080px;
        }

        .notifications-wrapper {
            display: none;
        }
    }

    .page-backdrop {
        position: absolute;
        top: 0;
        left: 0;
        height: 100vh;
        width: 70vw;
        background-size: cover;
        // background-position: center;
        z-index: -1;
        // opacity: 0;
        // animation: fade 1s forwards;
        animation: fade 1s linear forwards;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height: 100vh;
            width: 70vw;
            background: linear-gradient(
                to bottom right,
                rgba(18, 20, 29, 0.95) 20%,
                rgba(18, 20, 29, 0.975) 30%,
                rgba(18, 20, 29, 1) 50%
            );
        }
    }

    .topbar-wrapper {
        display: grid;
        grid-template-columns: auto 1fr;
        grid-gap: 32px;
    }

    .row-topbar {
        display: flex;
    }

    .brand {
        display: flex;
        width: 240px;

        a {
            display: flex;

            img {
                margin: auto 0;
            }
        }
    }
}
</style>
