<template>
<div class="page page-landing">
    <div class="brand">
        <a href="/">
            <img src="/img/brand/streamforge-logo-text.png" height="50px">
        </a>
    </div>
</div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'

export default {
    computed: {
        ...mapGetters([
            'isAuthenticated',
        ]),
    },

    created() {
        if (this.isAuthenticated) {
            this.$router.push({ name: 'home' })
        } else {
            this.$router.push({ name: 'login' })
        }
    },
}
</script>
<style lang="scss" media="screen">
.page-landing {
    .brand {
        display: fixed;
        top: 0;
        left: 0;
        width: 250px;
        padding: 25px;
    }
}
</style>
