<template>
    <div class="page page-streamcards">
        <div class="row mb-sm">
            <div class="col-lg-6">
                <h1 class="display-2">
                    <font-awesome-icon
                        :icon="['fas', 'address-card']"
                        class="my-auto mr-3 text-primary"
                    />Stream Cards
                </h1>
                <p>
                    Your Stream Card is a link-in-bio tool for use on your social media profile to direct your fanbase to other places to find you on the web.
                </p>
            </div>

            <div class="col-lg-6 d-inline-flex justify-content-center align-items-center" >
                <div class="text-center mt-auto">
                    <h4 class="display-5 m-0">Your Link</h4>
                    <a class="streamcard-link" :class="{ disabled: !isActive }" target="_blank" :href="'https://stream.cards/' + channelName" >stream.cards/{{ channelName }}</a >
                </div>
            </div>
        </div>

        <div v-if="!loading">
            <div class="row">
                <div v-if="isActive" :class="{'col-xl-6': tabIndex != 1, 'col-xl-12': tabIndex == 1}">
                    <b-tabs pills v-model="tabIndex">
                        <b-tab active>
                            <template v-slot:title>Layout</template>

                            <div class="row row-panels mt-5">
                                <div class="col-lg-12 mb-5">
                                    <h4 class="display-4 mb-2">Description</h4>
                                    <div class="panel p-4">
                                        <div class="description-edit" ref="description" contenteditable="true" placeholder="Write your description here..." @input="checkDescription" @keydown="checkForEnter" >{{ originalDescription }}</div>
                                        <div class="mt-3 d-flex justify-content-between align-items-center">
                                            <div :class="{ 'text-danger': description.length > 180 }" >
                                                {{ description.length }}/180 characters
                                            </div>
                                            <button class="btn btn-sm btn-primary" :class="{ disabled: !description.valid }" @click="updateDescription" v-tooltip="description.hoverMessage">
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="editable-links links-wrapper mt-3">
                                <div class="d-flex justify-content-between flex-wrap">
                                    <h4 class="display-4 my-auto">Links</h4>

                                    <div class="d-flex">
                                        <button type="button" class="btn btn-warning btn-icon my-2" :class="{ disabled: !isDirty }" @click="revert">
                                            <span class="btn-inner--icon mr-2">
                                                <font-awesome-icon :icon="['fas', 'arrow-rotate-left']" />
                                            </span>
                                            <span>Undo</span>
                                        </button>

                                        <button type="button" class="btn btn-primary btn-icon my-2" :class="{ disabled: !enableSave }" @click="save" >
                                            <span class="btn-inner--icon mr-2">
                                                <font-awesome-icon :icon="['fas', 'floppy-disk']" />
                                            </span>
                                            <span>Save</span>
                                        </button>
                                    </div>
                                </div>

                                <div v-if="links.length === 0">
                                    <div class="mt-5 text-center">
                                        You don't have any links. Click below to add one.
                                    </div>
                                </div>

                                <div v-else>
                                    <draggable
                                        v-model="links"
                                        animation="0"
                                        handle=".handle"
                                        @start="onDragStart"
                                        @end="onDragEnd"
                                    >
                                        <transition-group :name="!drag ? 'links-list' : null">
                                            <div class="link" v-for="(link, i) in links" :key="link.key" :id="'link-' + link.key" >
                                                <div class="handle text-center">
                                                    <font-awesome-icon
                                                        :icon="['fas', 'grip-lines']"
                                                        class="my-auto bars"
                                                    />
                                                </div>
                                                <div>
                                                    <div class="link-field position-relative">
                                                        <span class="edit">
                                                            <font-awesome-icon
                                                                :icon="['fas', 'pen']"
                                                                size="sm"
                                                            />
                                                        </span>
                                                        <input class="title" :class="{ 'warning-highlight': link.title.length <= 0, }" placeholder="Title" v-model="link.title" />
                                                    </div>
                                                    <div class="link-field position-relative">
                                                        <span class="edit">
                                                            <font-awesome-icon
                                                                :icon="['fas', 'link']"
                                                                size="sm"
                                                            />
                                                        </span>
                                                        <input class="url" :class="{ 'warning-highlight': link.url.length <= 0, 'danger-highlight': !validURL(link.url), }" placeholder="http://www.example.com" v-model="link.url" />
                                                    </div>
                                                </div>
                                                <div class="delete text-center">
                                                    <span v-on:click="deleteLink(i)">
                                                        <font-awesome-icon
                                                            :icon="['fas', 'trash-can']"
                                                            class="my-auto trash-can"
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                        </transition-group>
                                    </draggable>
                                </div>

                                <div class="link add-link d-flex" @click="addLink">
                                    <font-awesome-icon :icon="['fas', 'plus']" size="2x" class="m-auto" />
                                </div>
                            </div>
                        </b-tab>

                        <b-tab>
                            <template v-slot:title>Analytics</template>
                            <analytics
                                v-bind:show="tabIndex == 1"
                            ></analytics>

                        </b-tab>

                        <b-tab>
                            <template v-slot:title>Settings</template>

                            <div class="mt-5">
                                <!-- <div class="row row-panels">
                                    <div class="col-lg-12 mb-5">
                                        <h4 class="display-4 mb-2">Description</h4>
                                        <div class="panel p-4">
                                            <div class="description-edit" ref="description" contenteditable="true" placeholder="Write your description here..." @input="checkDescription" @keydown="checkForEnter" >{{ originalDescription }}</div>
                                            <div class="mt-3 d-flex justify-content-between align-items-center">
                                                <div :class="{ 'text-danger': description.length > 180 }" >
                                                    {{ description.length }}/180 characters
                                                </div>
                                                <button class="btn btn-sm btn-primary" :class="{ disabled: !description.valid }" @click="updateDescription" v-tooltip="description.hoverMessage">
                                                    Save
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div> -->

                                <div class="row">
                                    <div class="col-lg-12">
                                        <h4 class="display-4">Deactivate Your Stream Card</h4>
                                        <p> Here you can deactivate your Stream Card and users will no longer be able to view your Stream Cards links. You will able to reactivate it again at any time. </p>

                                        <button type="button" v-if="!deactivateState.confirm" @click="deactivateState.confirm = true" class="btn btn-danger mt-3" >
                                            Deactivate
                                        </button>

                                        <button type="button" v-if="deactivateState.confirm" @click="deactivate" class="btn btn-danger mt-3" >
                                            Yes
                                        </button>
                                        <button type="button" v-if="deactivateState.confirm" @click="deactivateState.confirm = false" class="btn btn-primary mt-3" >
                                            No
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </b-tab>
                    </b-tabs>
                </div>

                <div v-else class="col-xl-6">
                    <h4 class="display-4">Activate Your Stream Card</h4>
                    <p>Click the button below to activate your Stream Card and begin customizing it just the way you want.</p>
                    <p>Stream Card links will default to your social media links from Twitch.</p>
                    <p class="text-primary" v-if="!linkedTwitch">
                        You need to connect your Twitch account to use Stream Cards
                    </p>
                    <button type="button" class="btn btn-success mt-3" :class="{ disabled: !linkedTwitch }" @click="activateCard()" >
                        Activate
                    </button>
                </div>

                <div v-if="tabIndex != 1" class="col-xl-6 d-none d-xl-block">
                    <div class="preview-wrapper">
                        <div class="preview">
                            <div class="app">
                                <div class="save-indicator" :class="{ hidden: !enableSave }"></div>
                                <!-- <div v-if="!isActive" class="preview-indicator">Preview</div> -->

                                <div class="content">
                                    <div class="header">
                                        <img class="logo" :src="avatarUrl" />
                                        <div class="username"> {{ displayName }} </div>
                                        <div v-if="isActive" class="description">
                                            {{ description.value }}
                                        </div>
                                        <div v-else class="description">
                                            A sample description
                                        </div>
                                    </div>

                                    <div v-if="isActive" class="w-100">
                                        <div v-if="links.length == 0" class="text-center">
                                            {{ displayName }} hasn't added links to their Stream Card yet
                                        </div>

                                        <div class="links" v-for="link in links" :key="link.key" >
                                            <a :href="link.url" target="_blank" class="link">
                                                {{ link.title }}
                                            </a>
                                        </div>
                                    </div>
                                    <div v-else class="w-100">
                                        <div class="links">
                                            <a href="https://www.twitch.tv/" target="_blank" class="link">Twitch</a>
                                            <a href="https://discord.com/" target="_blank" class="link">Discord</a>
                                            <a href="https://twitter.com/home" target="_blank" class="link">Twitter</a>
                                        </div>
                                    </div>

                                </div>

                                <div class="footer">
                                    <img src="/img/brand/streamforge-logo-text.png" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import draggable from 'vuedraggable'
import Analytics from '../components/StreamCards/Analytics'

export default {
    name: 'links',

    components: {
        draggable,
        Analytics
    },

    data() {
        return {
            originalLinks: [],
            links: [],
            nextKey: 0,
            loading: true,
            isActive: false,
            drag: false,
            tabIndex: 0,
            deactivateState: {
                confirm: false,
            },
            originalDescription: '',
            description: {
                length: 0,
                valid: false,
                value: '',
                hoverMessage: '',
            },
        }
    },

    created() {
        this.getCard()
    },

    computed: {
        ...mapGetters([
            'self',
            'userProfiles'
        ]),

        channelName() {
            if (this.userProfiles.twitch != null) {
                return this.userProfiles.twitch.name
            } else {
                return null
            }
        },

        linkedTwitch() {
            if (this.userProfiles.twitch != null) {
                return true
            } else {
                return false
            }
        },

        isDirty() {
            if (this.originalLinks.length != this.links.length) return true
            let dirty = this.links.some((link, i) => {
                let originalLink = {
                    title: this.originalLinks[i].title,
                    url: this.originalLinks[i].url,
                }
                return link.title != originalLink.title || link.url != originalLink.url
            })

            return dirty
        },

        enableSave() {
            // check if any field is empty or url not correct
            let anyFieldsWrong = this.links.some((link) => {
                let empty = link.title == '' || link.url == ''
                let urlIncorrect = !this.validURL(link.url)
                return empty || urlIncorrect
            })

            if (anyFieldsWrong) return false
            // if (this.links.length == 0) return false
            else return this.isDirty
        },

        avatarUrl() {
            if(this.userProfiles && this.userProfiles.twitch && this.userProfiles.twitch.avatar_url) {
                return this.userProfiles.twitch.avatar_url
            } else {
                return this.self.avatar
            }
        },

        displayName() {
            if(this.userProfiles && this.userProfiles.twitch && this.userProfiles.twitch.display_name) {
                return this.userProfiles.twitch.display_name
            } else {
                return this.self.user.name
            }
        }
    },

    methods: {
        getCard() {
            this.axios.get('/card')
                .then(({ data }) => {
                    if (data.active) {
                        this.loadLinks(data.links)
                        this.loadDescription(data.description)

                        this.isActive = true
                    } else {
                        this.isActive = false
                    }

                    this.loading = false
                })
                .catch((err) => {
                    console.error(`getLinks |`, err.response.data.message)
                })
        },
        activateCard() {
            if (this.linkedTwitch) {
                this.axios.post('/card')
                    .then(({ data }) => {
                        this.loadLinks(data.links)
                        this.loadDescription(data.description)

                        this.isActive = true
                    })
                    .catch((err) => {
                        console.error(`activateCard |`, err.response.data.message)
                    })
            }
        },
        loadLinks(links) {
            let allLinks = links
            allLinks.map((link) => {
                link.key = this.nextKey++
                return link
            })

            this.originalLinks = allLinks.map((link) => ({ ...link }))
            this.links = allLinks.map((link) => ({ ...link }))
        },
        loadDescription(description) {
            this.originalDescription = description
            this.description.value = this.originalDescription
            this.description.length = description.length
            this.description.hoverMessage = 'Changes are up to date'
        },


        validURL(url) {
            if (url.length == 0) return true

            let pattern = new RegExp(
                '^([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?$'
            )
            return pattern.test(url)
        },

        containsURL(text) {
            let pattern = new RegExp(
                '([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?'
            )

            return pattern.test(text)
        },

        containsHTML(text) {
            let a = document.createElement('div')
            a.innerHTML = text

            for (let c = a.childNodes, i = c.length; i--; ) {
                if (c[i].nodeType == 1) return true
            }

            return false
        },

        onDragStart(e) {
            this.drag = true

            let link = document.getElementById(e.item.id)
            link.classList.add('ghost')
            for (let i = 0; i < link.children.length; i++) {
                link.children[i].classList.add('hidden')
            }

        },

        onDragEnd(e) {
            this.drag = false

            let link = document.getElementById(e.item.id)
            link.classList.remove('ghost')
            for (let i = 0; i < link.children.length; i++) {
                link.children[i].classList.remove('hidden')
            }

        },

        revert() {
            if (this.isDirty) {
                this.links = this.originalLinks.map((link) => ({ ...link }))
            }
        },
        deleteLink(linkIndex) {
            this.links.splice(linkIndex, 1)
        },
        addLink() {
            const newLink = {
                key: this.nextKey++,
                title: '',
                url: '',
            }
            this.links.push(newLink)
        },
        save() {
            if (this.enableSave) {
                this.axios.post('/card/links', {
                        links: this.links,
                    })
                    .then((res) => {
                        const newLinks = res.data
                        this.originalLinks = newLinks.map((link) => ({ ...link }))
                        this.links = newLinks.map((link) => ({ ...link }))
                    })
                    .catch((err) => {
                        console.error(`save |`, err.response.data.message)
                    })
            }
        },

        checkDescription(e) {
            this.description.length = e.target.textContent.length
            this.description.value = e.target.textContent

            let isValid = false
            let errorMessage = ''

            if (this.description.length > 180) {
                errorMessage = 'Description has too many characters'
            } else if (this.containsHTML(this.description.value)) {
                errorMessage = 'Description cannot contain HTML'
            } else if (this.containsURL(this.description.value)) {
                errorMessage = 'Description cannot contain a URL'
            } else {
                isValid = true
            }

            this.description.hoverMessage = errorMessage
            this.description.valid = isValid
        },
        checkForEnter(e) {
            if (e.keyCode == 13) {
                e.preventDefault()
                this.updateDescription()
                e.target.blur()
            }
        },
        updateDescription() {
            if (this.description.valid) {
                this.axios.post('/card/description', {
                        description: this.description.value,
                    })
                    .then((res) => {
                        this.originalDescription = res.data.description
                        this.$refs.description.textContent = this.originalDescription
                        this.description.valid = false
                        this.description.length = res.data.description.length
                        this.description.hoverMessage = 'Changes are up to date'
                    })
            }
        },
        deactivate() {
            this.axios.delete('/card').then((res) => {
                this.originalLinks = []
                this.links = []
                this.nextKey = 0
                this.loading = false
                this.isActive = false
                this.tabIndex = 0
                this.deactivateState = {
                    confirm: false,
                }
                this.originalDescription = '',
                this.description = {
                    length: 0,
                    valid: false,
                    value: '',
                    hoverMessage: '',
                }
            })
        },

        getAnalytics() {
            this.axios.get('/card/analytics').then((res) => {
                console.log(res.data)
                this.analytics = res.data
            })
        }
    },
}
</script>

<style scoped lang="scss">
.streamcard-link {
    font-size: 1.25em;
    font-weight: 700;
}

.streamcard-link.disabled {
    opacity: 0.65;
    pointer-events: none;
}

.links-wrapper {
    margin: 0 auto;
    max-width: 600px;
    position: relative;
}

.link {
    margin: 20px 0;
    background-color: #1e2029;
    padding: 20px 15px;
    border-radius: 12px;
    display: grid;
    grid-template-columns: 50px auto 50px;
    grid-column-gap: 15px;
    align-items: center;
    width: 100%;
    /* transition: transform .3s; */
}

.handle {
    cursor: move;
}

.edit {
    position: absolute;
    top: 10px;
    left: 10px;
}

.trash-can {
    color: #cc4040;
    cursor: pointer;
}

.ghost {
    background-color: #4b4f61;
}

.hidden {
    opacity: 0 !important;
}

.dragging {
    cursor: move !important;
}

.editable-links {
    .link {
        min-height: 140px;

        &.add-link {
            opacity: 0.75;
            cursor: pointer;
            transition: 0.25s all;

            &:hover {
                opacity: 1;
            }
        }
    }
}

.link .link-field:not(:last-child) {
    margin-bottom: 10px;
}

.link input {
    background-color: #12141d;
    border: 1.5px solid #12141d;
    border-radius: 7px;
    height: 45px;
    padding: 0 10px 0 33px;
    width: 100%;
    transition: 0.2s;
    text-overflow: ellipsis;
}

.link input:focus {
    outline: none;
}

.warning-highlight {
    border-color: #b9a918 !important;
}

.danger-highlight {
    border-color: #f12424 !important;
}

.link .title {
    color: #f6f6f8;
    font-weight: 600;
}

.link .url {
    color: #bec2cc;
}

.links-list-enter {
    opacity: 0;
    transform: translateX(-50px);
}

.links-list-enter-active {
    transition: all 0.3s;
}

[contenteditable="true"]:empty:before {
    content: attr(placeholder);
    opacity: 0.5;
}

.description-edit {
    cursor: text;
    border: 1.5px solid #12141d;
    border-radius: 7px;
    padding: 10px 15px;
    background-color: #12141d;
}

.description-edit:focus {
    outline: none;
}

.preview-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.preview {
    width: 350px;
    height: 692px;
    border: 15px solid black;
    border-radius: 30px;
    position: relative;
}

.preview .app {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 2rem;
    padding-bottom: 1rem;
}

.preview .save-indicator {
    position: absolute;
    top: 10px;
    left: 12px;
    width: 10px;
    height: 10px;
    background-color: #6565da;
    border-radius: 100%;
    transition: 0.2s;
}

.preview .preview-indicator {
    position: absolute;
    top: 8px;
    left: 50%;
    transform: translateX(-50%);
    background-color: grey;
    padding: 5px;
    border-radius: 7px;
}

.preview .content {
    flex: 1;
    padding: 1rem;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;
    /* margin: auto 0; */
    margin-right: -11px;
}

.preview .header {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.preview .logo {
    width: 100px;
    height: 100px;
    margin-bottom: 15px;
    object-fit: cover;
    border-radius: 50%;
}

.preview .username {
    color: #fff;
    font-size: 24px;
    line-height: 30px;
    font-weight: 600;
    letter-spacing: 0em;
    /* letter-spacing: -.02em; */
}

.preview .description {
    margin-top: 10px;
    text-align: center;
    color: hsla(0, 0%, 100%, 0.5);
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    width: 100%;
    word-wrap: break-word;
}

.preview .links {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.preview .links a {
    /* text-decoration: none !important;
        color: hsla(0, 0%, 100%, .5);
        font-family: 'Inter', sans-serif;
        font-size: 15px; */

    display: inline-block;
    padding: 10px 15px;
    background-color: #3898ec;
    color: white;
    border: 0;
    line-height: inherit;
    text-decoration: none;
    cursor: pointer;
    border-radius: 0;

    border-style: solid;
    border-width: 2px;
    border-color: hsla(0,0%,100%,.1);
    border-radius: 7.5px;

    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    min-height: 48px;
    padding-right: 24px;
    padding-left: 24px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: transparent;
    -webkit-transition: border-color 200ms ease;
    transition: border-color 200ms ease;
    font-weight: 600;
    overflow-wrap: anywhere;
}

.preview .links a:hover {
    outline: 0;
    border-color: hsla(0, 0%, 100%, 0.5);
}

.preview .link {
    margin: 7px 0;
    padding: 15px 0px;
    width: 100%;
    text-align: center;
    /* background-color: hsla(0, 0%, 100%, .05); */
    /* border-radius: 12px; */
}

.preview .footer {
    flex: 0;
    display: flex;
    padding-top: 1rem;
}

.preview .footer img {
    height: 30px;
    margin: auto;
}
</style>
