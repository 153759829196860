<template>
    <div v-if="!loading">
        <div class="row mt-5">
            <div class="col-xl-12">
                <page-analytics-chart
                    v-bind:data="data.page"
                    v-bind:show="show"
                ></page-analytics-chart>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-xl-12">
                <link-analytics-chart
                    v-bind:data="data.links"
                    v-bind:show="show"
                ></link-analytics-chart>
            </div>
        </div>

        <div class="row mt-5">
            <div class="col-xl-4 mb-3">
                <h3 class="display-4 mb-3">Locations</h3>
                <div class="table table-striped">
                    <div class="table-header-wrapper px-3">
                        <div class="row table-row-header my-auto w-100 no-gutters">
                            <div class="col-4">Country</div>
                            <div class="col-4 text-right">Visits</div>
                            <div class="col-4 text-right">Click Rate</div>
                        </div>
                    </div>
                    <div class="table-data-wrapper">
                        <div v-if="createList(data.locations).length == 0" class="text-center">
                            No data to display
                        </div>
                        <div v-else>
                            <div class="table-group-wrapper" v-for="location in createList(data.locations)">
                                <div class="table-row-wrapper px-3">
                                    <div class="row table-row my-auto w-100 no-gutters prime">
                                        <div class="col-4">{{ location.name }}</div>
                                        <div class="col-4 text-right">{{ location.details.views }}</div>
                                        <div class="col-4 text-right pl-4">{{ location.details.clicks / location.details.views | numeral('0%') }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 mb-3">
                <h3 class="display-4 mb-3">Referrers</h3>
                <div class="table table-striped">
                    <div class="table-header-wrapper px-3">
                        <div class="row table-row-header my-auto w-100 no-gutters">
                            <div class="col-4">Website</div>
                            <div class="col-4 text-right">Visits</div>
                            <div class="col-4 text-right">Click Rate</div>
                        </div>
                    </div>
                    <div class="table-data-wrapper">
                        <div v-if="createList(data.locations).length == 0" class="text-center">
                            No data to display
                        </div>
                        <div v-else>
                            <div class="table-group-wrapper" v-for="referrer in createList(data.referrers)">
                                <div class="table-row-wrapper px-3">
                                    <div class="row table-row my-auto w-100 no-gutters prime">
                                        <div class="col-4">{{ referrer.name }}</div>
                                        <div class="col-4 text-right">{{ referrer.details.views }}</div>
                                        <div class="col-4 text-right pl-4">{{ referrer.details.clicks / referrer.details.views | numeral('0%') }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-4">
                <h3 class="display-4 mb-3">Devices</h3>
                <div class="table table-striped">
                    <div class="table-header-wrapper px-3">
                        <div class="row table-row-header my-auto w-100 no-gutters">
                            <div class="col-4">Device</div>
                            <div class="col-4 text-right">Visits</div>
                            <div class="col-4 text-right">Click Rate</div>
                        </div>
                    </div>
                    <div class="table-data-wrapper">
                        <div v-if="createList(data.locations).length == 0" class="text-center">
                            No data to display
                        </div>
                        <div v-else>
                            <div class="table-group-wrapper" v-for="device in createList(data.devices)">
                                <div class="table-row-wrapper px-3">
                                    <div class="row table-row my-auto w-100 no-gutters prime">
                                        <div class="col-4 device-name">{{ device.name }}</div>
                                        <div class="col-4 text-right">{{ device.details.views }}</div>
                                        <div class="col-4 text-right pl-4">{{ device.details.clicks / device.details.views | numeral('0%') }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import PageAnalyticsChart from './PageAnalyticsChart'
import LinkAnalyticsChart from './LinkAnalyticsChart'

export default {
    name: 'analytics',

    props: [
        'show'
    ],

    components: {
        PageAnalyticsChart,
        LinkAnalyticsChart
    },

    data() {
        return {
            data: null,
            loading: true,
        }
    },

    watch: {
        show: function(val, oldVal) {
            this.loading = true
            if (val) {
                this.getAnalytics()
            }
        },
    },

    methods: {
        createList(dict) {
            let list = []

            for (let [key, value] of Object.entries(dict)) {
                list.push({
                    name: key,
                    details: value
                })
            }

            list.sort((first, second) => {
                if (first.details.views < second.details.views)
                    return 1;
                else
                    return -1
            })

            return list
        },

        getAnalytics() {
            this.axios.get('/card/analytics').then((res) => {
                console.log(res.data)
                this.data = res.data
                this.loading = false
            })
        }
    }

}
</script>

<style lang='scss'>
.table {
    font-size: 0.95em;

    // &.table-striped .table-row-wrapper:nth-of-type(odd) {
    //     background-color: hsla(0, 0%, 100%, 0.05);
    // }

    .table-header-wrapper {
        display: flex;
        // border-bottom: 1px solid rgba(white, 0.075);
        padding-bottom: 0.5em;

        .table-row-header {
            color: #eee;
            font-weight: 600;

            & > div {
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
        }
    }
    .table-row-wrapper {
        position: relative;
        display: flex;
        // border-top: 1px solid rgba(white, 0.05);

        // &:nth-child(2) {
        //     border-top: 1px solid rgba(white, 0.075);
        // }

        .device-name {
            text-transform: capitalize;
        }
    }


    .table-data-wrapper {
        background-color: #1e2029;
        border-radius: 12px;
        padding: 15px 0;
    }

    .table-group-wrapper:not(:last-child) {
        margin-bottom: 15px;
    }



}

</style>
