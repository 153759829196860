<template>
    <div class="page page-campaign" v-if="campaign">
        <slot-application-modal
            :type="modal.slots.type"
            :campaign="modal.slots.campaign"
            :selected-slot="modal.slots.slot"
            :timezone-text="timezoneTitle"
            @submit="onSlotApplicationModalSubmit"
        />

        <key-modal
            v-if="campaign"
            :campaign_id="campaign.id"
            :task_id="modal.keys.task_id"
            :keycode="modal.keys.keycode"
            :type="modal.keys.type"
            @key-retrieved="onKeyModalRetrieveSubmit"
        />

        <div class="row mb-sm">
            <div class="col-lg-12">
                <p class="m-0">{{ getFromDictionary(`campaign.types.${campaign.type}`, 'label') }}</p>
                <h1 class="display-3 m-0">{{ campaign.title }}</h1>
            </div>
        </div>

        <div class="row mb-sm" v-if="campaign.payload.showcase">
            <div class="col-lg-12">
                <showcase-slider :videos="campaign.payload.showcase.videos" :screenshots="campaign.payload.showcase.screenshots" />
            </div>
        </div>

        <div class="row row-panels mb-sm">
            <div class="col-lg-4 mb-3">
                <div class="panel p-3">
                    <div class="description-wrapper">
                        <small class="font-weight-bold">Sponsorship Description</small>
                        <p style="white-space: pre-line;">{{ campaign.description }}</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 mb-3">
                <div class="panel p-3">
                    <small class="font-weight-bold">Campaign Start</small>
                    <p>{{ campaign.started_at | moment('dddd, MMMM Do YYYY, h:mm A') }}</p>

                    <small class="font-weight-bold">Campaign End</small>
                    <p class="m-0">{{ campaign.ended_at | moment('dddd, MMMM Do YYYY, h:mm A') }}</p>
                </div>

                <div class="panel p-3 mt-3" v-if="campaign.type ===  'steam-broadcast'">
                    <small class="font-weight-bold">Payout Per Slot</small>
                    <p class="m-0">${{ campaign.payload.slots.payout_per_slot | numeral('0,0.00') }}</p>
                </div>
            </div>
        </div>

        <div class="row row-panels mb-sm" v-if="tasks && tasks.length">
            <div class="col-lg-12">
                <h1 class="display-4 mb-3">Tasks</h1>
            </div>

            <div class="col-lg-4" v-for="(task, index) in tasks">
                <task-card
                    :task="task"
                    :index="index"
                    :link="link"
                    :campaign="campaign"
                    @task-action="onTaskAction"
                />
            </div>
        </div>

        <div class="row row-panels mb-sm" v-if="campaign.type === 'steam-broadcast'">

            <div class="col-lg-12" v-if="campaign.payload.applications.state === 'locked' && participant">
                <div class="row">
                    <div class="col-lg-12">
                        <h1 class="display-4 mb-3">Instructions</h1>
                    </div>

                    <div class="col-lg-4 mb-md">
                        <div class="panel p-3">
                            <small class="font-weight-bold">Congratulations!</small>
                            <p class="m-0">Please see the streaming slots you are responsible for, and read the Steam streaming guide. You will find your OBS credentials displayed on this page as well.</p>
                        </div>
                    </div>

                    <div class="col-lg-4 mb-md" v-if="ingestServer">
                        <div class="panel p-3">
                            <small class="font-weight-bold">RTMP URL</small>
                            <p class="m-0">{{ ingestServer.rtmp_url }}</p>

                            <small class="font-weight-bold">Stream Key</small>
                            <p class="m-0">{{ ingestServer.stream_key }}</p>
                        </div>
                    </div>

                    <div class="col-lg-4 mb-md" v-else>
                        <div class="panel p-3">
                            <p>Please check back later when we have the OBS credentials for you.</p>
                        </div>
                    </div>

                    <div class="col-lg-4 mb-md">
                        <div class="panel panel-steam-stream-guide p-3 d-flex h-100">
                            <a class="m-auto" target="blank" href="https://docs.google.com/document/d/1ymOPtGZWSYnG_w51T5rYoQOfb67NjVzLGI91yd7K3Nc/edit?usp=sharing">
                                <div class="d-flex mb-3">
                                    <font-awesome-icon :icon="['fas', 'book']" size="4x" class="mx-auto" />
                                </div>
                                <div class="d-flex">
                                    <h4 class="h5 m-0">Steam Stream Guide</h4>
                                    <font-awesome-icon :icon="['fas', 'external-link-alt']" class="sm my-auto ml-2" />
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-8" >
                <h1 class="display-4 mb-3">Broadcast Slots</h1>
                <p v-if="campaign.payload.applications.state === 'open'">Please click any slots that you would be interested in and submit the form. All times below are being shown in your brower's timezone.</p>
                <p v-if="campaign.payload.applications.state === 'locked' && participant">You have been selected for the following streaming slots.</p>
            </div>

            <div class="col-lg-4 d-flex">
                <div class="text-right my-auto ml-auto">
                    <small>Times shown are</small>
                    <p class="m-0">{{ timezoneTitle }}</p>
                </div>
            </div>

            <div class="col-lg-12" v-if="!userProfiles.paypal || !userProfiles.discord">
                <div class="panel p-3">
                    <p class="m-0 text-center">
                        Please link your PayPal and Discord accounts on the <router-link :to="{ name: 'connections' }">Accounts Page</router-link> and join the Discord server in order to apply for this campaign.
                    </p>
                </div>
            </div>

            <div class="col-lg-12" v-else-if="campaign.payload.applications.state === 'open'">
                <slot-list-section
                    :slots="slots"
                    @slot-clicked="onSlotClick"
                    @slot-delete-clicked="onSlotDeleteClick"
                    :is-actions-enabled="true"
                />
            </div>

            <div class="col-lg-12" v-else-if="campaign.payload.applications.state === 'locked' && !participant">
                <div class="panel p-3">
                    <p class="m-0 text-center">Thank you for your interest in participating in this campaign! We have filled all available slots at this time. Please keep an eye on your email for future sponsorship opportunities!</p>
                </div>
            </div>

            <div class="col-lg-12" v-else-if="campaign.payload.applications.state === 'locked' && participant">
                <slot-list-section :slots="userSelectedSlots" />
            </div>
        </div>

        <div class="row row-panels mb-sm" v-if="campaign.type === 'key-distribution'">

            <div class="col-lg-12" v-if="!participant">
                <div class="row">
                    <div class="col-lg-12">
                        <h1 class="display-4 mb-3">Signup</h1>
                    </div>

                    <div class="col-lg-5 mb-md">
                        <div class="panel p-3">
                            <b-form-group
                                label="Day of Stream"
                                description="Please select which day during the campaign you will stream."
                            >
                                <b-form-select v-model="options.stream_delivery.day">
                                    <b-form-select-option :value="day" v-for="(times, day) in availableStreamTimes">{{ day }}</b-form-select-option>
                                </b-form-select>
                            </b-form-group>

                            <b-form-group
                                label="Time of Stream"
                                description="Please select the time of day you will be streaming."
                            >
                                <b-form-select v-model="options.stream_delivery.datetime" :disabled="!options.stream_delivery.day">
                                    <b-form-select-option :value="timeSlot.value" v-for="timeSlot in availableStreamTimes[options.stream_delivery.day]">{{ timeSlot.text }}</b-form-select-option>
                                </b-form-select>
                            </b-form-group>

                            <b-form-group
                                label="Duration of Stream (Hours)"
                                description="Please specify how many hours you will be streaming the game."
                            >
                                <div class="row mb-2">
                                    <div class="col">
                                        <b-form-input
                                            v-model="options.stream_delivery.duration_hours" type="range"
                                            :min="campaign.payload.stream_delivery && campaign.payload.stream_delivery.expected_hours ? campaign.payload.stream_delivery.expected_hours : 1"
                                            max="8"
                                         ></b-form-input>
                                    </div>

                                    <div class="col-lg-3">
                                        <div class="text-center">{{ options.stream_delivery.duration_hours }} {{ options.stream_delivery.duration_hours | pluralize('en', ['hour', 'hours']) }}</div>
                                    </div>
                                </div>
                            </b-form-group>

                            <div class="d-flex justify-content-end mt-3">
                                <b-button variant="primary" :disabled="!options.stream_delivery.datetime || !options.stream_delivery.duration_hours" @click="onApplyClick">Apply</b-button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </div>
</template>

<script>
import _ from 'lodash'
import Vue from 'vue'
import moment from 'moment'
import { mapGetters } from 'vuex'
import ShowcaseSlider from '@/components/Sliders/ShowcaseSlider'
import TaskCard from '@/components/Cards/TaskCard'
import SlotListSection from '@/components/Sections/SlotListSection'
import SlotApplicationModal from '@/components/Modals/SlotApplicationModal'
import KeyModal from '@/components/Modals/KeyModal'

export default {
    name: 'campaign',

    metaInfo() {
        return {
            title: 'Campaign'
        }
    },

    components: {
        ShowcaseSlider,
        TaskCard,
        SlotListSection,
        SlotApplicationModal,
        KeyModal,
    },

    computed: {
        ...mapGetters([
            'self',
            'getFromDictionary',
            'userProfiles',
        ]),

        availableStreamTimes() {
            if (!_.has(this.campaign, 'payload.stream_delivery.is_window_expected')) {
                return null
            }

            const campaignStartAt = moment(this.campaign.started_at)
            const campaignEndAt = moment(this.campaign.ended_at)
            const now = moment()

            let dateIterator
            let dateIteratorEnd

            // If the campaign window is ahead
            if (now.isBefore(campaignStartAt)) {
                dateIterator = campaignStartAt
                dateIteratorEnd = campaignEndAt

            // If we are past the campaign window
            } else {
                dateIterator = now.add(1, 'hour').startOf('hour')
                dateIteratorEnd = moment().add(7, 'days')
            }


            const streamTimes = {}

            while (dateIterator.isBefore(dateIteratorEnd)) {
                const date = dateIterator.format('dddd, MMM Do YYYY')

                if (!streamTimes[date]) {
                    streamTimes[date] = [{
                        text: dateIterator.format('h:mm a'),
                        value: dateIterator.format(),
                    }]
                } else {
                    streamTimes[date].push({
                        text: dateIterator.format('h:mm a'),
                        value: dateIterator.format(),
                    })
                }

                dateIterator.add(30, 'minutes')
            }

            return streamTimes
        },

        userSelectedSlots() {
            if (!this.participant) return []

            return _.filter(this.slots, item => item.participant_id === this.participant.id)
        },

        ingestServer() {
            const hasTwitchDisplayName = _.has(this.self, 'superviews.twitch.channel.display_name')
            const hasIngestServer = _.has(this.campaign, 'payload.ingest_server.rtmp_url') && _.has(this.campaign, 'payload.ingest_server.stream_key')

            if (hasTwitchDisplayName) {
                return {
                    rtmp_url: this.campaign.payload.ingest_server.rtmp_url.replace('YOURTWITCHHANDLE', this.self.superviews.twitch.channel.display_name),
                    stream_key: this.campaign.payload.ingest_server.stream_key.replace('YOURTWITCHHANDLE', this.self.superviews.twitch.channel.display_name),
                }
            }

            return false
        },
    },

    created() {
        this.getCampaign()
    },

    data() {
        return {
            campaign: null,
            participant: null,
            tasks: null,
            link: null,
            slots: null,
            applications: null,
            keys: null,
            game: null,

            // modal: {
            //     slot: null,
            //     type: 'create',
            //     campaign: null,
            // },

            modal: {
                slots: {
                    slot: null,
                    type: 'create',
                    campaign: null,
                },
                keys: {
                    type: 'create',
                    keycode: null,
                    campaign_id: null,
                    task_id: null,
                },
            },

            options: {
                stream_delivery: {
                    day: null,
                    datetime: null,
                    duration_hours: 1,
                }
            },

            timezoneTitle: new Date().toString().match(/([A-Z]+[\+-][0-9]+.*)/)[1],
        }
    },

    methods: {
        getCampaign() {
            this.axios.get(`/crm/campaigns/${this.$route.params.id}`).then(({ data }) => {
                if (data.payload.campaign) {
                    this.campaign = data.payload.campaign
                    this.participant = data.payload.campaign.participant

                    if (data.payload.campaign.participant) {
                        this.tasks = data.payload.campaign.participant.tasks
                        this.link = data.payload.campaign.participant.link
                    }

                    this.applications = data.payload.campaign.applications
                    this.slots = _.orderBy(data.payload.campaign.slots, ['start_at'], ['asc'])

                    if (data.payload.campaign.game) {
                        this.game = data.payload.campaign.game
                        this.keys = data.payload.campaign.game.keys
                    } else {
                        this.keys = []
                    }

                    const streamDeliveryExpectedHours = _.get(data, 'payload.campaign.payload.stream_delivery.expected_hours')

                    if (streamDeliveryExpectedHours) {
                        this.options.stream_delivery.duration_hours = streamDeliveryExpectedHours
                    }
                }
            }).catch((error) => {
                this.$notify({
                    group: 'global',
                    type: 'error',
                    title: 'Error - Campaign Retrieval',
                    text: _.has(error, 'response.data.message') ? error.response.data.message : `There was an error finding this campaign.`,
                    duration: 10000,
                    speed: 1000,
                })
            })
        },

        onSlotClick(slot) {
            if (this.campaign) {
                this.modal.slots.campaign = this.campaign
            }

            this.modal.slots.type = 'create'
            this.modal.slots.slot = slot
            this.$bvModal.show('slot-application-modal')
        },

        onSlotDeleteClick(slot) {
            if (this.campaign) {
                this.modal.slots.campaign = this.campaign
            }

            this.modal.slots.type = 'delete'
            this.modal.slots.slot = slot
            this.$bvModal.show('slot-application-modal')
        },

        onTaskAction({ taskId, campaign, keyId }) {
            this.modal.keys.task_id = taskId
            this.modal.keys.campaign = this.campaign

            const keycode = _.find(this.keys, item => item.id === keyId)

            if (keycode) {
                this.modal.keys.keycode = keycode
                this.modal.keys.type = 'view'
            } else {
                this.modal.keys.type = 'create'
            }

            this.$bvModal.show('key-modal')
        },

        onKeyModalRetrieveSubmit({ key, task }) {
            const previousTaskIndex = _.findIndex(this.tasks, item => item.id === task.id)
            const previousTask = this.tasks[previousTaskIndex]

            console.log(`onKeyModalRetrieveSubmit`, task, previousTaskIndex)

            // We do it this way so that we trigger vue reactivity
            if (previousTaskIndex !== -1) {
                Vue.set(this.tasks, previousTaskIndex, _.assign({}, previousTask, task))
                console.log(`Doing vue set`, this.tasks, previousTaskIndex, previousTask, task)
            }

            if (this.keys && Array.isArray(this.keys)) {
                this.keys.push(key)
            }

            // Let's show the key right away after submission
            this.modal.keys.keycode = key
            this.modal.keys.type = 'view'

            this.$bvModal.show('key-modal')
        },

        onSlotApplicationModalSubmit(type, slot) {
            if (type === 'create') {
                this.axios.post(`/crm/campaigns/${this.$route.params.id}/applications`, {
                    slots: [slot],
                    platform_id: 'twitch',
                }).then(({ data }) => {
                    this.applications.push(...data.payload.applications)

                    const matchedSlot = _.find(this.slots, item => item.id === slot.id)

                    if (matchedSlot) {
                        Vue.set(matchedSlot, 'is_selected', true)
                    }

                    this.$notify({
                        group: "global",
                        type: "success",
                        text: `Successfully applied to selected slot.`,
                        duration: 5000,
                        speed: 1000,
                    })
                }).catch((error) => {
                    const message = error.message ? error.message : `Error applying to selected campaign slot.`

                    this.$notify({
                        group: 'global',
                        type: 'error',
                        title: 'Error - Apply to Slot',
                        text: message,
                        duration: 10000,
                        speed: 1000,
                    })
                })

            } else if (type === 'delete') {
                this.axios.delete(`/crm/campaigns/${this.$route.params.id}/applications`, {
                    data: {
                        slots: [slot],
                    },
                }).then(({ data }) => {
                    this.applications = _.filter(this.applications, item => item.slot_id !== slot.id)

                    const matchedSlot = _.find(this.slots, item => item.id === slot.id)

                    if (matchedSlot) {
                        Vue.set(matchedSlot, 'is_selected', false)
                    }

                    this.$notify({
                        group: "global",
                        type: "success",
                        text: `Successfully deleted slot application.`,
                        duration: 5000,
                        speed: 1000,
                    })
                }).catch((error) => {
                    const message = error.message ? error.message : `Error deleting slot application.`

                    this.$notify({
                        group: 'global',
                        type: 'error',
                        title: 'Error - Apply to Slot',
                        text: message,
                        duration: 10000,
                        speed: 1000,
                    })
                })
            }
        },

        onApplyClick() {
            const hasStartTime = !!this.options.stream_delivery.datetime
            const hasDuration = !!this.options.stream_delivery.duration_hours

            if (!hasStartTime || !hasDuration) {
                this.$notify({
                    group: 'global',
                    type: 'error',
                    title: 'Error - Apply to Campaign',
                    text: `Please provide a start time and duration for your stream.`,
                    duration: 10000,
                    speed: 1000,
                })

                return
            }

            this.axios.post(`/crm/campaigns/${this.$route.params.id}/participants`, {
                start_at: moment.utc(this.options.stream_delivery.datetime).format(),
                end_at: moment.utc(this.options.stream_delivery.datetime).add(this.options.stream_delivery.duration_hours, 'hours').format(),
            }).then(({ data }) => {
                this.tasks = data.payload.tasks
                this.participant = data.payload.participant
                this.link = data.payload.link
            }).catch((error) => {
                this.$notify({
                    group: 'global',
                    type: 'error',
                    title: 'Error - Apply to Campaign',
                    text: _.has(error, 'response.data.message') ? error.response.data.message : `There was an error applying for this campaign.`,
                    duration: 10000,
                    speed: 1000,
                })
            })
        },
    }
}
</script>

<style lang="scss">
.campaign-container {
    .content {
        display: flex;

        .info {
            width: 50%;

            p {
                font-size: 18px;
            }
        }

        img {
            height: 100%;
            margin: 0 auto;
        }
    }

    .row-panels {
        .panel-steam-stream-guide {
            opacity: 0.9;
            cursor: pointer;

            &:hover {
                opacity: 1;
            }
        }
    }

    .task-container {
        width: 70%;
        margin: 10px 0;
        padding: 1em;
        border-radius: 6px;
        background: rgba(white, 0.05);
    }

    .row-showcase {
        .col-asset {
            overflow: hidden;
            border-radius: 5px;
            position: relative;
            display: flex;

            &:hover {
                .action {
                    animation: float-subtle 1.5s infinite;
                }
            }

            .spacer {
                width: 100%;
                height: 0;
                position: relative;
                overflow: hidden;
                padding: 28.125% 0;
            }

            &.selected {
                .action, .label {
                    display: none;
                }

                .image-asset:before {
                    opacity: 0;
                    z-index: -1;
                }
            }

            .image-asset, .video-asset {
                height: 100%;
                width: 100%;
                background-size: cover;
                background-position: center;
                position: absolute;
                top: 0;
                left: 0;
                border-radius: 5px;

                iframe {
                    border-radius: 5px;
                }
            }

            .image-asset {
                &:before {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 50%;
                    bottom: 0;
                    left: 0;
                    background: linear-gradient(to top, rgba(0, 0, 0, 0.75), transparent);
                    z-index: 1;
                    border-radius: 5px;
                    transition: 0.5s all;
                }
            }

            .label {
                position: absolute;
                bottom: 0;
                left: 0;
                z-index: 4;
                transition: all 0.25s;
                color: #eee;
                text-transform: uppercase;
                font-weight: 700;
                letter-spacing: -0.5px;
                text-shadow: 0px 8px 15px rgba(0, 0, 0, 1);
                font-family: 'Inter', sans-serif;
                line-height: 1em;
                transition: all 0.5s;
            }

            .action {
                position: absolute;
                bottom: 0;
                right: 0;
                z-index: 5;
                cursor: pointer;
                transition: all 0.5s;
                color: #ddd;

                svg {
                    filter: drop-shadow(0px 8px 15px rgba(0, 0, 0, 0.75));
                }

                &:hover {
                    transform: scale(1.1);
                    color: #fff;
                }
            }
        }

        .swiper-container {
            overflow: visible;

            &:hover {
                .swiper-button-prev, .swiper-button-next {
                    opacity: 1;

                    &.swiper-button-disabled {
                        opacity: 0.5;
                    }
                }
            }


            .swiper-button-prev, .swiper-button-next {
                padding: 0 20px;
                transition: all 0.25s;
                opacity: 0;
                outline: 0;
                z-index: 25;

                &:hover {
                    transform: scale(1.25);
                }
            }

            .swiper-button-next {
                right: -50px;
            }

            .swiper-button-prev {
                left: -50px;
            }
        }
    }
}
</style>
