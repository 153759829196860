<template>
    <div class="app-wrapper">
        <notifications group="global" />

        <div v-if="isFullscreen" id="#app" class="app-fullscreen">
            <router-view :key="$route.path"/>
        </div>

        <div v-else id="app" class="app-user" :class="{ 'full-width': isFullwidth }">
            <modal-manager></modal-manager>

            <!-- Resource Managers -->
            <notification-manager v-if="isAuthenticated"></notification-manager>

            <user-topbar></user-topbar>

            <div
                :class="{
                    'container': !isFullwidth,
                    'container-fluid': isFullwidth,
                    'browser-view': $route.name === 'browser-view',
                }"
            >
                <div class="user-layout-wrapper">
                    <router-view name="header" v-if="!isFullwidth"></router-view>

                    <main>
                        <transition
                            name="fade"
                            origin="center"
                            mode="out-in"
                            :duration="250"
                            v-bind:css="false"
                        >
                            <router-view :key="$route.path"/>
                        </transition>
                    </main>
                </div>

                <router-view name="footer"></router-view>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapGetters } from 'vuex'
    import NotificationManager from '@/components/Managers/NotificationManager'
    import ModalManager from '@/components/Managers/ModalManager'
    import UserTopbar from './layout/UserTopbar'

    export default {
        components: {
            NotificationManager,
            ModalManager,
            UserTopbar,
        },

        metaInfo: {
            titleTemplate: (titleChunk) => {
                return titleChunk ? `${titleChunk} - Streamforge` : 'Streamforge';
            }
        },

        created() {
            if (this.isAuthenticated && this.$route.name !== 'clickthrough') {
                this.bootstrapAxios()

                // Refresh user data
                this.getSelf()
                this.getRank()
            }
        },

        mounted() {
            if (process.env.VUE_APP_INTERCOM_ENABLED == 'true') {
                if (this.$route.name !== 'clickthrough') {
                    this.$intercom.boot()
                }
            }
        },

        computed: {
            ...mapGetters([
                'tokens',
                'isAuthenticated',
                'self'
            ]),

            isFullscreen() {
                return this.$route.meta.layout && this.$route.meta.layout === 'fullscreen'
            },

            isFullwidth() {
                return this.$route.meta.layout && this.$route.meta.layout === 'fullwidth'
            },
        },

        watch: {
            isAuthenticated(value) {
                if (value) {
                    // Setup Axios
                    this.bootstrapAxios()

                    // Request initial user state
                    this.getSelf()
                    this.getRank()
                }
            },

            tokens(current, previous) {
                if (current.core !== previous.core) {
                    // Setup Axios
                    this.bootstrapAxios()

                    // Request initial user state
                    this.getSelf()
                    this.getRank()
                }
            }
        },

        methods: {
            bootstrapAxios() {
                this.axios.defaults.headers.Authorization = `Bearer ${this.tokens.core}`

                this.axios.interceptors.response.use(
                    (response) => {

                        // If request using a bearer token fails, it has expired, and log them out
                        if (response.status === 401) {

                            console.log(`Saving redirect`, this.$route.fullPath)

                            // Save the URL the user was trying to access so we can redirect to it after
                            this.$store.commit('authentication:redirect', this.$route.fullPath)

                            // Logout
                            this.$store.commit('authentication:logout')

                            // Clear user info
                            this.$store.commit('self:clear')

                            this.$router.push({ name: 'login' })
                        }

                        return response
                    },

                    (error) => {
                        return Promise.reject(error)
                    }
                )
            },

            getSelf() {
                this.axios.get('/me').then(({ data }) => {
                    let email

                    // Store the user
                    if (data && data.payload && data.payload.user) {
                        this.$store.commit('self:user', {
                            user: data.payload.user,
                        })
                    }

                    // Store the profiles
                    this.$store.commit('profiles:setAll', { profiles: data.payload.profiles })

                    // A discord should always be returned
                    if (data.payload.profiles.discord) {
                        let discord = data.payload.profiles.discord
                        email = discord.email

                        this.$store.commit('self:avatar', {
                            id: discord.id,
                            avatar: discord.avatar_url
                        })

                        if (process.env.VUE_APP_INTERCOM_ENABLED == 'true') {
                            this.$intercom.update({
                                avatar: {
                                    type: 'avatar',
                                    image_url: discord.avatar_url
                                }
                            })
                        }
                    }

                    if (process.env.VUE_APP_INTERCOM_ENABLED == 'true') {
                        this.$intercom.update({
                            user_id: data.payload.user.id,
                            discord_id: data.payload.user.discord_id,
                            name: data.payload.user.name,
                            email: email,
                            mod_link: `https://app.streamforge.gg/admin/users/${data.payload.user.id}`,
                        })
                    }

                    if (process.env.VUE_APP_ANALYTICS_ENABLED == 'true') {
                        this.$mam.identify({
                            id: data.payload.user.id,
                            options: {
                                newUser: false
                            }
                        })

                        this.$mam.setUserProperties({
                            "$name": data.payload.user.name,
                            "$email": email,
                            "$created": data.payload.user.joined_at,
                            "$last_login": new Date()
                        })

                        this.$openReplayTracker.setUserID(data.payload.user.id)
                        this.$openReplayTracker.setMetadata('email', email)
                        this.$openReplayTracker.setMetadata('created_at', data.payload.user.joined_at)
                        this.$openReplayTracker.setMetadata('rank', data.payload.user.rank)
                    }

                    this.$store.commit('self:superviews', {
                        superviews: data.payload.superviews,
                    })

                    if (process.env.VUE_APP_INTERCOM_ENABLED == 'true') {
                        if (data.payload.superviews.twitch) {
                            this.$intercom.update({
                                user_id: this.self.user.id,
                                rank: _.get(data, 'payload.superviews.twitch.rank.name'),
                                followers_count: _.get(data, 'payload.superviews.twitch.channel.followers'),
                            })
                        }
                    }
                })
            },

            getRank() {
                this.axios.get('/ranks').then(({ data }) => {
                    this.$store.commit('thresholds:ranks', { ranks: data.payload.ranks })
                })
            },
        }
    }
</script>
<style lang="scss">
.app-fullscreen {
    // height: 100vh;
}

.browser-view {
    padding: 0 !important;
}

.app-user {
    height: 100vh;
    display: flex;
    flex-direction: column;

    .user-layout-wrapper {
        width: 100%;
        display: grid;
        grid-template-columns: auto 1fr;
        grid-gap: 32px;
    }

    header {
        width: 240px;
    }

    .page {
        margin: 0;
    }

    &.full-width {
        .user-layout-wrapper {
            display: flex;
        }

        header {
            display: none;
        }
    }

    .container {
        flex: 1;
        display: flex;
        flex-direction: column;
    }
}

.vue-notification-group {
    top: 25px !important;
    right: 25px !important;
}

.vue-notification {
    padding: 10px;
    font-size: 12px;
    border-radius: 5px;

    color: #ffffff;
    background: #44A4FC;
    border-left: 5px solid #187FE7;

    &.warn {
        background: #ffb648;
        border-left-color: #f48a06;
        color: #000;
    }

    &.error {
        background: #E54D42;
        border-left-color: #B82E24;
    }

    &.success {
        background: #68CD86;
        border-left-color: #42A85F;
    }
}

@media(max-width: 767px) {
    .intercom-launcher {
        display: none;
    }
}
</style>
