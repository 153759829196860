export default function(multianalytics) {

    // If tracking is disabled, don't send events
    const isDisabled = !(process.env.VUE_APP_ANALYTICS_ENABLED == 'true')

    return {
        onUserClick(input) {
            if (isDisabled) return

            if (input) {
                multianalytics.trackEvent({
                    category: input.category,
                    action: 'click',
                    label: input.label,
                    value: input.value,
                    properties: input.properties ? input.properties : {}
                })
            } else {
                multianalytics.trackError({
                    description: 'Fatal error'
                })
            }
        },

        onUserHover(input) {
            if (isDisabled) return

            if (input) {
                multianalytics.trackEvent({
                    category: input.category,
                    action: 'hover',
                    label: input.label,
                    value: input.value,
                    properties: input.properties ? input.properties : {}
                })
            } else {
                multianalytics.trackError({
                    description: 'Fatal error'
                })
            }
        },

        identify(input) {
            if (isDisabled) return

            multianalytics.identify({
                userId: input.id,
                options: input.options
            })
        },

        setUserProperties(properties) {
            if (isDisabled) return

            multianalytics.setUserProperties(properties)
        }
    }
}
