<template>
    <div class="page page-analytics">
        <div class="row mb-md">
            <div class="col-lg-8">
                <h1 class="display-2">
                    <font-awesome-icon
                    :icon="['fas', 'chart-bar']"
                    class="my-auto mr-3 text-primary"/>Analytics
                </h1>
                <p>Here you can find your combined stats and a list of your recent streams. Please note that our statistics are calculated independently and will not be the exact same as what you would see on your Twitch dashboard.</p>
            </div>
        </div>

        <div class="row row-panels row-metrics" v-if="metrics">
            <div class="col-lg-8 mb-3">
                <h2 class="display-4 m-0 d-inline-block position-relative">Analytics</h2>
            </div>

            <div class="col-lg-4 mb-3 d-flex">
                <metric-window-switcher
                    :metric-windows="metricWindows"
                    class="ml-auto my-auto"
                    @switched="onMetricWindowSwitch"
                />
            </div>

            <div class="col-lg-3">
                <div class="panel p-3 d-flex">
                    <div class="icon-wrapper d-flex my-auto">
                        <font-awesome-icon class="m-auto" :icon="['fas', 'eye']" size="lg" />
                    </div>
                    <div class="metric-wrapper ml-3 my-auto">
                        <small>Avg. Viewers</small>
                        <h3 class="m-0 metric-value">{{ metrics.aggregation.viewers.avg | numeral('0,0') }}</h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-3">
                <div class="panel p-3 d-flex">
                    <div class="icon-wrapper d-flex my-auto">
                        <font-awesome-icon class="m-auto" :icon="['fas', 'eye']" size="lg" />
                    </div>
                    <div class="metric-wrapper ml-3 my-auto">
                        <small>Peak Viewers</small>
                        <h3 class="m-0 metric-value">{{ metrics.aggregation.viewers.peak.value | numeral('0,0') }}</h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-3">
                <div class="panel p-3 d-flex">
                    <div class="icon-wrapper d-flex my-auto">
                        <font-awesome-icon class="m-auto" :icon="['fas', 'eye']" size="lg" />
                    </div>
                    <div class="metric-wrapper ml-3 my-auto">
                        <small>Minutes Watched</small>
                        <h3 class="m-0 metric-value">{{ metrics.aggregation.minutes_watched.sum | numeral('0,0') }}</h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-3">
                <div class="panel p-3 d-flex">
                    <div class="icon-wrapper d-flex my-auto">
                        <font-awesome-icon class="m-auto" :icon="['fas', 'eye']" size="lg" />
                    </div>
                    <div class="metric-wrapper ml-3 my-auto">
                        <small>Total Followers</small>
                        <h3 class="m-0 metric-value">{{ self.superviews.twitch ? self.superviews.twitch.channel.followers : 0 | numeral('0,0') }}</h3>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-md">
            <div class="col-lg-12">
                <h2 class="display-4 d-inline-block position-relative mb-0">
                    Recent Streams
                </h2>
            </div>
        </div>

        <streams-with-chapters
            :items="streams"
        />
    </div>
</template>
<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'
import StreamsWithChapters from '@/components/Tables/StreamsWithChapters'
import MetricWindowSwitcher from '@/components/Switchers/MetricWindowSwitcher'

export default {
    name: 'analytics-view',

    metaInfo() {
        return {
            title: 'Streams'
        }
    },

    components: {
        StreamsWithChapters,
        MetricWindowSwitcher,
    },

    data() {
        return {
            streams: [],
            chaptersByStreamId: {},
            loaded: null,

            state: {
                metricWindow: {
                    activeKey: 'day_90',
                },
            },
        }
    },

    computed: {
        ...mapGetters([
            'self',
            'selfSuperviews'
        ]),

        metrics() {
            const statistics = _.get(this, `self.superviews.twitch.stream.${this.state.metricWindow.activeKey}`)
            if (!statistics) return null
            return statistics
        },

        metricWindows() {
            const metricWindows = _.get(this, 'self.superviews.twitch.stream')
            if (!metricWindows) return null

            return _.map(metricWindows, (streamWindowMetrics, key) => {
                return {
                    value: key,
                    label: `${key.replace('day_', '')} days`,
                }
            })
        },
    },

    created() {
        this.getStreams()
    },

    methods: {
        getStreams() {
            this.axios.get(`/streams`).then(({ data }) => {
                this.streams = _.map(data.payload.streams, (stream) => {
                    stream.chapters = data.payload.chaptersByStreamId[stream._id]
                    return stream
                })

                this.chaptersByStreamId = data.payload.chaptersByStreamId
                this.loaded = true
            }).catch(({ message }) => {
                this.loaded = false
                console.log(message)
            })
        },

        onMetricWindowSwitch(newValue) {
            this.state.metricWindow.activeKey = newValue
        },
    }
}
</script>
<style lang="scss">
    .page-analytics {
        .row-metrics {
            .icon-wrapper {
                height: 60px;
                width: 60px;
                background: hsla(0,0%,100%,.05);
                border-radius: 50%;
                color: #fff;
            }

            .metric-value {
                font-weight: 700;
                font-size: 28px;
                line-height: 32px;
                color: #fff;
            }
        }

        .stream-count {
            color: #fcd878;
            font-size: 1em;
            margin: auto 0;
            font-weight: 600 !important;
        }
    }
</style>
