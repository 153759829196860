<template>
    <div class="showcase-slider-steam">
        <swiper class="swiper gallery-top" :options="swiperOptionTop" ref="swiperTop">
            <swiper-slide
                v-for="(slide, index) in slides"
                class="col-asset"
            >
                <div class="spacer" />

                <div class="video-asset" v-if="slide.type === 'video'">
                    <iframe
                        frameborder="0"
                        scrolling="no"
                        marginheight="0"
                        marginwidth="0"
                        width="100%"
                        height="100%"
                        type="text/html"
                        :src="`https://www.youtube.com/embed/${slide.video_id}?autoplay=1&fs=0&iv_load_policy=3&showinfo=0&rel=0&cc_load_policy=0&start=0&end=0`"
                    />
                </div>

                <div
                    v-if="slide.type === 'screenshot'"
                    class="image-asset"
                    :style="`background-image: url(${slide.image_url})`"
                />
            </swiper-slide>

            <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
            <div class="swiper-button-next swiper-button-white" slot="button-next"></div>
        </swiper>

        <div class="custom-thumbs">
            <div
                v-for="(slide, index) in thumbnails.slides"
                class="col-asset d-flex"
                :class="{ 'selected': index === activeIndex }"
                @click="selectItem(index)"
            >
                <div class="spacer" />

                <div
                    class="image-asset"
                    :style="`background-image: url(${slide.image_url})`"
                />

                <div class="action m-auto" v-if="slide.type === 'video'">
                    <font-awesome-icon :icon="['fas', 'play']" size="lg" />
                </div>
            </div>

            <div
                class="previous-button"
                @click="setThumbnailGroupIndex(state.thumbnails.groupIndex - 1)"
                :class="{
                    'disabled': !thumbnails.hasPrev,
                }"
            >
                <font-awesome-icon :icon="['fas', 'chevron-left']" size="lg" />
            </div>
            <div
                class="next-button"
                @click="setThumbnailGroupIndex(state.thumbnails.groupIndex + 1)"
                :class="{
                    'disabled': !thumbnails.hasNext,
                }"
            >
                <font-awesome-icon :icon="['fas', 'chevron-right']" size="lg" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'showcase-slider',

    props: {
        slides: {
            type: Array,
        },
    },

    data() {
        return {
            selected: null,

            activeIndex: 0,

            swiperOptionTop: {
                loop: false,
                // loopedSlides: 5, // looped slides should be the same
                spaceBetween: 10,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                allowTouchMove: false,
            },

            state: {
                thumbnails: {

                    // Change the CSS Grid template if you change this
                    slidesPerView: 7,

                    groupIndex: 0,
                },
            },
        }
    },

    computed: {
        thumbnails() {
            if (!this.slides || !this.slides.length) return []

            const maxSlideIndex = this.slides.length - 1
            const start = this.state.thumbnails.groupIndex * this.state.thumbnails.slidesPerView
            const end = (this.state.thumbnails.groupIndex + 1) * this.state.thumbnails.slidesPerView

            return {
                slides: this.slides.slice(start, end),
                hasNext: (end + 1) < maxSlideIndex,
                hasPrev: this.state.thumbnails.groupIndex !== 0,
            }
        },
    },

    methods: {
        expandAsset(id) {
            if (this.selected === id) this.selected = false
            else this.selected = id
        },

        selectItem(index) {
            this.activeIndex = index
            this.$refs.swiperTop.swiper.slideTo(index, 0)
            this.$refs.swiperTop.swiper.update()
        },

        setThumbnailGroupIndex(newIndex) {
            if (!this.slides.length) {
                this.state.thumbnails.groupIndex = 0
                return
            }

            if (newIndex < 0) return

            if ((newIndex * 7) > this.slides.length - 1) {
                return
            } else {
                this.state.thumbnails.groupIndex = newIndex
            }
        },
    },
}
</script>

<style lang="scss">
.showcase-slider-steam {
    overflow: hidden;

    .col-asset {
        overflow: hidden;
        border-radius: 4px;
        position: relative;
        display: flex;

        .spacer {
            width: 100%;
            height: 0;
            position: relative;
            overflow: hidden;
            padding: 28.125% 0;
        }

        &.selected {
            .action, .label {
                opacity: 0;
            }

            .image-asset:before {
                opacity: 0;
                z-index: -1;
            }
        }

        .image-asset, .video-asset {
            height: 100%;
            width: 100%;
            background-size: cover;
            background-position: center;
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 5px;

            iframe {
                border-radius: 5px;
            }
        }

        .label {
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 4;
            transition: all 0.25s;
            color: #eee;
            text-transform: uppercase;
            font-weight: 700;
            letter-spacing: -0.5px;
            text-shadow: 0px 8px 15px rgba(0, 0, 0, 1);
            font-family: 'Inter', sans-serif;
            line-height: 1em;
            transition: all 0.5s;
        }

        .action {
            transition: opacity 0.5s;
            color: #ddd;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;

            svg {
                filter: drop-shadow(0px 8px 15px rgba(0, 0, 0, 0.75));
                margin: auto;
            }
        }
    }

    .gallery-top {
        .image-asset {
            &:before {
                content: '';
                position: absolute;
                width: 100%;
                height: 50%;
                bottom: 0;
                left: 0;
                background: linear-gradient(to top, rgba(0, 0, 0, 0.75), transparent);
                z-index: 1;
                border-radius: 5px;
                transition: 0.5s all;
            }
        }
    }

    .custom-thumbs {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        grid-gap: 10px;
        height: 60px;
        margin-top: 10px;
        padding: 0 35px;
        position: relative;

        .previous-button, .next-button {
            top: 0;
            position: absolute;
            z-index: 10;
            background: rgba(white, 0.05);
            border-radius: 4px;
            height: 100%;
            width: 25px;
            display: flex;

            &.disabled {
                opacity: 0.5;
            }

            &:not(.disabled) {
                cursor: pointer;

                svg {
                    transition: opacity 200ms ease-in-out 0s;
                }

                &:hover {
                    svg {
                        opacity: 1;
                    }
                }
            }

            svg {
                color: #ddd;
                margin: auto;
            }
        }

        .previous-button {
            left: 0;
        }

        .next-button {
            right: 0;
        }

        .col-asset {
            cursor: pointer;

            &:hover {
                .image-asset {
                    opacity: 1;
                }
            }

            .image-asset {
                opacity: 0.5;
                transition: opacity 200ms ease-in-out 0s;
                border: 1px solid transparent;
            }

            &.selected {
                .image-asset {
                    opacity: 1;
                    border: 1px solid rgba(255, 255, 255, 0.7);
                }
            }
        }
    }

    .swiper {
        .swiper-button-prev {
            left: 15px;
            opacity: 0;
            transition: transform 0.35s cubic-bezier(1, 0.09, 0.03, 0.93) 0s, opacity 0.35s ease 0s;
        }

        .swiper-button-next {
            right: 15px;
            opacity: 0;
            transition: transform 0.35s cubic-bezier(1, 0.09, 0.03, 0.93) 0s, opacity 0.35s ease 0s;
        }

        &:hover {
            .swiper-button-prev {
                opacity: 1;
            }

            .swiper-button-next {
                opacity: 1;
            }

            .swiper-button-disabled {
                opacity: 0.35;
            }
        }

        .swiper-slide {
            background-size: cover;
            background-position: center;
            overflow: hidden;
        }

        &.gallery-top {
            // height: 80%;
            width: 100%;
        }

        &.gallery-thumbs {
            // height: 20%;
            box-sizing: border-box;
            margin-top: 10px;
        }

        &.gallery-thumbs .swiper-slide {
            width: 25%;
            height: 100%;
            opacity: 0.4;
        }

        &.gallery-thumbs .swiper-slide-active {
            opacity: 1;
        }
    }
}
</style>
