<template>
    <div class="page page-creator">
        <hire-modal
            :superview="superview"
            :platform_id="state.platform_id"
        />
        <player-modal
            :show="modals.player.is_shown"
            @close="modals.player.is_shown = false"
            v-if="modals.player.is_shown"
        >
            <iframe
                :src="`https://clips.twitch.tv/embed?clip=${modals.player.clip_id}&parent=${hostname}`"
                autplay="true"
                height="720"
                width="1280"
                allowfullscreen="true">
            </iframe>
        </player-modal>

        <div class="fixed-width-profile mb-md row no-gutters" v-if="superview">
            <div
                v-if="state.platform_id === 'twitch'"
                class="banner-wrapper"
                :style="{
                    'background-image': `url(${superview.channel.profile_banner})`
                }"
            />

            <div
                v-if="state.platform_id === 'youtube'"
                class="banner-wrapper"
                :style="{
                    'background-image': `url(https://streamforge.nyc3.cdn.digitaloceanspaces.com/resume/streamforge-youtube-banner.png)`
                }"
            />

            <div class="logo-wrapper d-flex">
                <div class="logo-inner mx-auto">
                    <div
                        v-if="state.platform_id === 'twitch'"
                        class="logo w-100 h-100"
                        :style="{
                            'background-image': `url(${superview.channel.logo})`
                        }"
                    />
                    <div
                        v-if="state.platform_id === 'youtube'"
                        class="logo w-100 h-100"
                        :style="{
                            'background-image': `url(${superview.channel.snippet.thumbnails.medium.url})`
                        }"
                    />
                </div>
            </div>

            <div class="col-lg-12 mt-4 metadata-wrapper">
                <div class="row">
                    <div class="col-lg-4">
                        <div class="platform-wrapper d-flex">
                            <div
                                class="platform twitch d-flex"
                                v-if="superviews && superviews.twitch"
                                :class="{
                                    'active': state.platform_id === 'twitch',
                                }"
                            >
                                <div class="platform-inner">
                                    <font-awesome-icon size="md" :icon="['fab', 'twitch']" />
                                    <span class="ml-2">Twitch</span>
                                </div>
                            </div>

                            <div
                                class="platform youtube d-flex disabled"
                                v-if="superviews"
                                v-tooltip="'Coming soon!'"
                                :class="{
                                    'active': state.platform_id === 'youtube',
                                }"
                            >
                                <div class="platform-inner">
                                    <font-awesome-icon size="md" :icon="['fab', 'youtube']" />
                                    <span class="ml-2">YouTube</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 d-flex">
                        <div class="name-wrapper m-auto d-flex flex-column">
                            <div class="name tex-center d-flex mx-auto" v-if="state.platform_id === 'twitch'">
                                <h1 class="h4 mb-0">{{ superview.channel.display_name }}</h1>
                                <div class="my-auto ml-1">
                                    <verified-badge platform="twitch" />
                                </div>
                            </div>
                            <div class="name tex-center d-flex mx-auto" v-if="state.platform_id === 'youtube'">
                                <h1 class="h4 mb-0">{{ superview.channel.snippet.title }}</h1>
                                <div class="my-auto ml-1">
                                    <verified-badge platform="youtube" />
                                </div>
                            </div>
                            <div class="socials-wrapper mt-3 d-flex justify-content-center">
                                <a :href="social.href" v-for="social in socials" class="social d-flex" :class="social.platform.key" target="blank">
                                    <font-awesome-icon size="md" :icon="social.platform.icon" class="my-auto" />
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 d-flex">
                        <b-button
                            variant="primary"
                            class="ml-auto my-auto px-5 py-3"
                            size="lg"
                            @click="openModal('hire-modal')"
                        >
                            Hire me
                        </b-button>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 mt-md key-metrics-wrapper">
                <div class="section-header w-100 d-flex">
                    <div class="title-wrapper d-flex">
                        <h2 class="section-header-title my-auto">Key Metrics</h2>
                    </div>

                    <div class="window-switcher-wrapper ml-auto d-flex">
                        <div class="window-switcher d-flex my-auto">
                            <div
                                v-for="metricWindowOption in options.metricWindow"
                                class="window d-flex px-3 text-center"
                                :class="{
                                    'active': state.metricWindow === metricWindowOption.value
                                }"
                                @click="setMetricWindow(metricWindowOption.value)"
                            >
                                <div class="window-inner">
                                    <span>{{ metricWindowOption.title }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row row-panels mt-4" v-if="state.platform_id === 'twitch' && superview">
                    <div class="col-lg-3 col-metric">
                        <div class="panel p-3 d-flex">
                            <div class="icon-wrapper d-flex my-auto">
                                <font-awesome-icon :icon="['fas', 'eye']" size="lg" class="m-auto" />
                            </div>
                            <div class="metric-wrapper ml-3 my-auto">
                                <small>Avg. Viewers</small>
                                <h3 class="m-0">{{ superview.stream[state.metricWindow].aggregation.viewers.avg | numeral('0,0') }}</h3>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-metric">
                        <div class="panel p-3 d-flex">
                            <div class="icon-wrapper d-flex my-auto">
                                <font-awesome-icon :icon="['fas', 'users']" size="lg" class="m-auto" />
                            </div>
                            <div class="metric-wrapper ml-3 my-auto">
                                <small>Impressions</small>
                                <h3 class="m-0">{{ superview.stream[state.metricWindow].aggregation.impressions.sum | numeral('0.0a') }}</h3>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-metric">
                        <div class="panel p-3 d-flex">
                            <div class="icon-wrapper d-flex my-auto">
                                <font-awesome-icon :icon="['fas', 'clock']" size="lg" class="m-auto" />
                            </div>
                            <div class="metric-wrapper ml-3 my-auto">
                                <small>Avg. Stream Duration</small>
                                <h3 class="m-0">{{ toHumanDuration(superview.stream[state.metricWindow].aggregation.live_seconds.avg) }}</h3>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-metric">
                        <div class="panel p-3 d-flex">
                            <div class="icon-wrapper d-flex my-auto">
                                <font-awesome-icon :icon="['fas', 'user-plus']" size="lg" class="m-auto" />
                            </div>
                            <div class="metric-wrapper ml-3 my-auto">
                                <small>Total Followers</small>
                                <h3 class="m-0">{{ superview.channel.followers | numeral('0.0a') }}</h3>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row row-panels mt-4" v-if="state.platform_id === 'youtube' && superview">
                    <div class="col-lg-3 col-metric">
                        <div class="panel p-3 d-flex">
                            <div class="icon-wrapper d-flex my-auto">
                                <font-awesome-icon :icon="['fas', 'eye']" size="lg" class="m-auto" />
                            </div>
                            <div class="metric-wrapper ml-3 my-auto">
                                <small>Avg. Views per Video</small>
                                <h3 class="m-0">{{ superview.video[state.metricWindow].aggregation.views.avg | numeral('0.0a') }}</h3>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-metric">
                        <div class="panel p-3 d-flex">
                            <div class="icon-wrapper d-flex my-auto">
                                <font-awesome-icon :icon="['fas', 'users']" size="lg" class="m-auto" />
                            </div>
                            <div class="metric-wrapper ml-3 my-auto">
                                <small>Avg. Likes</small>
                                <h3 class="m-0">{{ superview.video[state.metricWindow].aggregation.likes.avg | numeral('0a') }}</h3>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-metric">
                        <div class="panel p-3 d-flex">
                            <div class="icon-wrapper d-flex my-auto">
                                <font-awesome-icon :icon="['fas', 'clock']" size="lg" class="m-auto" />
                            </div>
                            <div class="metric-wrapper ml-3 my-auto">
                                <small>Videos Published</small>
                                <h3 class="m-0">{{ superview.video[state.metricWindow].accumulation.videos.count | numeral('0,0') }}</h3>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-metric">
                        <div class="panel p-3 d-flex">
                            <div class="icon-wrapper d-flex my-auto">
                                <font-awesome-icon :icon="['fas', 'user-plus']" size="lg" class="m-auto" />
                            </div>
                            <div class="metric-wrapper ml-3 my-auto">
                                <small>Total Subscibers</small>
                                <h3 class="m-0">{{ superview.channel.statistics.subscriberCount | numeral('0.0a') }}</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 mt-md about-wrapper">
                <div class="row">
                    <div class="col-lg-4">
                        <div class="section-header w-100 d-flex">
                            <div class="title-wrapper editable d-flex">
                                <h2 class="section-header-title my-auto">About me</h2>
                            </div>
                        </div>

                        <div class="row row-panels mt-4">
                            <div class="col-lg-12 details-wrapper">
                                <div class="panel p-3 d-flex">
                                    <div class="detail my-auto w-50" v-if="state.platform_id === 'twitch'">
                                        <small>Language</small>
                                        <h3 class="m-0" v-if="resume && resume.language">{{ getFromDictionary(`locales.${resume.language}.title`) }}</h3>
                                        <h3 class="m-0" v-else-if="superview.channel.language">{{ getFromDictionary(`locales.${superview.channel.language}.title`) }}</h3>
                                        <h3 class="m-0" v-else>Not specified</h3>
                                    </div>

                                    <div class="detail my-auto w-50" v-if="state.platform_id === 'youtube'">
                                        <small>Country</small>
                                        <h3 class="m-0" v-if="resume && resume.language">{{ getFromDictionary(`locales.${resume.language}.title`) }}</h3>
                                        <h3 class="m-0" v-else-if="superview.channel.snippet.country">{{ getFromDictionary(`countries.${superview.channel.snippet.country.toLowerCase()}.title`) }}</h3>
                                        <h3 class="m-0" v-else>Not specified</h3>
                                    </div>

                                    <div class="detail my-auto w-50" v-if="state.platform_id === 'twitch'">
                                        <small>Last Live</small>
                                        <h3 class="m-0">{{ superview._updated_at | moment('from') }}</h3>
                                    </div>

                                    <div class="detail my-auto w-50" v-if="state.platform_id === 'youtube'">
                                        <small>Last Video Posted</small>
                                        <h3 class="m-0">{{ superview._last_video_at | moment('from') }}</h3>
                                    </div>
                                </div>
                            </div>

                            <div
                                class="conditional-wrapper"
                                :class="{
                                    'data-missing': !resume
                                }"
                            >
                                <div class="col-lg-12 mt-sm attributes-wrapper">
                                    <h3 class="text-uppercase">Attributes</h3>

                                    <div class="row mt-3">
                                        <div class="col-lg-12 attribute d-flex">
                                            <h4>Humor</h4>
                                            <div class="circles d-flex">
                                                <div
                                                    class="circle"
                                                    v-for="i in 10"
                                                    :class="{
                                                        'active': i <= (resume && resume.attributes ? resume.attributes.humor : 0)
                                                    }"
                                                />
                                            </div>
                                        </div>
                                        <div class="col-lg-12 attribute mt-4 d-flex">
                                            <h4>Skill</h4>
                                            <div class="circles d-flex">
                                                <div
                                                    class="circle"
                                                    v-for="i in 10"
                                                    :class="{
                                                        'active': i <= (resume && resume.attributes ? resume.attributes.skill : 0)
                                                    }"
                                                />
                                            </div>
                                        </div>
                                        <div class="col-lg-12 attribute mt-4 d-flex">
                                            <h4>Informative</h4>
                                            <div class="circles d-flex">
                                                <div
                                                    class="circle"
                                                    v-for="i in 10"
                                                    :class="{
                                                        'active': i <= (resume && resume.attributes ? resume.attributes.informative : 0)
                                                    }"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-12">
                                    <div class="interests-wrapper">
                                        <h3 class="text-uppercase mt-sm">Interests</h3>
                                        <div class="interests d-flex mt-2" v-if="resume && resume.interests && resume.interests.length">
                                            <div
                                                class="interest"
                                                v-for="interest in resume.interests"
                                            >
                                                <span class="font-weight-normal">{{ getFromDictionary(`resume.interests.${interest}.emoji`) }}</span> {{ getFromDictionary(`resume.interests.${interest}.title`) }}
                                            </div>
                                        </div>

                                        <div class="interests d-flex mt-2" v-else>
                                            <div
                                                class="interest"
                                                v-for="index in 5"
                                            >
                                                <span class="px-4"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="col-lg-8 timeseries-wrapper">
                        <div class="section-header w-100 d-flex">
                            <div class="title-wrapper d-flex">
                                <h2 class="section-header-title my-auto">Metrics over time</h2>
                            </div>
                        </div>

                        <div class="row row-panels mt-4">
                            <div class="col-lg-12">
                                <div class="panel p-3" style="height: 350px;">
                                    <recent-streams-chart
                                        v-if="avgViewersChart"
                                        :timeseries="avgViewersChart"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 mt-md games-wrapper">
                <div class="section-header w-100 d-flex">
                    <div class="title-wrapper d-flex">
                        <h2 class="section-header-title my-auto">Most played games</h2>
                    </div>

                    <div class="window-switcher-wrapper ml-auto d-flex">
                        <div class="window-switcher d-flex my-auto">
                            <div
                                v-for="metricWindowOption in options.metricWindow"
                                class="window d-flex px-3 text-center"
                                :class="{
                                    'active': state.metricWindow === metricWindowOption.value
                                }"
                                @click="setMetricWindow(metricWindowOption.value)"
                            >
                                <div class="window-inner">
                                    <span>{{ metricWindowOption.title }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row mt-4">
                    <div class="col" v-for="game in gamesByMinutesStreamed">
                        <div class="game d-flex flex-column">
                            <div
                                class="game-thumbnail w-100"
                                :style="{
                                    'background-image': game.metadata ? `url(${game.metadata.avatarURL})` : null
                                }"
                            >
                                <div class="dominance-wrapper">
                                    <div class="dominance">
                                        <div class="backdrop" />

                                        <radial-progress-bar
                                            :diameter="44"
                                            :completed-steps="game.dominance_percent * 100"
                                            :total-steps="100"
                                            start-color="#FCD878"
                                            stop-color="#FCD878"
                                            :stroke-width="5"
                                            :inner-stroke-width="5"
                                            inner-stroke-color="#35373f"
                                        >
                                            {{ game.dominance_percent | numeral('0%') }}
                                        </radial-progress-bar>
                                    </div>
                                </div>
                            </div>

                            <div class="game-meta mt-2 px-2">
                                <span class="game-meta-value" v-if="state.platform_id === 'twitch'">{{ toHumanDuration(game.duration_seconds) }}</span>
                                <span class="game-meta-value" v-if="state.platform_id === 'youtube'">{{ game.count | numeral('0,0') }} videos</span>
                                <h3>{{ game.name }}</h3>

                                <div class="metrics mt-2 pt-2 flex-column" v-if="state.platform_id === 'twitch'">
                                    <div class="d-flex justify-content-between">
                                        <span>{{ game.avg_viewers | numeral('0,0') }} avg viewers</span>
                                        <span :class="game.performance.viewers.style" v-tooltip="game.performance.viewers.tooltip">
                                            {{ game.performance.viewers.sign }}{{ Math.abs(game.performance.viewers.percent) | numeral('0.0%') }}
                                        </span>
                                    </div>

                                    <!-- <div class="d-flex mt-1 justify-content-between">
                                        <span v-if="game.avg_impressions >= 1000">{{ game.avg_impressions | numeral('0a') }} avg impressions</span>
                                        <span v-else>{{ game.avg_impressions | numeral('0,0') }} avg impressions</span>
                                        <span :class="game.performance.impressions.style" v-tooltip="game.performance.impressions.tooltip">
                                            {{ game.performance.impressions.sign }}{{ Math.abs(game.performance.impressions.percent) | numeral('0.0%') }}
                                        </span>
                                    </div> -->
                                </div>

                                <div class="metrics mt-2 pt-2 flex-column" v-if="state.platform_id === 'youtube'">
                                    <div class="d-flex justify-content-between">
                                        <span v-if="game.avg_views >= 1000">{{ game.avg_views | numeral('0a') }} avg views</span>
                                        <span v-else>{{ game.avg_views | numeral('0,0') }} avg views</span>
                                        <span :class="game.performance.views.style" v-tooltip="game.performance.views.tooltip">
                                            {{ game.performance.views.sign }}{{ Math.abs(game.performance.views.percent) | numeral('0.0%') }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="gamesByMinutesStreamed && gamesByMinutesStreamed.length < 5" class="col" v-for="i in 5 - gamesByMinutesStreamed.length">
                        <div class="game d-flex flex-column">
                            <div class="game-thumbnail w-100" />

                            <div class="game-meta mt-3 px-2">
                                <span class="game-meta-value">
                                    <span class="skeleton w-25pc h-10px mb-2" />
                                </span>
                                <h3><span class="skeleton w-75pc h-16px" /></h3>

                                <div class="metrics mt-2 pt-2 flex-column">
                                    <div class="d-flex justify-content-between">
                                        <span class="skeleton w-40pc h-14px" />
                                        <span class="skeleton w-20pc h-14px" />
                                    </div>

                                    <div class="d-flex mt-1 justify-content-between">
                                        <span class="skeleton w-60pc h-14px" />
                                        <span class="skeleton w-20pc h-14px" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 mt-md sponsorships-wrapper" v-if="resume">
                <div class="section-header w-100 d-flex">
                    <div class="title-wrapper d-flex">
                        <h2 class="section-header-title my-auto">Past sponsorships</h2>
                    </div>
                </div>

                <!-- <div class="row row-panels mt-4">
                    <div class="col-lg-3 col-metric">
                        <div class="panel p-3 d-flex">
                            <div class="icon-wrapper d-flex my-auto">
                                <font-awesome-icon :icon="['fas', 'users']" size="lg" class="m-auto" />
                            </div>
                            <div class="metric-wrapper ml-3 my-auto">
                                <small>AVG Impressions</small>
                                <h3 class="m-0">{{ superview.stream[state.metricWindow].aggregation.viewers.avg | numeral('0,0') }}</h3>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-metric">
                        <div class="panel p-3 d-flex">
                            <div class="icon-wrapper d-flex my-auto">
                                <font-awesome-icon :icon="['fas', 'external-link-alt']" size="lg" class="m-auto" />
                            </div>
                            <div class="metric-wrapper ml-3 my-auto">
                                <small>AVG Clickthrough Rate</small>
                                <h3 class="m-0">{{ superview.channel.followers | numeral('0.0a') }}</h3>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-metric">
                        <div class="panel p-3 d-flex">
                            <div class="icon-wrapper d-flex my-auto">
                                <font-awesome-icon :icon="['fas', 'eye']" size="lg" class="m-auto" />
                            </div>
                            <div class="metric-wrapper ml-3 my-auto">
                                <small>Viewer Retention</small>
                                <h3 class="m-0">{{ superview.stream[state.metricWindow].aggregation.impressions.avg | numeral('0,0') }}</h3>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-metric">
                        <div class="panel p-3 d-flex">
                            <div class="icon-wrapper d-flex my-auto">
                                <font-awesome-icon :icon="['fas', 'eye']" size="lg" class="m-auto" />
                            </div>
                            <div class="metric-wrapper ml-3 my-auto">
                                <small>Total Sponsorships</small>
                                <h3 class="m-0">{{ toHumanDuration(superview.stream[state.metricWindow].aggregation.live_seconds.avg) }}</h3>
                            </div>
                        </div>
                    </div>
                </div> -->

                <div class="row row-sponsorships row-panels mt-4">
                    <div class="col-lg-12">
                        <swiper
                            :options="swiper"
                            class="pb-4"
                        >
                            <swiper-slide v-for="participant in participants">
                                <div class="sponsorship-wrapper">
                                    <div class="panel p-3">
                                        <div
                                            class="sponsorship-hero position-relative"
                                            :style="{
                                                'background-image': `url(${participant.campaign.supercampaign.payload.showcase.screenshots[0].url})`
                                            }"
                                        >
                                            <b-aspect :aspect="'21:9'">
                                                <div class="badges">
                                                    <div class="badge">

                                                    </div>
                                                </div>
                                            </b-aspect>

                                            <div class="sphere-wrapper">
                                                <div
                                                    class="sphere mx-auto"
                                                    :style="{
                                                        'background-image': `url(${games.byId[participant.campaign.game.twitch_id].avatarURL})`
                                                    }"
                                                />
                                            </div>
                                        </div>

                                        <div class="meta mt-5 text-center">
                                            <h3>{{ participant.campaign.game.name }}</h3>
                                            <p class="mb-2">{{ participant.campaign.supercampaign.stage }}</p>
                                            <small>{{ participant.campaign.started_at | moment('MMMM YYYY') }}</small>
                                        </div>
                                    </div>
                                </div>
                            </swiper-slide>

                            <swiper-slide v-if="participants && participants.length < 3" v-for="i in 3 - participants.length">
                                <div class="sponsorship-wrapper d-flex flex-column">
                                    <div class="p-3">
                                        <div class="sponsorship-hero position-relative">
                                            <b-aspect :aspect="'21:9'"></b-aspect>

                                            <div class="sphere-wrapper w-100 d-flex">
                                                <div class="skeleton circle h-80px w-80px mx-auto" />
                                            </div>
                                        </div>

                                        <div class="game-meta mt-5 px-2">
                                            <div class="metrics pt-2 flex-column">
                                                <div class="d-flex justify-content-center">
                                                    <span class="skeleton w-60pc h-24px" />
                                                </div>

                                                <div class="d-flex mt-1 justify-content-center">
                                                    <span class="skeleton w-20pc h-14px" />
                                                </div>

                                                <!-- <div class="d-flex mt-3 justify-content-center">
                                                    <span class="skeleton w-20pc h-12px" />
                                                </div> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </swiper-slide>

                            <div class="swiper-scrollbar" slot="scrollbar" />
                        </swiper>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 mt-md clips-wrapper">
                <div class="section-header w-100 d-flex">
                    <div class="title-wrapper d-flex">
                        <h2 class="section-header-title my-auto">Top recent clips</h2>
                    </div>

                    <!-- <div class="window-switcher-wrapper ml-auto d-flex">
                        <div class="window-switcher d-flex my-auto">
                            <div
                                v-for="metricWindowOption in options.metricWindow"
                                class="window d-flex px-3 text-center"
                                :class="{
                                    'active': state.metricWindow === metricWindowOption.value
                                }"
                                @click="setMetricWindow(metricWindowOption.value)"
                            >
                                <div class="window-inner">
                                    <span>{{ metricWindowOption.title }}</span>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>

                <div class="row" v-if="clips.is_loaded && clips.is_valid">
                    <div class="col-lg-6 mt-4" v-for="clip in clips.primaries">
                        <div class="clip-wrapper clip-primary d-flex w-100 h-100" @click="onClipClick(clip.id)">
                            <div
                                class="thumbnail"
                                :style="{
                                    'background-image': `url(${clip.thumbnail_url})`
                                }"
                            />
                            <div class="clip w-100 mt-auto px-sm py-sm">
                                <p class="mb-1">{{ games.byId[clip.game_id] ? games.byId[clip.game_id].name : '' }}</p>
                                <h3 class="m-0">{{ clip.title }}</h3>
                                <small>{{ clip.view_count | numeral('0,0') }} views</small>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="row">
                            <div class="col-lg-12 mt-4" v-for="clip in clips.secondaries">
                                <div class="clip-wrapper clip-secondary d-flex" @click="onClipClick(clip.id)">
                                    <div
                                        class="thumbnail w-50"
                                        :style="{
                                            'background-image': `url(${clip.thumbnail_url})`
                                        }"
                                    />
                                    <div class="clip w-50 d-flex flex-column justify-content-center px-sm">
                                        <p class="mb-1">{{ games.byId[clip.game_id] ? games.byId[clip.game_id].name : '' }}</p>
                                        <h3 class="mb-1">{{ clip.title }}</h3>
                                        <small>{{ clip.view_count | numeral('0,0') }} views</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 mt-4">
                        <div class="row">
                            <div class="col-lg-3" v-for="clip in clips.tertiaries">
                                <div class="clip-wrapper clip-tertiary" @click="onClipClick(clip.id)">
                                    <div
                                        class="thumbnail w-100"
                                        :style="{
                                            'background-image': `url(${clip.thumbnail_url})`
                                        }"
                                    />
                                    <div class="clip w-100 px-3 py-4">
                                        <p class="mb-1">{{ games.byId[clip.game_id] ? games.byId[clip.game_id].name : '' }}</p>
                                        <h3 class="mb-1">{{ clip.title }}</h3>
                                        <small>{{ clip.view_count | numeral('0,0') }} views</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="spacer">

        </div>
    </div>
</template>

<script type="text/javascript">
    import _ from 'lodash'
    import numeral from 'numeral'
    import { mapGetters } from 'vuex'
    import HireModal from '@/components/Modals/Resume/HireModal'
    import VerifiedBadge from '@/components/VerifiedBadge'
    import RadialProgressBar from 'vue-radial-progress'
    import TwitchPlayer from '@/components/Media/TwitchPlayer'
    import PlayerModal from '@/components/Modals/PlayerModal'
    import RecentStreamsChart from '@/components/Charts/RecentStreamsChart'

    export default {
        name: 'creator',

        metaInfo() {
            return {
                title: this.page.title,
            }
        },

        components: {
            HireModal,
            VerifiedBadge,
            RadialProgressBar,
            TwitchPlayer,
            PlayerModal,
            RecentStreamsChart,
        },

        data() {
            return {
                hostname: null,

                page: {
                    title: 'Creator Page',
                },

                // Only applicable for registered
                superviews: null,

                superview: null,
                participants: null,
                games: {
                    byId: null,
                    byName: null,
                },
                resume: null,
                avgViewersChart: null,

                clips: {
                    primary: null,
                    secondaries: null,
                    tertiaries: null,
                    is_loaded: false,
                    is_valid: null,
                },

                state: {
                    platform_id: 'twitch',
                    metricWindow: 'day_30',
                },

                options: {
                    metricWindow: [{
                        title: '30 days',
                        value: 'day_30',
                    }, {
                        title: '90 days',
                        value: 'day_90',
                    }, {
                        title: '180 days',
                        value: 'day_180',
                    }, {
                        title: '365 days',
                        value: 'day_365',
                    }],
                    interests: [
                        'food', 'fashion', 'beauty', 'finance',
                        'fitness', 'outdoors', 'music', 'technology',
                        'sports', 'tabletop',
                    ],
                    platforms: ['twitch', 'youtube'],
                },

                modals: {
                    player: {
                        clip_id: null,
                        is_shown: false,
                    },
                },

                swiper: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                    scrollbar: {
                        el: '.swiper-scrollbar',
                        hide: true,
                    },
                },
            }
        },

        created() {
            if (this.$route.meta.platform_id) {
                if (this.options.platforms.includes(this.$route.meta.platform_id)) {
                    this.state.platform_id = this.$route.meta.platform_id
                }
            }

            this.getSuperview()

            if (process.env.VUE_APP_LANDING_URL) {
                const url = new URL(process.env.VUE_APP_LANDING_URL)

                if (url.hostname) {
                    this.hostname = url.hostname
                }
            }
        },

        mounted() {

            // If modal hash is in URL, auto open modal
            if (window.location.hash) {
                this.openModal(window.location.hash.substr(1))
            }
        },

        computed: {
            ...mapGetters([
                'self',
                'colors',
                'isAuthenticated',
                'getFromDictionary',
                'dictionary',
            ]),

            gamesByMinutesStreamed() {
                if (this.state.platform_id === 'twitch') {
                    let gamesAccumulation = _.get(this.superview, `stream[${this.state.metricWindow}].accumulation.games.lookup`)
                    if (!gamesAccumulation) return []

                    const gamesAsList = _.values(gamesAccumulation)
                    const totalSecondsStreamed = _.sumBy(gamesAsList, 'duration_seconds')

                    return _.orderBy(_.values(gamesAsList), ['duration_seconds'], ['desc']).slice(0, 5).map((game) => {
                        game.metadata = this.games.byName[game.name]
                        game.dominance_percent = (game.duration_seconds / totalSecondsStreamed)

                        game.performance = {
                            viewers: {
                                percent: (game.avg_viewers / this.superview.stream[this.state.metricWindow].aggregation.viewers.avg) - 1,
                                sign: '',
                                style: 'text-warning',
                            },
                            impressions: {
                                percent: (game.avg_impressions / this.superview.stream[this.state.metricWindow].aggregation.impressions.avg) - 1,
                                sign: '',
                                style: 'text-warning',
                            },
                        }

                        _.forEach(game.performance, (value, key) => {
                            if (value.percent !== 0) {
                                value.sign = value.percent > 0 ? '+' : '-'
                                value.style = value.percent > 0 ? 'text-success' : 'text-danger'
                                value.tooltip = `Creator performs ${value.sign}${numeral(Math.abs(value.percent)).format('0.0%')} compared to their average when playing this game.`
                            }
                        })

                        return game
                    })
                }

                if (this.state.platform_id === 'youtube') {
                    const gamesLookup = _.get(this.superview, `video[${this.state.metricWindow}].accumulation.games.lookup`)
                    if (!gamesLookup) return []

                    const gamesAsList = _.map(gamesLookup, (game, key) => {
                        game.key = key
                        return game
                    })

                    const totalVideoCount = _.sumBy(gamesAsList, 'count')

                    return _.orderBy(gamesAsList, ['count'], ['desc']).slice(0, 5).map((game) => {
                        game.metadata = this.games.byName[game.name]
                        game.dominance_percent = (game.count / totalVideoCount)

                        game.avg_views = (game.views / game.count)

                        game.performance = {
                            views: {
                                percent: (game.avg_views / this.superview.video[this.state.metricWindow].aggregation.views.avg) - 1,
                                sign: '',
                                style: 'text-warning',
                            },
                        }

                        _.forEach(game.performance, (value, key) => {
                            if (value.percent !== 0) {
                                value.sign = value.percent > 0 ? '+' : '-'
                                value.style = value.percent > 0 ? 'text-success' : 'text-danger'
                                value.tooltip = `Creator performs ${value.sign}${numeral(Math.abs(value.percent)).format('0.0%')} compared to their average when playing this game.`
                            }
                        })

                        return game
                    })
                }
            },

            sponsorships() {
                if (!this.participants) return []

                const campaignTypesWithGames = ['game-sponsorship', 'steam-broadcast']

                const participantsWithGame = _.map(this.participants, (participant) => {
                    if (campaignTypesWithGames.includes(participant.campaign.type)) {
                        participant.game = this.games.byId[participant.campaign.game.twitch_id]
                    }

                    return participant
                })

                return _.orderBy(participantsWithGame, ['campaign.started_at'], ['desc'])
            },

            keyMetrics() {
                const metrics = _.get(this.superview, 'stream')
                if (!metrics) return null

                return {
                    viewers: {
                        current: metrics.day_30.aggregation.viewers.avg,
                        delta: (metrics.day_30.aggregation.viewers.avg / metrics[this.state.metricWindow].aggregation.viewers.avg) - 1,
                    },
                    impressions: {
                        current: metrics.day_30.aggregation.impressions.avg,
                        delta: (metrics.day_30.aggregation.impressions.avg / metrics[this.state.metricWindow].aggregation.impressions.avg) - 1,
                    },
                    live_seconds: {
                        current: metrics.day_30.aggregation.live_seconds.avg,
                        delta: (metrics.day_30.aggregation.live_seconds.avg / metrics[this.state.metricWindow].aggregation.live_seconds.avg) - 1,
                    },
                }
            },

            socials() {
                const platforms = _.get(this.dictionary, 'platforms.list')

                const links = _.get(this.superview, 'metadata.root.socials')
                if (!links) return {}

                return _.reduce(platforms, (results, platform) => {
                    if (platform.key === 'twitch') {
                        results.push({
                            platform,
                            href: `https://twitch.tv/${this.superviews.twitch.channel.name}`,
                        })
                    } else {
                        if (links[platform.domain_key]) {
                            results.push({
                                platform,
                                href: links[platform.domain_key].href,
                            })
                        }
                    }

                    return results
                }, [])
            }
        },

        methods: {
            getSuperview() {
                const channelName = this.$route.params.channel_name.toLowerCase()

                const unregisteredCreatorRoutes = ['creator-platform-twitch', 'creator-platform-youtube']
                const registeredCreatorRoutes = ['creator', 'creator-registered']

                if (unregisteredCreatorRoutes.includes(this.$route.name)) {
                    this.axios.get(`/superviews/${this.state.platform_id}/${channelName}`).then(({ data }) => {
                        this.superview = data.payload.superview
                        this.superviews = {}
                        this.superviews[this.state.platform_id] = data.payload.superview

                        this.participants = data.payload.participants
                        this.games.byId = _.keyBy(data.payload.games, '_id')
                        this.games.byName = _.keyBy(data.payload.games, 'name')
                        this.avgViewersChart = data.payload.avgViewersChart

                        this.getTwitchClips()

                        this.page.title = this.superview.channel.display_name
                    }).catch((error) => {
                        // Redirect to 404 error page
                    })

                } else if (registeredCreatorRoutes.includes(this.$route.name)) {
                    this.axios.get(`/resumes/${channelName}`).then(({ data }) => {
                        // Change based on which platform is configured to show first
                        this.superview = data.payload.superviews.twitch
                        this.superviews = data.payload.superviews
                        this.participants = data.payload.participants
                        this.games.byId = _.keyBy(data.payload.games, '_id')
                        this.games.byName = _.keyBy(data.payload.games, 'name')
                        this.resume = data.payload.resume
                        this.streams = _.orderBy(data.payload.streams, ['started_at'], ['desc'])
                        this.avgViewersChart = data.payload.avgViewersChart

                        this.getTwitchClips()

                        this.page.title = this.superview.channel.display_name
                    }).catch((error) => {
                        // Redirect to 404 error page
                    })
                }
            },

            getTwitchClips() {
                this.axios.get(`/clips/twitch/${this.superview._id}`).then(({ data }) => {
                    this.clips.is_loaded = true

                    if (data.payload.clips && data.payload.clips.length >= 7) {
                        this.clips.primaries = data.payload.clips.splice(0, 1)
                        this.clips.secondaries = data.payload.clips.splice(0, 2)
                        this.clips.tertiaries = data.payload.clips.splice(0, 4)
                        this.clips.is_valid = true
                    } else {
                        this.clips.is_valid = false
                    }
                }).catch((error) => {

                })
            },

            setMetricWindow(newMetricWindow) {
                this.state.metricWindow = newMetricWindow
            },

            toHumanDuration(durationSeconds) {
                const hours = Math.floor(durationSeconds / (60 * 60))
                const minutes = Math.round((durationSeconds % (60 * 60)) / 60)

                let result = []
                if (hours > 0) result.push(`${hours}h`)
                if (minutes > 0) result.push(`${minutes}m`)
                return result.join(' ')
            },

            onClipClick(clipId) {
                this.modals.player.is_shown = true
                this.modals.player.clip_id = clipId
            },

            openModal(modalId) {
                this.$bvModal.show(modalId)
                window.location.hash = modalId
            },
        }
    }
</script>

<style lang="scss">
.page-creator {
    .fixed-width-profile {
        margin-left: auto;
        margin-right: auto;
        width: 1080px;
        min-height: 100%;
        display: flex;
        position: relative;
        margin-top: 300px;
        padding-top: 84px;
    }

    .banner-wrapper {
        position: absolute;
        top: -300px;
        left: -60px;
        width: 1200px;
        height: 300px;
        background-color: rgba(white, 0.05);
        border-radius: 12.5px;
        background-size: cover;
        z-index: 10;
    }

    .logo-wrapper {
        position: absolute;
        top: calc(-80px + -4px);
        left: 0;
        width: 100%;
        height: calc(160px + 8px);
        z-index: 20;

        .logo-inner {
            width: calc(160px + 8px);
            height: 100%;
            border: 4px solid #12141C;
            border-radius: 50%;
            background-color: rgba(white, 0.05);
            overflow: hidden;

            .logo {
                background-size: cover;
            }
        }
    }

    .conditional-wrapper {
        &.data-missing {
            position: relative;

            &:before {
                content: 'This creator has not filled out thier Streamforge profile';
                padding: 2rem;
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                display: flex;
                text-align: center;
                flex-direction: column;
                justify-content: center;
                color: white;
                z-index: 10;
                font-size: 1.25rem;
                text-shadow: 0px 0px 5px black;
            }

            & > * {
                filter: blur(1.5px);
                opacity: 0.2;
            }
        }
    }

    .section-header {
        height: 50px;
        position: relative;

        .title-wrapper {
            &.editable {
                cursor: pointer;

                &:hover {
                    .edit-control {
                        color: #FCD878;
                    }
                }

                .edit-control {
                    transition: 0.25s all;
                }
            }
        }

        .section-header-title {
            font-weight: 700;
            font-size: 20px;
            line-height: 22px;
            color: #fff;
        }



        &:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            height: 2px;
            width: 100%;
            z-index: 10;
            background-color: rgba(white, 0.05);
        }

        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            height: 2px;
            width: 30px;
            z-index: 10;
            background-color: #FCD878;
        }

        .window-switcher {
            border: 2px solid #2A2B33;
            padding: 3px;
            // max-width: 156px;
            height: 36px;
            border-radius: 6px;

            .window {
                width: 50%;
                height: 100%;
                border-radius: 4px;
                color: #fff;
                transition: 0.25s all;

                &.active {
                    background: #FCD878;
                    color: #000;
                }

                &:not(.active) {
                    cursor: pointer;

                    &:hover {
                        background: rgba(white, 0.05);
                    }
                }

                .window-inner {
                    margin: auto;
                    font-size: 12px;
                    line-height: 14px;
                    font-weight: 700;
                    white-space: nowrap;
                }
            }
        }
    }

    .metadata-wrapper {
        .name-wrapper {
            h1 {
                font-size: 28px;
                font-weight: 700;
                line-height: 32px;
                color: #fff;
            }
        }

        .socials-wrapper {
            font-size: 20px;

            .social {
                cursor: pointer;
                transition: 0.25s all;

                &:hover {
                    transform: translateY(-1px);
                }

                &:not(:first-child) {
                    margin-left: 12px;
                }

                &.twitch {
                    color: #673AB7;
                }

                &.youtube {
                    color: #e33434;
                }

                &.twitter {
                    color: #1DA1F2;
                }

                &.instagram {
                    color: #e95950;
                }

                &.facebook {
                    color: #4267B2;
                }
            }
        }

        .platform-wrapper {
            border: 2px solid #2A2B33;
            padding: 6px;
            max-width: 280px;
            height: 64px;
            border-radius: 6px;

            .platform {
                width: 132px;
                height: 100%;
                border-radius: 4px;
                color: #fff;
                transition: 0.25s all;

                &.disabled {
                    opacity: 0.5;
                }

                &:not(.active):not(.disabled) {
                    cursor: pointer;

                    &:hover {
                        background: rgba(white, 0.05);
                    }
                }

                .platform-inner {
                    margin: auto;
                    font-size: 16px;
                    line-height: 22px;
                    font-weight: 600;

                    &.disabled {

                    }
                }

                &.twitch {
                    &.active {
                        background-color: #673AB7;
                    }
                }

                &.youtube {
                    &.active {
                        background-color: #c4302b;
                    }
                }
            }
        }
    }

    .key-metrics-wrapper, .sponsorships-wrapper {
        .icon-wrapper {
            height: 60px;
            width: 60px;
            background: rgba(white, 0.05);
            border-radius: 50%;
            color: #fff;
        }

        .col-metric {
            &:nth-child(1) .icon-wrapper {
                background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(222.71deg, #FF715B 0%, #5634BD 104.33%);
            }

            &:nth-child(2) .icon-wrapper {
                background: linear-gradient(222.71deg, #4263C6 0%, #6AC897 102.7%);
            }

            &:nth-child(3) .icon-wrapper {
                background: linear-gradient(222.71deg, #EB995F 0%, #EF7B62 104.33%);
            }

            &:nth-child(4) .icon-wrapper {
                background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(222.71deg, #405DC7 0%, #803FE5 102.7%);
            }
        }

        .metric-wrapper {
            small {
                color: rgba(white, 0.50);
            }

            h3 {
                font-weight: 700;
                font-size: 28px;
                line-height: 32px;
                color: #fff;
            }
        }
    }

    .about-wrapper {
        .details-wrapper {
            .detail {
                small {
                    color: rgba(white, 0.50);
                }

                h3 {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 20px;
                    color: #fff;
                }
            }
        }

        .attributes-wrapper {
            h3 {
                font-size: 12px;
                line-height: 14px;
                font-weight: 600;
                color: rgba(white, 0.3);
                margin: 0;
            }

            .attribute {
                h4 {
                    font-size: 16px;
                    line-height: 18px;
                    font-weight: 700;
                    margin: 0;
                    border-left: 2px solid rgba(white, 0.1);
                    padding-left: 10px;
                    width: 40%;
                }
            }

            .circles {
                justify-content: space-between;
                width: 60%;

                .circle {
                    position: relative;
                    margin: auto 0;
                    height: 14px;
                    width: 14px;
                    border: 2px solid rgba(white, 0.1);
                    border-radius: 50%;

                    &.active {
                        border: 2px solid rgba(#FCD878, 0.3);

                        &:before {
                            content: '';
                            position: absolute;
                            top: 2px;
                            left: 2px;
                            width: 6px;
                            height: 6px;
                            background: #FCD878;
                            border-radius: 50%;
                        }
                    }
                }
            }
        }

        .interests-wrapper {
            h3 {
                font-size: 12px;
                line-height: 14px;
                font-weight: 600;
                color: rgba(white, 0.3);
                margin: 0;
            }

            .interests {
                flex-wrap: wrap;

                .interest {
                    border: 2px solid rgba(white, 0.15);
                    border-radius: 36px;
                    font-size: 12px;
                    line-height: 14px;
                    font-weight: 600;
                    color: #fff;
                    margin-bottom: 10px;
                    margin-right: 10px;
                    padding: 8px 12px;
                    transition: 0.25s all;

                    &:hover {
                        transform: translateY(-1px);
                    }
                }
            }
        }
    }

    .timeseries-wrapper {
        .fake-chart {
            height: 350px;
        }
    }

    .games-wrapper {
        .col {
            max-width: 20%;
        }

        .game {
            position: relative;

            .game-meta {
                h3 {
                    font-size: 16px;
                    line-height: 18px;
                    font-weight: 700;
                    margin: 0;
                }

                .game-meta-value {
                    font-size: 12px;
                    line-height: 14px;
                    font-weight: 600;
                    color: rgba(white, 0.3);
                }

                .metrics {
                    border-top: 2px solid rgba(white, 0.1);
                    font-size: 12px;
                    line-height: 14px;
                    font-weight: 600;
                    display: flex;
                    justify-content: space-between;
                }
            }

            .game-thumbnail {
                height: 100%;
                padding-bottom: 133.333%;
                border-radius: 12.5px;
                background-size: cover;
                overflow: hidden;
                background-color: rgba(white, 0.05);
            }

            .dominance-wrapper {
                position: absolute;
                top: 5px;
                right: 5px;
                height: 50px;
                width: 50px;

                .dominance {
                    position: relative;
                    height: 100%;
                    width: 100%;
                    padding: 3px;

                    .backdrop {
                        height: 100%;
                        width: 100%;
                        background: #12141c;
                        backdrop-filter: blur(60px);
                        /* Note: backdrop-filter has minimal browser support */
                        border-radius: 100px;
                        position: absolute;
                        top: 0;
                        left: 0;
                    }

                    .radial-progress-inner {
                        font-size: 10px;
                        line-height: 12px;
                        color: #FCD878;
                        font-weight: 700;
                        text-align: center;
                    }
                }
            }
        }
    }

    .sponsorships-wrapper {
        .row-sponsorships {
            .sponsorships-header-wrapper {
                .sponsorships-header {
                    background: rgba(white, 0.05);
                    border-radius: 6px;
                    font-size: 12px;
                    line-height: 14px;
                    color: rgba(white, 0.5);
                    font-weight: 600;
                }
            }



            .sponsorship-wrapper {

                .sponsorship-hero {
                    border-radius: 8px;
                    background-color: rgba(white, 0.05);
                    background-size: cover;
                    background-position: center;

                    .sphere-wrapper {
                        position: absolute;
                        bottom: -37px;
                        left: 0;
                        width: 100%;
                        display: flex;

                        .sphere {
                            height: 80px;
                            width: 80px;
                            border: 3px solid #1E2028;
                            border-radius: 50%;
                            background-position: center;
                            background-size: cover;
                        }
                    }
                }

                h3 {
                    margin: 0;
                    font-weight: 700;
                    font-size: 20px;
                    color: #fff;
                    line-height: 22px;
                }

                small {
                    font-size: 12px;
                    color: rgba(white, 0.3);
                    line-height: 14px;
                    font-weight: 600;
                }

                p {
                    color: #fff;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 20px;
                }
            }
        }
    }

    .clip-wrapper {
        border-radius: 12.5px;
        overflow: hidden;
        transition: 0.25s all;
        cursor: pointer;

        &:hover {
            background: rgba(white, 0.05);
        }

        .clip {
            p {
                font-size: 12px;
                line-height: 14px;
                font-weight: 600;
                color: #FCD878;
                text-transform: uppercase;
            }

            h3 {
                font-weight: bold;
                line-height: 30px;
                color: #fff;
                font-weight: 700;
            }

            small {
                font-weight: 600;
                font-size: 12px;
                line-height: 15px;
                color: #bec2cc;
            }
        }

        &.clip-primary {
            height: 100%;
            width: 100%;
            position: relative;

            .thumbnail {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                background-size: cover;
                z-index: 10;

                &:before {
                    position: absolute;
                    content: '';
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    background: linear-gradient(180deg, rgba(18, 20, 28, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
                    z-index: 20;
                }
            }

            .clip {
                z-index: 30;

                h3 {
                    font-size: 24px;
                }
            }
        }

        &.clip-secondary {
            height: 150px;

            .thumbnail {
                height: 100%;
                border-radius: 12.5px;
                background-size: cover;
                overflow: hidden
            }

            .clip {
                h3 {
                    font-size: 20px;
                    line-height: 22px;
                }
            }
        }

        &.clip-tertiary {
            .thumbnail {
                height: 150px;
                border-radius: 12.5px;
                background-size: cover;
                overflow: hidden
            }

            .clip {
                h3 {
                    font-size: 16px;
                    line-height: 20px;
                    font-weight: 600;
                }
            }
        }
    }

    .swiper-container {
        .swiper-scrollbar {
            opacity: 1 !important;
            background: rgba(white, 0.05);
            left: 0;
            width: 100%;
        }

        .swiper-scrollbar-drag {
            background: #FCD878;
        }
    }

}
</style>
