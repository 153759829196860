<template>
    <section class="page page-connections">
        <div class="row mb-sm">
            <div class="col-lg-8">
                <h1 class="display-2">
                    <font-awesome-icon
                    :icon="['fas', 'plug']"
                    class="my-auto mr-3 text-primary"/>Connections</h1>
                <p>Here you can link your accounts from the platforms we currently support.</p>
            </div>
        </div>

        <div class="row row-connections mb-5">
            <div class="col-platform col-lg-4 mb-4" v-for="(platform, platform_id) in connections" :class="{ 'linked': platform }">
                <a :href="!platform ? `${axios.defaults.baseURL}/link/${platform_id}` : null">
                    <div class="box d-flex p-4">
                        <font-awesome-icon
                            class="platform-icon mr-3"
                            size="lg"
                            :icon="['fab', platform_id]"
                            :style="{
                                color: colors.socials[platform_id]
                            }"
                        />

                        <span class="platform-label mr-4">{{ platform ? platform.name : platform_id | capitalize }}</span>

                        <div class="actions mr-4 d-flex">
                            <a class="mr-3 my-auto" :href="`${axios.defaults.baseURL}/link/${platform_id}`" @click.prevent="delink(platform_id, platform.id)">
                                <span
                                    v-tooltip="'Unlink Account'"
                                >
                                    <font-awesome-icon
                                        v-if="platform"
                                        class="text-danger"
                                        :icon="['fas', 'trash-can']"

                                    />
                                </span>
                            </a>

                            <!-- <a class="my-auto" :href="`${axios.defaults.baseURL}/link/${platform_id}`">
                                <span
                                    v-tooltip="'Relink Account'"
                                >
                                    <font-awesome-icon
                                        v-if="platform"
                                        class="text-success"
                                        :icon="['fas', 'redo']"
                                    />
                                </span>
                            </a> -->
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </section>

</template>
<script>
import { mapGetters } from 'vuex'
import _ from 'lodash'

export default {
    name: 'connections',

    metaInfo() {
        return {
            title: 'Connections'
        }
    },
    data() {
        return {
            optionsToggled: false,

            platforms: {
                twitch: false,
                youtube: false,
                discord: false,
                twitter: false,
                paypal: false,
            },

            urls: {
                twitch: 'twitch.tv',
                mixer: 'mixer.com',
                twitter: 'twitter.com',
                youtube: 'youtube.com',
                instagram: 'instagram.com',
                discord: 'discord.com',
                paypal: 'paypal.com',
            }
        }
    },

    computed: {
        ...mapGetters([
            'colors',
            'userProfiles'
        ]),

        connections() {
            return _.mapValues(this.platforms, (platform, platform_id) => {
                if (this.userProfiles[platform_id]) {
                    return this.userProfiles[platform_id]
                }

                return platform
            })
        }
    },

    methods: {
        toggleOptions(id) {
            this.optionsToggled = id
        },

        relink(platform_id) {
            this.$store.commit('authentication:redirect', this.$route.path)
            this.$nextTick(() => {
                window.location.replace(`${this.axios.defaults.baseURL}/auth/${platform_id}`)
            })
        },

        delink(platform_id, platform_user_id) {
            this.axios.delete(`/connections/${platform_id}/${platform_user_id}`).then(({ data }) => {
                this.profiles = _.filter(this.profiles, item => item.platform_user_id !== platform_user_id)
                this.$store.commit('profiles:delete', { platform_id })

                this.$notify({
                    group: 'global',
                    type: 'info',
                    title: 'Success - Unlink Account',
                    text: _.has(data, 'message') ? data.message : `Account successfully unlinked.`,
                    duration: 10000,
                    speed: 1000,
                })
            }).catch((error) => {
                this.$notify({
                    group: 'global',
                    type: 'error',
                    title: 'Error - Unlink Account',
                    text: _.has(error, 'response.data.message') ? error.response.data.message : `There was an error deleting this connection.`,
                    duration: 10000,
                    speed: 1000,
                })
            })
        }
    }
}
</script>
<style lang="scss">
    .page-connections {

        .row-connections {
            .col-platform {
                &:not(.linked) {
                    .box {
                        border: 1px solid rgba(white, 0.05);

                        &:hover {
                            cursor: pointer;
                            border: 1px solid rgba(white, 0.15);

                            .platform-label {
                                color: #bec2cc;
                            }
                        }
                    }

                    .platform-icon {
                        filter: grayscale(1);
                    }

                    .platform-label {
                        color: rgba(white, 0.5);
                    }
                }
            }

            .box {
                border: 1px solid rgba(white, 0.1);
                transition: 0.25s all;
                border-radius: 5px;
                position: relative;

                .platform-label {
                    transition: 0.25s all;
                    font-size: 1.25em;
                    line-height: 1.1em;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    color: #eee;
                }

                .greyscale {
                    filter: grayscale(1);
                }

                .actions {
                    position: absolute;
                    right: 0;
                    padding: 2.5px;
                    height: 100%;
                    top: 0;

                    a {
                        opacity: 0.5;
                        transition: 0.25s all;

                        &:hover {
                            opacity: 1;
                        }
                    }

                    svg {
                        font-size: 1.15em;
                    }
                }
            }
        }
    }
</style>
