<template>
    <div class="page-admin">
        <router-view :key="$route.path"/>
    </div>
</template>
<script>
import _ from 'lodash'
import moment from 'moment'
import numeral from 'numeral'
import { mapGetters } from 'vuex'

export default {
    name: 'dashboard',

    metaInfo() {
        return {
            title: 'Admin'
        }
    },

    data() {
        return {
        }
    },

    computed: {
        ...mapGetters([
            'self'
        ])
    },

    created() {
        if (!this.self.user.is_moderator) {
            this.$router.push({ name: 'landing' })
        }
    },

    methods: {
    }
}
</script>
<style lang="scss">
</style>
