<template>
    <div class="page page-campaign-inquiry relative">
        <!-- <div
            class="background w-100 h-100 position-absolute top-0 left-0"
            v-if="values.steamGame"
            :style="{
                'background-image': `url(${values.steamGame.meta.background})`
            }"
        /> -->

        <div class="brand p-4">
            <img src="/img/brand/streamforge-logo-text.png" height="50px">
        </div>

        <div class="container my-md" v-if="state.isLoaded === true">
            <div class="row row-panels">
                <div class="col-lg-3 mb-5 d-flex">
                    <div class="d-flex my-auto">
                        <img class="avatar avatar-lg" :src="values.participant.platform_user_logo">

                        <div class="d-flex ml-3">
                            <div class="my-auto">
                                <h1 class="display-4 mb-0">{{ values.participant.platform_user_name }}</h1>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 mb-5">
                    <div class="panel p-3">
                        <small>Business Email</small>
                        <div v-if="values.superview && values.superview.metadata">{{ values.superview.metadata.email }}</div>
                        <div v-else>---</div>
                    </div>
                </div>

                <div class="col-lg-3 mb-5">
                    <div class="panel p-3">
                        <small>Avg Viewers</small>
                        <div v-if="values.superview && values.superview.stream && values.superview.stream.day_90">{{ values.superview.stream.day_90.aggregation.viewers.avg | numeral('0,0') }}</div>
                        <div v-else>---</div>
                    </div>
                </div>

                <div class="col-lg-3 mb-5">
                    <div class="panel p-3">
                        <small>Followers</small>
                        <div v-if="values.superview && values.superview.channel">{{ values.superview.channel.followers | numeral('0,0a') }}</div>
                        <div v-else>---</div>
                    </div>
                </div>

                <div class="col-lg-8 mb-3">
                    <h1 class="mb-0 h3">
                        {{ values.organizationGame.name }}
                    </h1>
                </div>

                <div class="col-lg-4 d-flex mb-3">
                    <a class="ml-auto d-flex" :href="`https://store.steampowered.com/app/${values.organizationGame.steam_id}`">
                        <button class="btn btn-sm btn-primary px-3">Steam Page</button>
                    </a>
                </div>

                <div class="col-lg-8 mb-5">
                    <showcase-slider-steam :slides="values.slides" />
                </div>

                <div class="col-lg-4 mb-5 d-flex flex-column">
                    <div class="mb-3" v-if="values.organizationGame">
                        <img :src="`https://cdn.akamai.steamstatic.com/steam/apps/${values.organizationGame.steam_id}/header.jpg`" alt="" class="w-100 rounded">
                    </div>

                    <div class="panel p-3 rounded flex-grow-1 d-flex flex-column justify-content-between">
                        <div class="meta">
                            <p class="mb-3">
                                <font-awesome-icon :icon="['fas', 'calendar']" class="mr-2" style="opacity: 0.5;" />
                                <span>Campaign starts {{ values.campaign.started_at | moment('MMM Do, YYYY') }}</span>
                            </p>

                            <p>{{ values.steamGame.meta.short_description }}</p>
                        </div>


                        <div class="row mb-3 text-sm" v-if="values.steamGame">
                            <div class="col-lg-4">
                                Publisher
                            </div>
                            <div class="col-lg-8">
                                <a
                                    target="_BLANK"
                                    :href="`https://store.steampowered.com/search/?publisher=${encodeURIComponent(publisherName)}`"
                                    v-for="publisherName in values.steamGame.meta.publishers"
                                >
                                    {{ publisherName }}
                                </a>
                            </div>

                            <div class="col-lg-4">
                                Developer
                            </div>
                            <div class="col-lg-8">
                                <a
                                    target="_BLANK"
                                    :href="`https://store.steampowered.com/search/?developer=${encodeURIComponent(developerName)}`"
                                    v-for="developerName in values.steamGame.meta.developers"
                                >
                                    {{ developerName }}
                                </a>
                            </div>
                        </div>

                        <div class="tags font-weight-bold">
                            <span class="badge badge-primary" v-for="genre in values.igdbGame.genres">{{ genre.name }}</span>
                            <span class="badge badge-warning" v-for="theme in values.igdbGame.themes">{{ theme.name }}</span>
                            <span class="badge badge-info" v-for="perspective in values.igdbGame.player_perspectives">{{ perspective.name }}</span>
                        </div>
                    </div>
                </div>

                <!-- <div class="col-lg-4">
                    <h2 class="h5 mb-3">How we calculate rates</h2>

                    <div class="panel p-3 rounded">

                    </div>
                </div>

                <div class="col-lg-8">
                    <h2 class="h5 mb-3">Why we contacted you</h2>

                    <div class="panel p-3 rounded">
                        <div class="targeting" v-if="values.targeting">
                            <div class="row">
                                <div class="col-lg-4">
                                    <h3 class="h4 text-center py-4">You Play</h3>

                                    <div class="grid-targeting">
                                        <div
                                            class="grid-targeting-item"
                                            v-for="{ igdbGame } in values.targeting.source"
                                            :style="{
                                                'background-image': `url(${igdbGame.cover.url.replace('t_thumb', 't_cover_big')})`
                                            }"
                                        />
                                    </div>
                                </div>

                                <div class="col-lg-4 d-flex">
                                    <div class="m-auto text-center">
                                        <h1 class="m-0 text-success">78%</h1>
                                        <span>Match</span>
                                    </div>
                                </div>

                                <div class="col-lg-4">
                                    <h3 class="h4 text-center py-4">We Target</h3>

                                    <div class="grid-targeting">
                                        <div
                                            class="grid-targeting-item"
                                            v-for="{ igdbGame } in values.targeting.target"
                                            :style="{
                                                'background-image': `url(${igdbGame.cover.url.replace('t_thumb', 't_cover_big')})`
                                            }"
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div> -->

                <div class="col-lg-7" v-if="values.targeting">
                    <h2 class="h5 mb-3">Why we contacted you</h2>

                    <div class="row row-panels">
                        <div class="col-lg-5">
                            <div class="panel rounded p-3">
                                <p v-if="values.organizationGame && values.targeting.source && values.targeting.source.length">
                                    You have been contacted for this opportunity because you have streamed games that are similar to <span class="font-weight-bold text-primary">{{ values.organizationGame.name }}</span>.
                                </p>
                                <p v-else>
                                    You have been contacted for this opportunity because we think you would be a great fit to promote <span class="font-weight-bold text-primary">{{ values.organizationGame.name }}</span>.
                                </p>
                            </div>
                        </div>

                        <div class="col-lg-7">
                            <div class="row row-games">
                                <div
                                    class="col-lg-12 mb-2"
                                    v-for="{ igdbGame, metrics } in values.targeting.source"
                                >
                                    <div class="d-flex game panel overflow-hidden rounded">
                                        <img class="h-100" :src="igdbGame.cover.url.replace('t_thumb', 't_cover_big')">

                                        <div class="flex-grow-1 px-4 d-flex">
                                            <div class="my-auto">
                                                <h5 class="m-0">{{ igdbGame.name }}</h5>
                                                <p class="m-0">{{ toHumanDuration(metrics.duration_seconds) }} streamed</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-5 col-rates">
                    <h2 class="h5 mb-3">How we calculate rates</h2>

                    <div class="panel p-3 rounded">
                        <div class="panel d-flex rounded">
                            <div class="m-auto p-3 text-center">
                                <div class="h3 m-0">${{ values.participant.superview.trueprice.hourly_rate | numeral('0,0') }}/hour</div>
                                <div class="">TruePrice</div>
                            </div>
                        </div>

                        <div class="d-flex game overflow-hidden mt-3">
                            <img class="h-100 rounded" src="http://images.igdb.com/igdb/image/upload/t_cover_big/co4bzv.jpg">

                            <div class="flex-grow-1 ml-4 d-flex">
                                <div class="my-auto d-flex flex-grow-1">
                                    <div class="flex-grow-1">
                                        <h5 class="m-0">Vampire Survivors</h5>
                                        <p class="m-0">Your Main Game</p>
                                    </div>

                                    <div class="ml-auto text-right d-flex">
                                        <div class="my-auto">
                                            32 ACV
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex game overflow-hidden mt-3">
                            <img class="h-100 rounded" src="http://images.igdb.com/igdb/image/upload/t_cover_big/co1qll.jpg">

                            <div class="flex-grow-1 ml-4 d-flex">
                                <div class="my-auto d-flex flex-grow-1">
                                    <div class="flex-grow-1">
                                        <h5 class="m-0">All other games</h5>
                                        <!-- <p class="m-0">Your Main Game</p> -->
                                    </div>

                                    <div class="ml-auto text-right d-flex">
                                        <div class="my-auto">
                                            16 ACV
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import Vue from 'vue'
import moment from 'moment'
import ShowcaseSliderSteam from '@/components/Sliders/ShowcaseSliderSteam'

export default {
    name: 'campaign',

    metaInfo() {
        return {
            title: 'Campaign Inquiry'
        }
    },

    components: {
        ShowcaseSliderSteam,
    },

    computed: {
    },

    created() {
        this.getInquiry()
    },

    data() {
        return {
            values: {
                supercampaign: null,
                campaign: null,
                participant: null,
                superview: null,

                organizationGame: null,
                steamGame: null,
                igdbGame: null,

                targeting: null,

                slides: null,
            },

            state: {
                isLoaded: false,
            },
        }
    },

    methods: {
        getInquiry() {
            this.axios.get(`/crm/participant/${this.$route.params.participant_id}/inquiry`).then(({ data }) => {
                this.values.supercampaign = data.payload.participant.campaign.supercampaign
                this.values.campaign = data.payload.participant.campaign
                this.values.participant = data.payload.participant
                this.values.superview = data.payload.superview
                this.values.organizationGame = data.payload.organizationGame
                this.values.steamGame = data.payload.steamGame
                this.values.igdbGame = data.payload.igdbGame

                this.values.targeting = data.payload.targeting

                const showcase = _.get(data, 'payload.participant.campaign.payload.showcase')

                if (showcase) {
                    let videos = showcase.videos instanceof Array ? showcase.videos : []
                    let screenshots = showcase.screenshots instanceof Array ? showcase.screenshots : []

                    this.values.slides = [
                        ..._.map(videos, (video) => {
                            return {
                                type: 'video',
                                image_url: `https://img.youtube.com/vi/${video.youtube_id}/0.jpg`,
                                video_id: video.youtube_id,
                            }
                        }),
                        ..._.map(screenshots, (screenshot) => {
                            return {
                                type: 'screenshot',
                                image_url: screenshot.url,
                            }
                        })
                    ]
                }

                this.state.isLoaded = true
            }).catch((error) => {
                console.error(`Error fetching inquiry`, error)
            })
        },

        toHumanDuration(durationSeconds) {
            const hours = Math.floor(durationSeconds / (60 * 60))
            const minutes = Math.round((durationSeconds % (60 * 60)) / 60)

            let result = []
            if (hours > 0) result.push(`${hours}h`)
            if (minutes > 0) result.push(`${minutes}m`)
            return result.join(' ')
        },
    },
}
</script>

<style lang="scss">
.page-campaign-inquiry {
    .text-sm {
        font-size: 95%;
    }

    .background {
        z-index: -1;
        background-position: center top;
        background-repeat: no-repeat;
        background-size: cover;
        opacity: 0.2;
    }

    .row-panels {
        .panel {
            // background: #1f1f2a;
        }
    }

    .grid-targeting {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        width: 100%;
        grid-gap: 10px;

        .grid-targeting-item {
            border-radius: 4px;
            background-color: rgba(white, 0.05);
            background-size: cover;
            background-position: center;
            overflow: hidden;
            width: 100%;
            height: 0;
            position: relative;
            overflow: hidden;
            padding: 75% 0;
        }
    }

    .row-games {
        .game {
            height: 100px;
        }
    }

    .col-rates {
        .game {
            height: 100px;
        }
    }
}
</style>
