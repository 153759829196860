<template>
    <div class="user-top-info">

        <!-- Notifications -->
        <div class="notifications-wrapper mr-sm" v-if="isAuthenticated">
            <base-dropdown
                tag="div"
                class="notification-dropdown"
                menu-classes="dropdown-menu-right mt-2">

                 <template slot="title">
                    <a class="dropdown-toggle notification-icon mt-3"
                        :class="{
                            'no-caret': notificationCount === 0,
                            'new' : notificationCount > 0
                        }"
                        data-toggle="dropdown"
                    >
                        <font-awesome-icon :icon="['fas', 'bell']" />
                        <span class="new-count" v-if="notificationCount > 0">{{ notificationCount }}</span>
                        <span class="no-icon" v-if="notificationCount === 0"></span>
                    </a>
                </template>

                <div class="d-flex dropdown-header mb-1 px-4 py-2">Notifications
                    <span
                        class="mark-all-read ml-auto mr-1"
                        v-tooltip="'Mark all as read'"
                        @click="markAllRead()"
                    >
                        <font-awesome-icon :icon="['fas', 'envelope-open']" />
                    </span>
                </div>

                <div class="dropdown-items-wrapper">
                    <div class="dropdown-items">
                        <li
                            class="d-flex flex-column mb-2 dropdown-item"
                            :class="{
                                'read': !!notification.read_at
                            }"
                            v-for="notification in notifications"
                            @click="showModal(notification)"
                        >
                            <!-- <font-awesome-icon
                                v-if="!notification.read_at"
                                :icon="['fas', 'exclamation']"
                                size="lg"
                                :style="{
                                    'color': `#fcd878 !important`
                                }"
                            /> -->
                            <p class="m-0">{{ notification.summary }}</p>
                            <div class="d-flex">
                                <span>{{ notification.type | capitalize }}</span>
                                <span class="timestamp ml-auto">{{ notification.created_at | moment("from", "now") }}</span>
                            </div>
                        </li>

                        <li v-if="!hasNotifications" class="text-center font-italic py-2">No new notifications</li>
                    </div>
                </div>
            </base-dropdown>
        </div>

        <!-- User Information -->
        <base-dropdown tag="div" class="user-wrapper" menu-classes="dropdown-menu-right mt-2" v-if="isAuthenticated">
            <template slot="title">
                <div
                    class="media align-items-center mb-0"
                >
                    <div class="media-body mr-3 d-none d-sm-flex">
                        <span class="name mb-0 text-sm text-muted font-weight-bold">{{ self.user.name }}</span>
                        <!-- <span class="type">{{ rankName | capitalize }} Rank</span> -->
                    </div>

                    <span class="avatar avatar-md rounded-circle">
                        <img :alt="self.user.name" :src="self.user.avatar_url">

                        <svg class="circle" viewbox="0 0 38 38" width="100%" height="100%" style="opacity: 0.25;"
                            :stroke="`url(#${self.user.role_id})`">
                            <circle cx="50%" cy="50%" r="17" fill="none" />
                        </svg>
                    </span>

                    <font-awesome-icon :icon="['fas', 'chevron-down']" size="lg" class="user-info-icon ml-3" />
                </div>
            </template>

            <!-- <router-link :to="{ name: 'invite', params: { slug: self.user.name } }" class="dropdown-item">
                <div class="icon-wrapper">
                    <font-awesome-icon :icon="['fas', 'external-link-alt']" class="my-auto" />
                </div>
                <div class="dropdown-item-label">
                    Referral Link
                </div>
            </router-link> -->

            <router-link :to="{ name: 'connections' }" class="dropdown-item">
                <div class="icon-wrapper">
                    <font-awesome-icon :icon="['fas', 'plug']" class="my-auto" />
                </div>
                <div class="dropdown-item-label">
                    Accounts
                </div>
            </router-link>

            <router-link :to="{ name: 'settings' }" class="dropdown-item">
                <div class="icon-wrapper">
                    <font-awesome-icon :icon="['fas', 'gear']" class="my-auto" />
                </div>
                <div class="dropdown-item-label">
                    Settings
                </div>
            </router-link>

            <router-link :to="{ name: 'logout' }" class="dropdown-item">
                <div class="icon-wrapper">
                    <font-awesome-icon :icon="['fas', 'right-from-bracket']" class="my-auto" />
                </div>
                <div class="dropdown-item-label">
                    Logout
                </div>
            </router-link>
        </base-dropdown>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import BaseDropdown from '@/components/Base/BaseDropdown'
import Modal from '@/components/Base/Modal'

export default {
    name: 'user-top-info',

    components: {
        BaseDropdown,
        Modal,
    },

    data() {
        return {
            searchActive: false,
        }
    },

    computed: {
        ...mapGetters([
            'isAuthenticated',
            'self',
            'colors',
            'notifications',
            'activeQuests',
            'userRank'
        ]),

        notificationCount() {
            if (!this.notifications) return 0
            return this.notifications.filter(item => !item.read_at).length
        },

        rankName() {
            if (_.has(this.userRank, 'name')) {
                return this.userRank.name
            }

            return 'member'
        },

        points() {
            return this.self.user.points || 0
        },

        hasNotifications() {
            return this.notifications && this.notifications.length
        }
    },

    methods: {
        markRead(id) {
            this.axios.post(`/notification/${id}`, {
                read: true
            }).then((response) => {
                console.log(`[Modal Manager]: Notification read`)

                this.$store.commit('notifications:update', {
                    id: id,
                    properties: {
                        read_at: moment().format()
                    }
                })
            }).catch((error) => {
                console.log(`[Modal Manager]: Failed to read notification: `, error.message)
            })
        },

        markAllRead() {
            this.notifications.forEach((notification) => {
                if (!notification.read_at) {
                    this.markRead(notification.id)
                }
            })
        },

        showModal(notification) {
            this.$store.commit('modal:set', {
                modal: {
                    id: notification.id,
                    type: notification.type,
                    visible: true,
                    title: notification.summary,
                    author: null,
                    content: notification.content,
                    read_timeout: notification.read_timeout,
                    answer: notification.answer
                }
            })
        },

        onExternalClick(target_url, name) {
            window.open(target_url, `_blank`)
        },
    }
}
</script>

<style lang="scss">
.user-top-info {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-left: auto;

    .currency-number {
        white-space: nowrap;
        position: relative;

        .max {
            opacity: 0;
            animation: slide-right 1s forwards;
        }
    }

    .media-body {
        display: flex;
        flex-direction: column;

        .name {
            font-size: 18px;
        }

        .type {
            font-size: 12px;
            text-align: right;
            white-space: nowrap;
        }
    }

    .dropdown {
        -webkit-touch-callout: none; /* iOS Safari */
          -webkit-user-select: none; /* Safari */
           -khtml-user-select: none; /* Konqueror HTML */
             -moz-user-select: none; /* Firefox */
              -ms-user-select: none; /* Internet Explorer/Edge */
                  user-select: none; /* Non-prefixed version, currently
                                        supported by Chrome and Opera */

        .dropdown-toggle {
            display: flex;
            width: 100%;
            transition: color 0.25s;
            color: #f6f6f8;

            &:hover {
                color: #fff;
            }
        }

        .dropdown-menu {
            background: #1e2029;
            animation: slide-up 0.25s forwards;
            box-shadow: none;

            // If we don't have the arrow to dropdown source, use this
            border-radius: 5px;
            overflow: hidden;
            flex-direction: column;

            /* For when it is active */
            &.show {
                display: flex !important;
            }

            .dropdown-menu-inner {
                min-width: 500px;
            }

            .dropdown-items-wrapper {
                max-height: 100%;
                overflow-y: scroll;
                padding: 12px;
            }

            .dropdown-item {
                .dropdown-item-label {
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 24px;
                }

                &:hover {
                    color: #fff;
                    background: hsla(0, 0%, 100%, 0.05);
                    transition: 0.25s all;
                }
            }
        }
    }

    .notifications-wrapper {
        display: flex;
        height: 100%;
        width: 100%;

        .mark-all-read {
            opacity: 0.6;
            cursor: pointer;
            transition: 0.25s all;

            &:hover {
                opacity: 1;
            }
        }

        .notification-dropdown .dropdown-toggle::after {
            border-left: 0px;
            border-right: 0px;
        }

        .notification-dropdown {
            .read {
                opacity: 0.35;
            }

            .notification-icon {
                position: relative;
                margin: auto 0;
                font-size: 1.25em;

                .new-count {
                    position: absolute;
                    top: -10px;
                    right: -10px;
                    height: 15px;
                    width: 15px;
                    border-radius: 50%;
                    background: #fcd878;
                    color: #000;
                    text-align: center;
                    font-size: 8px;
                    line-height: 15px;
                    font-weight: 700;
                    font-family: monospace;
                }
            }

            .dropdown-header {
                font-size: 16px;
                padding: 6px 12px;
                text-transform: none;
            }

            .dropdown-menu {
                max-height: 50vh;
                width: 300px;
                padding-bottom: 0;
                box-shadow: 0 0 10px rgba(18, 20, 29, 0.25);

                &:before {
                    right: 34px;
                }

                .dropdown-item {
                    white-space: normal;
                    cursor: pointer;
                    position: relative;
                    color: rgba(255, 255, 255, 0.8);
                    font-size: 16px;
                    padding: 10px 12px;
                    border-radius: 6px;

                    & > svg {
                        margin-right: 0.5em;
                    }

                    &:active {
                        background: transparent;
                    }

                    span {
                        font-size: 14px;
                        font-style: italic;
                        font-weight: 400;
                        color: hsla(0, 0%, 100%, 0.5);
                    }

                    &:hover {
                        background-color: hsla(0, 0%, 100%, 0.05);
                    }
                }
            }
        }
    }

    .user-wrapper {
        cursor: pointer;

        .user-info-icon {
            display: block !important;
            transition: 0.25s all;
        }

        &.show {
            .user-info-icon {
                color: #fcd878;
                transform: rotate(180deg);
            }
        }

        .avatar {
            position: relative;
            background-color: #08080c;

            .circle {
                position: absolute;
                bottom: 0px;
                left: 0px;
                width: 38px;
                height: 38px;
                fill: none;
                stroke-width: 10;
                // stroke-linecap: round;
                pointer-events: none;
                margin: auto;
                overflow: visible;
                z-index: -1;
            }

            .rank {
                position: absolute;
                bottom: -12px;
                // right: -20px;
                right: 0px;
                width: 100%;

                img {
                    width: 22px;
                    height: 22px;
                    margin: 0 auto;
                    display: block;
                    opacity: 0;
                    transition: opacity 1s ease;

                    &[lazy="loaded"] {
                        opacity: 1;
                    }
                }
            }
        }

        .dropdown-menu {
            padding: 12px;

            .dropdown-item {
                position: relative;
                color: hsla(0, 0%, 100%, 0.5);
                display: flex;

                padding: 6px 12px;
                border-radius: 6px;
                transition: color 200ms ease, background-color 200ms ease;

                .icon-wrapper {
                    text-align: center;
                    margin-right: 0.75em;
                    transition: 1s all;
                    display: flex;
                    min-width: 16px;

                    svg {
                        font-size: 1.15em;
                        margin: auto;
                    }
                }

                & > svg {
                    margin: 0;
                }

                &:hover {
                    color: #fff;

                    .icon-wrapper {
                        color: #fcd878;
                    }
                }

                &:active, &.active {
                    background: inherit !important;

                    .icon-wrapper {
                        color: #fcd878 !important;
                    }
                }
            }
        }
    }
}
</style>
