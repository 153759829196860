<template>
    <modal :show.sync="modal.visible" :type="`notice`">
        <h6 slot="header" class="modal-title">{{ modal.title }}</h6>

        <span slot="body">
            <span v-html="modal.content" @click="handleClicks"></span>
        </span>

        <template v-if="!modal.answerDisabled && modal.answer" slot="footer">
            <base-button type="success">
                <font-awesome-icon
                    v-if="modal.answer == 'up'"
                    :icon="['fas', 'thumbs-up']"
                />

                <font-awesome-icon
                    v-if="modal.answer == 'down'"
                    :icon="['fas', 'thumbs-down']"
                />
            </base-button>
        </template>

        <template v-if="!modal.answerDisabled && !modal.answer" slot="footer">
            <base-button type="primary" v-on:click="acknowledge('up')">
                <font-awesome-icon
                    :icon="['fas', 'thumbs-up']"
                />
            </base-button>

            <base-button type="primary" v-on:click="acknowledge('down')">
                <font-awesome-icon
                    :icon="['fas', 'thumbs-down']"
                />
            </base-button>
        </template>

    </modal>
</template>
<script>
import { mapGetters } from 'vuex'
import Modal from '@/components/Base/Modal'
import moment from 'moment'

export default {
    name: 'modal-manager',

    components: {
        Modal
    },

    computed: {
        ...mapGetters([
            'modal'
        ])
    },

    watch: {
        modal(value, oldValue) {
            if (value.visible) {
                setTimeout(this.read, 1000 * 2.5)
            }
        }
    },

    methods: {
        read() {
            if (this.modal.read_at) return
            if (this.modal.sendReadDisabled) return

            this.axios.post(`/notification/${this.modal.id}`, {
                read: true
            }).then((response) => {
                console.log(`[Modal Manager]: Notification read`)

                this.$store.commit('notifications:update', {
                    id: this.modal.id,
                    properties: {
                        read_at: moment().format()
                    }
                })
            }).catch((error) => {
                console.log(`[Modal Manager]: Failed to read notification: `, error.message)
            })
        },

        acknowledge(answer) {
            this.axios.post(`/notification/${this.modal.id}`, {
                acknowledge: true,
                answer
            }).then((response) => {
                // this.modal.visible = false
                this.$store.commit('notifications:update', {
                    id: this.modal.id,
                    properties: {
                        answer: answer
                    }
                })

                this.$nextTick(() => {
                    this.$store.commit('modal:clear')
                })
            }).catch((error) => {
                console.log(`[Modal Manager]: Failed to acknowledge notification: `, error.message)
            })
        },

        handleClicks($event) {
            // ensure we use the link, in case the click has been received by a subelement
            let { target } = $event

            while (target && target.tagName !== 'A') target = target.parentNode

            // handle only links that occur inside the component and do not reference external resources
            if (target && target.matches(".modal-body a:not([href*='://'])") && target.href) {

                // some sanity checks taken from vue-router:
                // https://github.com/vuejs/vue-router/blob/dev/src/components/link.js#L106
                const {
                    altKey,
                    ctrlKey,
                    metaKey,
                    shiftKey,
                    button,
                    defaultPrevented
                } = $event

                // don't handle with control keys
                if (metaKey || altKey || ctrlKey || shiftKey) return

                // don't handle when preventDefault called
                if (defaultPrevented) return

                // don't handle right clicks
                if (button !== undefined && button !== 0) return

                // don't handle if `target="_blank"`
                if (target && target.getAttribute) {
                    const linkTarget = target.getAttribute('target')
                    if (/\b_blank\b/i.test(linkTarget)) return
                }

                // don't handle same page links/anchors
                const url = new URL(target.href)
                const to = url.pathname

                if (window.location.pathname !== to && $event.preventDefault) {
                    $event.preventDefault()
                    this.$router.push(to)

                    this.$nextTick(() => {
                        this.$store.commit('modal:clear')
                    })
                }
            }
        }
    }
}
</script>
<style lang="scss">
.modal-notice {
    max-width: 40% !important;

    .modal-content {
        box-shadow:  0 15px 35px rgba(0, 0, 0, 0.2), 0 5px 15px rgba(0, 0, 0, 0.17)
    }
}
</style>
