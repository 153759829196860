<template>
    <div>
        <div class="row mb-2">
            <div class="col-lg-9">
                <h3 class="display-4">Card Analytics</h3>
                <p>Past 30 days visits and clicks.</p>
            </div>
        </div>
        <div class="row mb-4">
            <div class="col-lg-12">
                <div class="total-stats py-3 px-3 d-flex justify-content-center">
                    <div class="mx-4 d-flex flex-column align-items-center">
                        <div>Visits</div>
                        <h4 class="display-4">{{ data.totals.views }}</h4>
                    </div>
                    <div class="mx-4 d-flex flex-column align-items-center">
                        <div>Clicks</div>
                        <h4 class="display-4">{{ data.totals.clicks }}</h4>
                    </div>
                    <div class="mx-4 d-flex flex-column align-items-center">
                        <div>Click Rate</div>
                        <h4 class="display-4">
                            {{ data.totals.views !== 0 ? data.totals.clicks / data.totals.views : 0 | numeral('0%') }}
                        </h4>
                    </div>
                </div>
            </div>
        </div>
        <div ref="chartdiv" class="chart"></div>
    </div>
</template>

<script>
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'
import am4themes_dark from '@amcharts/amcharts4/themes/dark'
import moment from 'moment'

am4core.useTheme(am4themes_dark)
am4core.useTheme(am4themes_animated)

export default {
    name: "page-analytics",

    props: [
        'data',
        'show'
    ],


    mounted() {
        this.render()
    },

    data() {
        return {
            chart: false
        }
    },

    methods: {
        render() {
            const cornerRadius = (radius, item) => {
                let dataItem = item.dataItem

                // Find the last series in this stack
                let lastSeries
                this.chart.series.each(function(series) {
                    if (dataItem.dataContext[series.dataFields.valueY] && !series.isHidden && !series.isHiding) {
                        lastSeries = series
                    }
                })

                // If current series is the one, use rounded corner
                return dataItem.component == lastSeries ? 5 : radius
            }

            this.chart = am4core.create(this.$refs.chartdiv, am4charts.XYChart)
            this.chart.logo.disabled = true
            this.chart.logo.height = -15000
            this.chart.padding = [0,0,0,0]
            this.chart.marginLeft = 0
            this.colorSet = new am4core.ColorSet()

            let plotData = []

            for (let [key, value] of Object.entries(this.data.day)) {
                plotData.push({
                    date: key,
                    views: value.views,
                    clicks: value.clicks
                })
            }

            this.chart.data = plotData

            this.chart.dateFormatter.inputDateFormat = "yyyy-MM-dd"

            let dateAxis = this.chart.xAxes.push(new am4charts.DateAxis())
            dateAxis.tooltip.label.fill = am4core.color("#ffffff")
            dateAxis.tooltip.background.fill = am4core.color('#000')
            dateAxis.tooltip.background.cornerRadiusTopLeft = 5
            dateAxis.tooltip.background.cornerRadiusTopRight = 5
            dateAxis.tooltip.background.strokeOpacity = 0
            dateAxis.tooltip.label.minWidth = 40
            dateAxis.tooltip.label.minHeight = 40
            dateAxis.tooltip.label.textAlign = "middle"
            dateAxis.tooltip.label.textValign = "middle"

            let valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis())
            valueAxis.tooltip.disabled = true
            // valueAxis.title.text = "Views / Clicks"
            // valueAxis.title.disabled = true
            valueAxis.min = 0

            let clicksSeries = this.chart.series.push(new am4charts.ColumnSeries())
            clicksSeries.tooltipText="{clicks} click(s)"
            clicksSeries.name = "Clicks"
            clicksSeries.stacked = true

            clicksSeries.tooltip.getFillFromObject = false
            clicksSeries.tooltip.background.fill = am4core.color('#000')
            clicksSeries.tooltip.background.fillOpacity = 0.85
            clicksSeries.tooltip.background.cornerRadiusTopLeft = 5
            clicksSeries.tooltip.background.cornerRadiusTopRight = 5
            clicksSeries.tooltip.background.strokeOpacity = 0
            clicksSeries.tooltip.label.minWidth = 40
            clicksSeries.tooltip.label.minHeight = 40
            clicksSeries.tooltip.label.textAlign = "middle"
            clicksSeries.tooltip.label.textValign = "middle"

            // clicksSeries.columns.template.fill = am4core.color('#ffa672')
            clicksSeries.columns.template.strokeWidth = 0

            clicksSeries.columns.template.column.adapter.add("cornerRadiusTopLeft", cornerRadius)
            clicksSeries.columns.template.column.adapter.add("cornerRadiusTopRight", cornerRadius)

            let viewsSeries = this.chart.series.push(new am4charts.ColumnSeries())
            viewsSeries.tooltipText="{views} visit(s)"
            viewsSeries.name = "Visits"
            viewsSeries.stacked = true

            viewsSeries.tooltip.getFillFromObject = false
            viewsSeries.tooltip.background.fill = am4core.color('#000')
            viewsSeries.tooltip.background.fillOpacity = 0.85
            viewsSeries.tooltip.background.cornerRadiusTopLeft = 5
            viewsSeries.tooltip.background.cornerRadiusTopRight = 5
            viewsSeries.tooltip.background.strokeOpacity = 0
            viewsSeries.tooltip.label.minWidth = 40
            viewsSeries.tooltip.label.minHeight = 40
            viewsSeries.tooltip.label.textAlign = "middle"
            viewsSeries.tooltip.label.textValign = "middle"

            // viewsSeries.columns.template.fill = am4core.color('#fcd878')
            viewsSeries.columns.template.strokeWidth = 0

            viewsSeries.columns.template.column.adapter.add("cornerRadiusTopLeft", cornerRadius)
            viewsSeries.columns.template.column.adapter.add("cornerRadiusTopRight", cornerRadius)


            this.chart.cursor = new am4charts.XYCursor();
            this.chart.cursor.behavior = "panXY";
            this.chart.cursor.xAxis = dateAxis;
            this.chart.cursor.snapToSeries = viewsSeries;


            viewsSeries.dataFields.dateX = 'date'
            viewsSeries.dataFields.valueY = 'views'

            clicksSeries.dataFields.dateX = 'date'
            clicksSeries.dataFields.valueY = 'clicks'

            this.chart.legend = new am4charts.Legend();
        },
    },

    beforeDestroy() {
        if (this.chart) {
            this.chart.dispose()
        }
    }



}
</script>

<style lang="scss">
.chart {
    height: 300px !important;
}

.total-stats {
    background-color: #1e2029;
    border-radius: 12px;
}
</style>
