import VueMultianalytics from 'vue-multianalytics'
import analyticsMixin from '../mixins/analytics'

export default (router) => {
    return {
        install(Vue) {
            Vue.use(VueMultianalytics, {
                modules: {
                    ga: {
                        appName: 'Streamforge',
                        appVersion: '1.0.0',
                        trackingId: process.env.VUE_APP_ANALYTICS_GOOGLE_KEY,
                        globalDimensions: [],
                        globalMetrics: [],
                        debug: process.env.NODE_ENV === 'production' ? false : true
                    },
                    // mixpanel: {
                    //     token: process.env.VUE_APP_ANALYTICS_MIXPANEL_KEY,
                    //     config: {},
                    //     debug: process.env.NODE_ENV === 'production' ? false : true
                    // }
                },
                routing: {
                    vueRouter: router, //  Pass the router instance to automatically sync with router (optional)
                    preferredProperty: 'name', // By default 'path' and related with vueRouter (optional)
                    ignoredViews: [], // Views that will not be tracked
                    ignoredModules: [] // Modules that will not send route change events. The event sent will be this.$ma.trackView({viewName: 'homepage'}, ['ga'])
                }
            }, analyticsMixin)
        }
    }
}
