import { render, staticRenderFns } from "./MetricWindowSwitcher.vue?vue&type=template&id=1b1658c3&scoped=true&"
import script from "./MetricWindowSwitcher.vue?vue&type=script&lang=js&"
export * from "./MetricWindowSwitcher.vue?vue&type=script&lang=js&"
import style0 from "./MetricWindowSwitcher.vue?vue&type=style&index=0&id=1b1658c3&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b1658c3",
  null
  
)

export default component.exports