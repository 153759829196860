<template>
    <div class="key-modal-wrapper">
        <b-modal id="key-modal" @ok="onSubmit" @hide="onHide">
            <template #modal-title>
                <h1 class="h4 m-0">Key Retrieval</h1>
            </template>

            <template #modal-footer="{ ok }">
                <b-button
                    v-if="type === 'create'"
                    variant="primary"
                    @click="ok()"
                    :disabled="!values.key_type"
                >
                    Retrieve Key
                </b-button>

                <b-button
                    v-if="type === 'view'"
                    variant="primary"
                    @click="ok()"
                >
                    Ok
                </b-button>
            </template>

            <form @submit.stop.prevent="onSubmit">
                <div class="row" v-if="type === 'create'">
                    <div class="col-lg-12 mb-3">
                        <p>Please select the type of key you would like to retrieve.</p>
                    </div>

                    <div class="col-lg-12">
                        <div class="row row-panels">
                            <div class="col-lg-12 panel outline hoverable clickable py-2" v-for="(value, key) in options.key_type" :class="{ 'active': value === values.key_type }" @click="onKeyTypeClick(value)">
                                <div class="row">
                                    <div class="col-lg-2 d-flex">
                                        <font-awesome-icon
                                            v-if="value === values.key_type"
                                            :icon="['fas', 'check']"
                                            class="m-auto text-success"
                                            size="lg"
                                        />
                                        <h4 v-else class="h5 m-auto">#{{ key + 1 }}</h4>
                                    </div>
                                    <div class="col-lg-2">
                                        <small>{{ transform.key_type.console_id.display_name }}</small>
                                        <p class="m-0">{{ value.console_id | uppercase }}</p>
                                    </div>
                                    <div class="col-lg-3">
                                        <small>{{ transform.key_type.marketplace_id.display_name }}</small>
                                        <p class="m-0">{{ value.marketplace_id | capitalize }}</p>
                                    </div>
                                    <div class="col-lg-3">
                                        <small>{{ transform.key_type.region_id.display_name }}</small>
                                        <p class="m-0">{{ value.region_id | capitalize }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row" v-if="type === 'view'">
                    <div class="col-lg-12">
                        <p>Below you can see the key you have retrieved for this campaign.</p>
                    </div>

                    <div class="col-lg-12">
                        <!-- Copy on click was not working -->
                        <b-form-input
                            :value="keycode.code"
                        />
                    </div>
                </div>
            </form>
        </b-modal>
    </div>
</template>

<script>
import _ from 'lodash'

export default {
    name: 'key-modal',

    props: {
        campaign_id: {
            type: String,
        },
        type: {
            type: String,
            default: () => 'create',
        },
        keycode: {
            type: Object,
        },
        task_id: {
            type: String,
        },
    },

    data() {
        return {
            transform: {
                key_type: {
                    console_id: {
                        display_name: 'Console',
                    },
                    marketplace_id: {
                        display_name: 'Marketplace',
                    },
                    region_id: {
                        display_name: 'Region',
                    },
                },
            },

            options: {
                key_type: []
            },

            defaults: {
                key_type: false,
            },

            values: false,
        }
    },

    created() {
        this.resetState()
        this.getKeyTypes()
    },

    methods: {
        getKeyTypes() {
            this.axios.get(`/crm/campaigns/${this.campaign_id}/keys/types`).then(({ data }) => {
                this.options.key_type = data.payload.keyTypes
            })
        },

        getKey() {
            return this.axios.post(`/crm/campaigns/${this.campaign_id}/keyrequest`, {
                task_id: this.task_id,
                console_id: this.values.key_type.console_id,
                marketplace_id: this.values.key_type.marketplace_id,
                region_id: this.values.key_type.region_id,
            })
        },

        resetState() {
            this.values = _.cloneDeep(this.defaults)
        },

        onKeyupEnter() {
            this.onSubmit()
            this.onHide()
        },

        onSubmit() {
            if (this.type === 'create') {
                this.getKey().then(({ data }) => {
                    this.$emit('key-retrieved', {
                        key: data.payload.key,
                        task: data.payload.task,
                    })

                    this.resetState()
                })
            }
        },

        onHide(event) {
            this.$emit('close', event)
            this.resetState()
        },

        onKeyTypeClick(keyType) {
            this.values.key_type = keyType
        },

        onCopyBoxClick(value) {
            const el = document.createElement('textarea')
            el.value = value
            el.setAttribute('readonly', '')
            el.style.position = 'absolute'
            el.style.left = '-9999px'
            document.body.appendChild(el)
            const selected =  document.getSelection().rangeCount > 0  ? document.getSelection().getRangeAt(0) : false
            el.select()
            document.execCommand('copy')
            document.body.removeChild(el)

            if (selected) {
                document.getSelection().removeAllRanges()
                document.getSelection().addRange(selected)
            }

            this.$notify({
                group: "global",
                type: "info",
                text: `Copied key code to clipboard!`,
                duration: 2500,
                speed: 1000
            })
        }
    }
}
</script>

<style lang="scss">
.key-modal-wrapper {
}
</style>
