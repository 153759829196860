<template>
<div class="overview-wrapper">
    <home-banner />

    <div class="row row-panels row-metrics" >
        <div class="col-lg-8 mb-3">
            <h2 class="display-4 m-0 d-inline-block position-relative">Analytics</h2>
            <div class="d-flex">
                <router-link class="my-auto" :to="{ name: 'analytics' }">View all</router-link>
            </div>
        </div>

        <div class="col-lg-4 mb-3 d-flex" v-if="metrics">
            <metric-window-switcher
                :metric-windows="metricWindows"
                class="ml-auto my-auto"
                @switched="onMetricWindowSwitch"
            />
        </div>

        <div class="col-lg-6" v-if="!metrics">
            <div class="panel p-3">
                <p class="m-0">
                    It seems we don't have any data for your Twitch account. Our data is updated after every stream,
                    please check in after your next broadcast.
                </p>
            </div>
        </div>

        <div class="col-lg-3" v-if="metrics">
            <div class="panel p-3 d-flex">
                <div class="icon-wrapper d-flex my-auto">
                    <font-awesome-icon class="m-auto" :icon="['fas', 'eye']" size="lg" />
                </div>
                <div class="metric-wrapper ml-3 my-auto">
                    <small>Avg. Viewers</small>
                    <h3 class="m-0 metric-value">{{ metrics.aggregation.viewers.avg | numeral('0,0') }}</h3>
                </div>
            </div>
        </div>

        <div class="col-lg-3" v-if="metrics">
            <div class="panel p-3 d-flex">
                <div class="icon-wrapper d-flex my-auto">
                    <font-awesome-icon class="m-auto" :icon="['fas', 'mountain']" size="lg" />
                </div>
                <div class="metric-wrapper ml-3 my-auto">
                    <small>Peak Viewers</small>
                    <h3 class="m-0 metric-value">{{ metrics.aggregation.viewers.peak.value | numeral('0,0') }}</h3>
                </div>
            </div>
        </div>

        <div class="col-lg-3" v-if="metrics">
            <div class="panel p-3 d-flex">
                <div class="icon-wrapper d-flex my-auto">
                    <font-awesome-icon class="m-auto" :icon="['fas', 'street-view']" size="lg" />
                </div>
                <div class="metric-wrapper ml-3 my-auto">
                    <small>Minutes Watched</small>
                    <h3 class="m-0 metric-value">{{ metrics.aggregation.minutes_watched.sum | numeral('0,0') }}</h3>
                </div>
            </div>
        </div>

        <div class="col-lg-3" v-if="metrics">
            <div class="panel p-3 d-flex">
                <div class="icon-wrapper d-flex my-auto">
                    <font-awesome-icon class="m-auto" :icon="['fas', 'users']" size="lg" />
                </div>
                <div class="metric-wrapper ml-3 my-auto">
                    <small>Total Followers</small>
                    <h3 class="m-0 metric-value">{{ self.superviews.twitch ? self.superviews.twitch.channel.followers : 0 | numeral('0,0') }}</h3>
                </div>
            </div>
        </div>
    </div>

    <home-wizard
        v-if="state.resume.is_loaded && state.card.is_loaded"
        :resume="values.resume"
        :card="values.card"
    />

    <div class="row row-panels row-campaigns mt-md" v-if="state.campaigns.is_loaded && values.sponsorships && values.sponsorships.length > 0">
        <div class="col-lg-12 mb-3">
            <h2 class="display-4 m-0 d-inline-block position-relative">Sponsorships</h2>
            <div class="d-flex">
                <router-link class="my-auto" :to="{ name: 'sponsorships' }">View all</router-link>
            </div>
        </div>

        <div class="col-lg-4 mb-3" v-for="campaign in values.sponsorships">
            <campaign-card :campaign="campaign" />
        </div>
    </div>

    <div class="row row-panels row-campaigns mt-md" v-if="state.campaigns.is_loaded && values.gamekeys && values.gamekeys.length > 0">
        <div class="col-lg-12 mb-3">
            <h2 class="display-4 m-0 d-inline-block position-relative">Game Keys</h2>
            <div class="d-flex">
                <router-link class="my-auto" :to="{ name: 'game-keys' }">View all</router-link>
            </div>
        </div>

        <div class="col-lg-4 mb-3" v-for="campaign in unlockedGameKeys">
            <campaign-card :campaign="campaign" />
        </div>

        <div class="col-lg-6" v-if="!unlockedGameKeys || unlockedGameKeys.length === 0">
            <div class="panel p-3">
                <p class="m-0">
                    It looks like you don’t have access to game keys yet.
                    Game keys are available to active creators with 1,000+ followers (Twitch).
                    Keep growing your channel to be eligible for more opportunities!
                </p>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import numeral from 'numeral'
import {
    mapGetters
} from 'vuex'

import SkeletonLoader from '@/components/SkeletonLoader/Loader'

import HomeBanner from '@/components/Swiper/HomeBanner'
import HomeWizard from '@/components/Wizards/HomeWizardSimple'
import CampaignCard from '@/components/Cards/CampaignCard'
import MetricWindowSwitcher from '@/components/Switchers/MetricWindowSwitcher'

export default {
    name: 'dashboard-overview',

    components: {
        SkeletonLoader,
        HomeBanner,
        HomeWizard,
        CampaignCard,
        MetricWindowSwitcher,
    },

    data() {
        return {
            state: {
                resume: {
                    is_activated: null,
                    is_loaded: false,
                },
                card: {
                    is_activated: null,
                    is_loaded: false,
                },
                campaigns: {
                    is_loaded: false,
                },
                metricWindow: {
                    activeKey: 'day_90',
                },
            },

            values: {
                resume: null,
                card: null,
                sponsorships: null,
                gamekeys: null,
            },
        }
    },

    computed: {
        ...mapGetters([
            'self',
            'colors',
            'getFromDictionary',
        ]),

        metrics() {
            const statistics = _.get(this, `self.superviews.twitch.stream.${this.state.metricWindow.activeKey}`)
            if (!statistics) return null
            return statistics
        },

        metricWindows() {
            const metricWindows = _.get(this, 'self.superviews.twitch.stream')
            if (!metricWindows) return null

            return _.map(metricWindows, (streamWindowMetrics, key) => {
                return {
                    value: key,
                    label: `${key.replace('day_', '')} days`,
                }
            })
        },

        unlockedGameKeys() {
            const followerCount = _.get(this.self, 'superviews.twitch.channel.followers')

            if (!followerCount || followerCount < 1000) {
                return []
            }

            return _.map(this.values.gamekeys, (campaign) => {
                const hasScreenshots = campaign.payload
                    && campaign.payload.showcase
                    && campaign.payload.showcase.screenshots
                    && campaign.payload.showcase.screenshots.length

                if (hasScreenshots) {
                    campaign.background = campaign.payload.showcase.screenshots[0]
                }

                return campaign
            }).slice(0, 3)
        },
    },

    created() {
        this.getResume()
        this.getCard()
        this.getCampaigns()
    },

    methods: {
        getCampaigns() {
            this.axios.get(`/crm/campaigns`).then(({ data }) => {
                this.values.sponsorships = _.filter(data.payload.campaigns, item => item.type !== 'key-distribution')
                this.values.gamekeys = _.filter(data.payload.campaigns, item => item.type === 'key-distribution')
                this.state.campaigns.is_loaded = true
            }).catch((error) => {
                console.log(error.message)
            })
        },

        getResume() {
            this.axios.get(`/resumes`).then(({ data }) => {
                if (data.payload.resume) {
                    this.values.resume = data.payload.resume
                    this.values.superviews = data.payload.superviews
                }

                if (data.payload.resume) {
                    this.state.resume.is_activated = true
                } else {
                    this.state.resume.is_activated = false
                }

                this.state.resume.is_loaded = true
            }).catch((error) => {
                console.error(`Error retrieving resume`, error)
            })
        },

        getCard() {
            this.axios.get('/card').then(({ data }) => {
                if (data.active) {
                    this.values.card = data
                    this.state.card.is_activated = true
                } else {
                    this.state.card.is_activated = false
                }

                this.state.card.is_loaded = true
            }).catch((error) => {
                console.error(`Error retrieving stream card`, error)
            })
        },

        getCampaignBackground(campaign) {
            return _.get(campaign, 'payload.showcase.screenshots[0].url')
        },

        onMetricWindowSwitch(newValue) {
            this.state.metricWindow.activeKey = newValue
        },
    }
}
</script>

<style lang="scss">
.overview-wrapper {
    .row-metrics {
        .icon-wrapper {
            height: 60px;
            width: 60px;
            background: hsla(0,0%,100%,.05);
            border-radius: 50%;
            color: #fff;
        }

        .metric-value {
            font-weight: 700;
            font-size: 28px;
            line-height: 32px;
            color: #fff;
        }
    }

    .row-campaigns {
        .panel {
            position: relative;
            overflow: hidden;

            .panel-background {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                background-position: center;
                background-size: cover;
                z-index: -1;
            }

            .panel-foreground {
                background: linear-gradient(to top, rgba(30, 32, 41, 1) 0%, rgba(30, 32, 41, 0.95) 50%, rgba(30, 32, 41, 0.8) 100%);

                p {
                    font-size: 1.05em;
                    color: #ddd;
                    max-height: 100px;
                    overflow: hidden;
                    -o-text-overflow: ellipsis;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                }
            }

            .locked-wrapper {
                background: rgba(black, 0.9);
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
            }
        }
    }
}
</style>
