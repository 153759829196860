<template>
    <div class="page page-resume">
        <div class="row" v-if="hasResume === false">
            <div class="col-lg-8">
                <h4 class="display-4">Welcome to your Connect page</h4>
                <p>Your first step is to claim your page link. This is a unique link only you can own. You will be able to share this link with others to showcase your channel stats and information that will dynamically update after every stream.</p>

                <p>After claiming your link you can complete your profile to help you get sponsorships that fit your personality and brand.</p>

                <p>You are about to claim <span class="text-primary font-weight-bold">streamforge.com/{{ self.user.name }}</span>.</p>

                <button type="button" class="btn btn-success mt-3" @click="onResumeCreate">
                    Claim
                </button>
            </div>
        </div>

        <div class="row row-panels" v-if="hasResume === true">
            <div class="col-lg-12">
                <h1 class="display-2">
                    <font-awesome-icon
                        :icon="['fas', 'address-card']"
                        class="my-auto mr-3 text-primary"
                    />Connect page
                </h1>
                <p class="w-75">
                    Your Connect page is a dynamic media kit that helps you get more sponsorship opportunities. Share your page with sponsors or let them find you organically - Your page will allow sponsors to view channel stats and information that matter to them.
                </p>

                <p>Your Connect page: <a class="text-primary font-weight-bold" :href="`https://streamforge.com/${self.user.name}`">streamforge.com/{{ self.user.name }}</a>.</p>

                <resume-settings-form
                    :resume="resume"
                    @updated="onResumeUpdated"
                    :updateErrors="errors.resumeUpdate"
                />
            </div>
        </div>
    </div>
</template>

<script type="text/javascript">
    import _ from 'lodash'
    import moment from 'moment'
    import { mapGetters } from 'vuex'

    import ResumeSettingsForm from '@/components/Forms/ResumeSettings'

    export default {
        name: 'resume',

        metaInfo() {
            return {
                title: 'Resume',
            }
        },

        components: {
            ResumeSettingsForm,
        },

        computed: {
            ...mapGetters([
                'self'
            ])
        },

        data() {
            return {
                hasResume: null,
                resume: null,
                superviews: null,

                errors: {
                    resumeUpdate: null,
                },
            }
        },

        created() {
            this.getResume()
        },

        methods: {
            getResume() {
                this.axios.get(`/resumes`).then(({ data }) => {
                    this.resume = data.payload.resume
                    this.superviews = data.payload.superviews

                    if (this.resume) {
                        this.hasResume = true
                    } else {
                        this.hasResume = false
                    }
                }).catch((error) => {
                    this.hasResume = false

                    const message = `There was an error getting your Connect page.`
                    const errorMessage = _.get(error, 'response.data.message')

                    this.$notify({
                        group: 'global',
                        type: 'error',
                        title: 'Error - Get Connect Page',
                        text: errorMessage ? errorMessage : message,
                        duration: 10000,
                        speed: 1000,
                    })
                })
            },

            onResumeUpdated(values) {

                // Clear previous errors
                this.errors.resumeUpdate = null

                // values.birthday = moment(values.birthday, 'YYYY-MM-DD').toDate()
                // console.log(_.cloneDeep(values))

                const requestBody = {
                    resume: _.assign({
                        _id: this.resume._id,
                    }, values)
                }

                this.axios.put(`/resumes`, requestBody).then(({ data }) => {
                    this.resume = data.payload.resume

                    this.$notify({
                        group: "global",
                        type: "success",
                        text: `Successfully updated your Connect Page!`,
                        duration: 2500,
                        speed: 1000,
                    })
                }).catch((error) => {
                    const message = `There was an error updating your Connect Page.`
                    const errorMessage = _.get(error, 'response.data.message')

                    this.$notify({
                        group: 'global',
                        type: 'error',
                        title: 'Error - Update Resume',
                        text: errorMessage ? errorMessage : message,
                        duration: 10000,
                        speed: 1000,
                    })

                    this.errors.resumeUpdate = _.get(error, 'response.data.errors')
                })
            },

            onResumeCreate() {
                this.axios.post(`/resumes`).then(({ data }) => {
                    this.getResume()
                }).catch((error) => {
                    const message = `There was an error claiming your Connect page.`
                    const errorMessage = _.get(error, 'response.data.message')

                    this.$notify({
                        group: 'global',
                        type: 'error',
                        title: 'Error - Claim Connect Page',
                        text: errorMessage ? errorMessage : message,
                        duration: 10000,
                        speed: 1000,
                    })
                })
            },
        }
    }
</script>

<style lang="scss">
.page-resume {
}
</style>
