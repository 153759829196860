export default {
    state: {
        campaign: {
            types: {
                'key-distribution': {
                    label: 'Game Key',
                    icon: ['fas', 'key'],
                },
                'game-sponsorship': {
                    label: 'Paid Twitch Stream',
                    icon: ['fab', 'twitch'],
                },
                'steam-broadcast': {
                    label: 'Paid Steam Broadcast',
                    icon: ['fab', 'steam']
                },
            }
        },

        participant: {
            checklist: {
                groups: {
                    prospect: {
                        title: 'Prospect',
                        icon: ['fas', 'search'],
                        description: 'Creator identified as a good match for campaign. Not yet contacted.',
                    },
                    negotiation: {
                        title: 'Negotiation',
                        icon: ['fas', 'dollar-sign'],
                        description: 'Communication established, contract details are being worked out.'
                    },
                    onboarded: {
                        title: 'Onboarded',
                        icon: ['fas', 'sign-in-alt'],
                        description: 'Creator is registered on our dashboard, deliverables can be communicated.'
                    },
                },
                fields: {
                    contacted: 'Contacted',
                    sent_for_client_approval: 'Sent for client approval',
                    received_client_approval: 'Client approval received',
                    reply_received: 'Reply received',
                    rate_established: 'Rate established',
                    deliverables_confirmed: 'Deliverables confirmed',
                    contract_signed: 'Contract signed',
                    tasks_created: 'Tasks created',
                },
            },

            status: {
                all: {
                    title: 'All',
                    icon: ['fas', 'users'],
                },
                registered: {
                    title: 'Onboarded',
                    icon: ['fas', 'sign-in-alt']
                },
                added: {
                    title: 'Prospect',
                    icon: ['fas', 'search'],
                },
                prospect: {
                    title: 'Prospect',
                    icon: ['fas', 'search'],
                },
                contacted: {
                    title: 'Contacted',
                    icon: ['fas', 'comments']
                },
                invited: {
                    title: 'Invited',
                    icon: ['fas', 'envelope-open'],
                },
            },
        },

        task: {
            title: {
                'custom-text': 'Custom instructions',
                'retrieve-key': 'Retrieve key from vault',
                'twitch-stream': 'Perform Twitch stream',
                'twitch-panel': 'Setup tracking',
            },
            icon: {
                'custom-text': ['fas', 'question'],
                'retrieve-key': ['fas', 'key'],
                'twitch-stream': ['fas', 'broadcast-tower'],
                'twitch-panel': ['fas', 'chart-line'],
            },
        },

        platforms: {
            list: [{
                key: 'twitch',
                title: 'Twitch',
                icon: ['fab', 'twitch'],
                domain_key: 'twitch_tv',
            }, {
                key: 'youtube',
                title: 'YouTube',
                icon: ['fab', 'youtube'],
                domain_key: 'youtube_com',
            }, {
                key: 'twitter',
                title: 'Twitter',
                icon: ['fab', 'twitter'],
                domain_key: 'twitter_com',
            }, {
                key: 'instagram',
                title: 'Instagram',
                icon: ['fab', 'instagram'],
                domain_key: 'instagram_com',
            }, {
                key: 'facebook',
                title: 'Facebook',
                icon: ['fab', 'facebook'],
                domain_key: 'facebook_com',
            }, {
                key: 'tiktok',
                title: 'TikTok',
                icon: ['fab', 'tiktok'],
                domain_key: 'tiktok_com',
            }],
            toAbbreviations: {
                twitch: 'tw',
                youtube: 'yt',
                twitter: 'tt',
                facebook: 'fb',
                instagram: 'ig',
            },
            fromAbbreviations: {
                tw: 'twitch',
                yt: 'youtube',
                tt: 'twitter',
                fb: 'facebook',
                ig: 'instagram',
            },
        },

        resume: {
            interests: {
                food: {
                    emoji: '🍔',
                    title: 'Food',
                    icon: ['fas', 'hamburger'],
                },
                fashion: {
                    emoji: '👜',
                    title: 'Fashion',
                    icon: ['fas', 'tshirt'],
                },
                beauty: {
                    emoji: '💄',
                    title: 'Beauty',
                    icon: ['fas', 'smile'],
                },
                finance: {
                    emoji: '📈',
                    title: 'Finance',
                    icon: ['fas', 'coins'],
                },
                fitness: {
                    emoji: '💪',
                    title: 'Fitness',
                    icon: ['fas', 'dumbbell'],
                },
                outdoors: {
                    emoji: '🏕️',
                    title: 'Outdoors',
                    icon: ['fas', 'hiking'],
                },
                music: {
                    emoji: '🎵',
                    title: 'Music',
                    icon: ['fas', 'music'],
                },
                technology: {
                    emoji: '👨‍💻',
                    title: 'Technology',
                    icon: ['fas', 'microchip'],
                },
                sports: {
                    emoji: '⚽️',
                    title: 'Sports',
                    icon: ['fas', 'basketball-ball'],
                },
                tabletop: {
                    emoji: '🎲',
                    title: 'Tabletop Games',
                    icon: ['fas', 'dragon'],
                },
            },

            hire_modal: {
                email: {
                    label: 'Email address',
                    description: 'We will never share your email with anyone else.',
                },
                firstname: {
                    label: 'First name'
                },
                lastname: {
                    label: 'Last name',
                },
                company: {
                    label: 'Company name'
                },
                message: {
                    label: 'Message',
                    description: 'Please let the creator know what you had in mind.',
                },
            },
        },

        locales: {
            "en": {
                "title": "English",
                "key": "en"
            },
            "zh": {
                "title": "Chinese",
                "key": "zh"
            },
            "ja": {
                "title": "Japanese",
                "key": "ja"
            },
            "ko": {
                "title": "Korean",
                "key": "ko"
            },
            "es": {
                "title": "Spanish",
                "key": "es"
            },
            "fr": {
                "title": "French",
                "key": "fr"
            },
            "de": {
                "title": "German",
                "key": "de"
            },
            "it": {
                "title": "Italian",
                "key": "it"
            },
            "pt": {
                "title": "Portuguese",
                "key": "pt"
            },
            "sv": {
                "title": "Swedish",
                "key": "sv"
            },
            "no": {
                "title": "Norwegian",
                "key": "no"
            },
            "da": {
                "title": "Danish",
                "key": "da"
            },
            "nl": {
                "title": "Dutch",
                "key": "nl"
            },
            "fi": {
                "title": "Finnish",
                "key": "fi"
            },
            "pl": {
                "title": "Polish",
                "key": "pl"
            },
            "el": {
                "title": "Greek",
                "key": "el"
            },
            "ru": {
                "title": "Russian",
                "key": "ru"
            },
            "tr": {
                "title": "Turkish",
                "key": "tr"
            },
            "cs": {
                "title": "Czech",
                "key": "cs"
            },
            "sk": {
                "title": "Slovak",
                "key": "sk"
            },
            "hu": {
                "title": "Hungarian",
                "key": "hu"
            },
            "ar": {
                "title": "Arabic",
                "key": "ar"
            },
            "bg": {
                "title": "Bulgarian",
                "key": "bg"
            },
            "th": {
                "title": "Thai",
                "key": "th"
            },
            "vi": {
                "title": "Vietnamese",
                "key": "vi"
            },
            "asl": {
                "title": "American Sign Language",
                "key": "asl"
            },
            "unknown": {
                "title": "Unknown",
                "key": "unknown"
            }
        },

        countries: {
            "af": {
                "key": "af",
                "title": "Afghanistan"
            },
            "ax": {
                "key": "ax",
                "title": "Åland Islands"
            },
            "al": {
                "key": "al",
                "title": "Albania"
            },
            "dz": {
                "key": "dz",
                "title": "Algeria"
            },
            "as": {
                "key": "as",
                "title": "American Samoa"
            },
            "ad": {
                "key": "ad",
                "title": "AndorrA"
            },
            "ao": {
                "key": "ao",
                "title": "Angola"
            },
            "ai": {
                "key": "ai",
                "title": "Anguilla"
            },
            "aq": {
                "key": "aq",
                "title": "Antarctica"
            },
            "ag": {
                "key": "ag",
                "title": "Antigua and Barbuda"
            },
            "ar": {
                "key": "ar",
                "title": "Argentina"
            },
            "am": {
                "key": "am",
                "title": "Armenia"
            },
            "aw": {
                "key": "aw",
                "title": "Aruba"
            },
            "au": {
                "key": "au",
                "title": "Australia"
            },
            "at": {
                "key": "at",
                "title": "Austria"
            },
            "az": {
                "key": "az",
                "title": "Azerbaijan"
            },
            "bs": {
                "key": "bs",
                "title": "Bahamas"
            },
            "bh": {
                "key": "bh",
                "title": "Bahrain"
            },
            "bd": {
                "key": "bd",
                "title": "Bangladesh"
            },
            "bb": {
                "key": "bb",
                "title": "Barbados"
            },
            "by": {
                "key": "by",
                "title": "Belarus"
            },
            "be": {
                "key": "be",
                "title": "Belgium"
            },
            "bz": {
                "key": "bz",
                "title": "Belize"
            },
            "bj": {
                "key": "bj",
                "title": "Benin"
            },
            "bm": {
                "key": "bm",
                "title": "Bermuda"
            },
            "bt": {
                "key": "bt",
                "title": "Bhutan"
            },
            "bo": {
                "key": "bo",
                "title": "Bolivia"
            },
            "ba": {
                "key": "ba",
                "title": "Bosnia and Herzegovina"
            },
            "bw": {
                "key": "bw",
                "title": "Botswana"
            },
            "bv": {
                "key": "bv",
                "title": "Bouvet Island"
            },
            "br": {
                "key": "br",
                "title": "Brazil"
            },
            "io": {
                "key": "io",
                "title": "British Indian Ocean Territory"
            },
            "bn": {
                "key": "bn",
                "title": "Brunei Darussalam"
            },
            "bg": {
                "key": "bg",
                "title": "Bulgaria"
            },
            "bf": {
                "key": "bf",
                "title": "Burkina Faso"
            },
            "bi": {
                "key": "bi",
                "title": "Burundi"
            },
            "kh": {
                "key": "kh",
                "title": "Cambodia"
            },
            "cm": {
                "key": "cm",
                "title": "Cameroon"
            },
            "ca": {
                "key": "ca",
                "title": "Canada"
            },
            "cv": {
                "key": "cv",
                "title": "Cape Verde"
            },
            "ky": {
                "key": "ky",
                "title": "Cayman Islands"
            },
            "cf": {
                "key": "cf",
                "title": "Central African Republic"
            },
            "td": {
                "key": "td",
                "title": "Chad"
            },
            "cl": {
                "key": "cl",
                "title": "Chile"
            },
            "cn": {
                "key": "cn",
                "title": "China"
            },
            "cx": {
                "key": "cx",
                "title": "Christmas Island"
            },
            "cc": {
                "key": "cc",
                "title": "Cocos (Keeling) Islands"
            },
            "co": {
                "key": "co",
                "title": "Colombia"
            },
            "km": {
                "key": "km",
                "title": "Comoros"
            },
            "cg": {
                "key": "cg",
                "title": "Congo"
            },
            "cd": {
                "key": "cd",
                "title": "Congo, The Democratic Republic of the"
            },
            "ck": {
                "key": "ck",
                "title": "Cook Islands"
            },
            "cr": {
                "key": "cr",
                "title": "Costa Rica"
            },
            "ci": {
                "key": "ci",
                "title": "Cote D'Ivoire"
            },
            "hr": {
                "key": "hr",
                "title": "Croatia"
            },
            "cu": {
                "key": "cu",
                "title": "Cuba"
            },
            "cy": {
                "key": "cy",
                "title": "Cyprus"
            },
            "cz": {
                "key": "cz",
                "title": "Czech Republic"
            },
            "dk": {
                "key": "dk",
                "title": "Denmark"
            },
            "dj": {
                "key": "dj",
                "title": "Djibouti"
            },
            "dm": {
                "key": "dm",
                "title": "Dominica"
            },
            "do": {
                "key": "do",
                "title": "Dominican Republic"
            },
            "ec": {
                "key": "ec",
                "title": "Ecuador"
            },
            "eg": {
                "key": "eg",
                "title": "Egypt"
            },
            "sv": {
                "key": "sv",
                "title": "El Salvador"
            },
            "gq": {
                "key": "gq",
                "title": "Equatorial Guinea"
            },
            "er": {
                "key": "er",
                "title": "Eritrea"
            },
            "ee": {
                "key": "ee",
                "title": "Estonia"
            },
            "et": {
                "key": "et",
                "title": "Ethiopia"
            },
            "fk": {
                "key": "fk",
                "title": "Falkland Islands (Malvinas)"
            },
            "fo": {
                "key": "fo",
                "title": "Faroe Islands"
            },
            "fj": {
                "key": "fj",
                "title": "Fiji"
            },
            "fi": {
                "key": "fi",
                "title": "Finland"
            },
            "fr": {
                "key": "fr",
                "title": "France"
            },
            "gf": {
                "key": "gf",
                "title": "French Guiana"
            },
            "pf": {
                "key": "pf",
                "title": "French Polynesia"
            },
            "tf": {
                "key": "tf",
                "title": "French Southern Territories"
            },
            "ga": {
                "key": "ga",
                "title": "Gabon"
            },
            "gm": {
                "key": "gm",
                "title": "Gambia"
            },
            "ge": {
                "key": "ge",
                "title": "Georgia"
            },
            "de": {
                "key": "de",
                "title": "Germany"
            },
            "gh": {
                "key": "gh",
                "title": "Ghana"
            },
            "gi": {
                "key": "gi",
                "title": "Gibraltar"
            },
            "gr": {
                "key": "gr",
                "title": "Greece"
            },
            "gl": {
                "key": "gl",
                "title": "Greenland"
            },
            "gd": {
                "key": "gd",
                "title": "Grenada"
            },
            "gp": {
                "key": "gp",
                "title": "Guadeloupe"
            },
            "gu": {
                "key": "gu",
                "title": "Guam"
            },
            "gt": {
                "key": "gt",
                "title": "Guatemala"
            },
            "gg": {
                "key": "gg",
                "title": "Guernsey"
            },
            "gn": {
                "key": "gn",
                "title": "Guinea"
            },
            "gw": {
                "key": "gw",
                "title": "Guinea-Bissau"
            },
            "gy": {
                "key": "gy",
                "title": "Guyana"
            },
            "ht": {
                "key": "ht",
                "title": "Haiti"
            },
            "hm": {
                "key": "hm",
                "title": "Heard Island and Mcdonald Islands"
            },
            "va": {
                "key": "va",
                "title": "Holy See (Vatican City State)"
            },
            "hn": {
                "key": "hn",
                "title": "Honduras"
            },
            "hk": {
                "key": "hk",
                "title": "Hong Kong"
            },
            "hu": {
                "key": "hu",
                "title": "Hungary"
            },
            "is": {
                "key": "is",
                "title": "Iceland"
            },
            "in": {
                "key": "in",
                "title": "India"
            },
            "id": {
                "key": "id",
                "title": "Indonesia"
            },
            "ir": {
                "key": "ir",
                "title": "Iran, Islamic Republic Of"
            },
            "iq": {
                "key": "iq",
                "title": "Iraq"
            },
            "ie": {
                "key": "ie",
                "title": "Ireland"
            },
            "im": {
                "key": "im",
                "title": "Isle of Man"
            },
            "il": {
                "key": "il",
                "title": "Israel"
            },
            "it": {
                "key": "it",
                "title": "Italy"
            },
            "jm": {
                "key": "jm",
                "title": "Jamaica"
            },
            "jp": {
                "key": "jp",
                "title": "Japan"
            },
            "je": {
                "key": "je",
                "title": "Jersey"
            },
            "jo": {
                "key": "jo",
                "title": "Jordan"
            },
            "kz": {
                "key": "kz",
                "title": "Kazakhstan"
            },
            "ke": {
                "key": "ke",
                "title": "Kenya"
            },
            "ki": {
                "key": "ki",
                "title": "Kiribati"
            },
            "kp": {
                "key": "kp",
                "title": "Korea, Democratic People'S Republic of"
            },
            "kr": {
                "key": "kr",
                "title": "Korea, Republic of"
            },
            "kw": {
                "key": "kw",
                "title": "Kuwait"
            },
            "kg": {
                "key": "kg",
                "title": "Kyrgyzstan"
            },
            "la": {
                "key": "la",
                "title": "Lao People'S Democratic Republic"
            },
            "lv": {
                "key": "lv",
                "title": "Latvia"
            },
            "lb": {
                "key": "lb",
                "title": "Lebanon"
            },
            "ls": {
                "key": "ls",
                "title": "Lesotho"
            },
            "lr": {
                "key": "lr",
                "title": "Liberia"
            },
            "ly": {
                "key": "ly",
                "title": "Libyan Arab Jamahiriya"
            },
            "li": {
                "key": "li",
                "title": "Liechtenstein"
            },
            "lt": {
                "key": "lt",
                "title": "Lithuania"
            },
            "lu": {
                "key": "lu",
                "title": "Luxembourg"
            },
            "mo": {
                "key": "mo",
                "title": "Macao"
            },
            "mk": {
                "key": "mk",
                "title": "Macedonia, The Former Yugoslav Republic of"
            },
            "mg": {
                "key": "mg",
                "title": "Madagascar"
            },
            "mw": {
                "key": "mw",
                "title": "Malawi"
            },
            "my": {
                "key": "my",
                "title": "Malaysia"
            },
            "mv": {
                "key": "mv",
                "title": "Maldives"
            },
            "ml": {
                "key": "ml",
                "title": "Mali"
            },
            "mt": {
                "key": "mt",
                "title": "Malta"
            },
            "mh": {
                "key": "mh",
                "title": "Marshall Islands"
            },
            "mq": {
                "key": "mq",
                "title": "Martinique"
            },
            "mr": {
                "key": "mr",
                "title": "Mauritania"
            },
            "mu": {
                "key": "mu",
                "title": "Mauritius"
            },
            "yt": {
                "key": "yt",
                "title": "Mayotte"
            },
            "mx": {
                "key": "mx",
                "title": "Mexico"
            },
            "fm": {
                "key": "fm",
                "title": "Micronesia, Federated States of"
            },
            "md": {
                "key": "md",
                "title": "Moldova, Republic of"
            },
            "mc": {
                "key": "mc",
                "title": "Monaco"
            },
            "mn": {
                "key": "mn",
                "title": "Mongolia"
            },
            "ms": {
                "key": "ms",
                "title": "Montserrat"
            },
            "ma": {
                "key": "ma",
                "title": "Morocco"
            },
            "mz": {
                "key": "mz",
                "title": "Mozambique"
            },
            "mm": {
                "key": "mm",
                "title": "Myanmar"
            },
            "na": {
                "key": "na",
                "title": "Namibia"
            },
            "nr": {
                "key": "nr",
                "title": "Nauru"
            },
            "np": {
                "key": "np",
                "title": "Nepal"
            },
            "nl": {
                "key": "nl",
                "title": "Netherlands"
            },
            "an": {
                "key": "an",
                "title": "Netherlands Antilles"
            },
            "nc": {
                "key": "nc",
                "title": "New Caledonia"
            },
            "nz": {
                "key": "nz",
                "title": "New Zealand"
            },
            "ni": {
                "key": "ni",
                "title": "Nicaragua"
            },
            "ne": {
                "key": "ne",
                "title": "Niger"
            },
            "ng": {
                "key": "ng",
                "title": "Nigeria"
            },
            "nu": {
                "key": "nu",
                "title": "Niue"
            },
            "nf": {
                "key": "nf",
                "title": "Norfolk Island"
            },
            "mp": {
                "key": "mp",
                "title": "Northern Mariana Islands"
            },
            "no": {
                "key": "no",
                "title": "Norway"
            },
            "om": {
                "key": "om",
                "title": "Oman"
            },
            "pk": {
                "key": "pk",
                "title": "Pakistan"
            },
            "pw": {
                "key": "pw",
                "title": "Palau"
            },
            "ps": {
                "key": "ps",
                "title": "Palestinian Territory, Occupied"
            },
            "pa": {
                "key": "pa",
                "title": "Panama"
            },
            "pg": {
                "key": "pg",
                "title": "Papua New Guinea"
            },
            "py": {
                "key": "py",
                "title": "Paraguay"
            },
            "pe": {
                "key": "pe",
                "title": "Peru"
            },
            "ph": {
                "key": "ph",
                "title": "Philippines"
            },
            "pn": {
                "key": "pn",
                "title": "Pitcairn"
            },
            "pl": {
                "key": "pl",
                "title": "Poland"
            },
            "pt": {
                "key": "pt",
                "title": "Portugal"
            },
            "pr": {
                "key": "pr",
                "title": "Puerto Rico"
            },
            "qa": {
                "key": "qa",
                "title": "Qatar"
            },
            "re": {
                "key": "re",
                "title": "Reunion"
            },
            "ro": {
                "key": "ro",
                "title": "Romania"
            },
            "ru": {
                "key": "ru",
                "title": "Russian Federation"
            },
            "rw": {
                "key": "rw",
                "title": "RWANDA"
            },
            "sh": {
                "key": "sh",
                "title": "Saint Helena"
            },
            "kn": {
                "key": "kn",
                "title": "Saint Kitts and Nevis"
            },
            "lc": {
                "key": "lc",
                "title": "Saint Lucia"
            },
            "pm": {
                "key": "pm",
                "title": "Saint Pierre and Miquelon"
            },
            "vc": {
                "key": "vc",
                "title": "Saint Vincent and the Grenadines"
            },
            "ws": {
                "key": "ws",
                "title": "Samoa"
            },
            "sm": {
                "key": "sm",
                "title": "San Marino"
            },
            "st": {
                "key": "st",
                "title": "Sao Tome and Principe"
            },
            "sa": {
                "key": "sa",
                "title": "Saudi Arabia"
            },
            "sn": {
                "key": "sn",
                "title": "Senegal"
            },
            "cs": {
                "key": "cs",
                "title": "Serbia and Montenegro"
            },
            "sc": {
                "key": "sc",
                "title": "Seychelles"
            },
            "sl": {
                "key": "sl",
                "title": "Sierra Leone"
            },
            "sg": {
                "key": "sg",
                "title": "Singapore"
            },
            "sk": {
                "key": "sk",
                "title": "Slovakia"
            },
            "si": {
                "key": "si",
                "title": "Slovenia"
            },
            "sb": {
                "key": "sb",
                "title": "Solomon Islands"
            },
            "so": {
                "key": "so",
                "title": "Somalia"
            },
            "za": {
                "key": "za",
                "title": "South Africa"
            },
            "gs": {
                "key": "gs",
                "title": "South Georgia and the South Sandwich Islands"
            },
            "es": {
                "key": "es",
                "title": "Spain"
            },
            "lk": {
                "key": "lk",
                "title": "Sri Lanka"
            },
            "sd": {
                "key": "sd",
                "title": "Sudan"
            },
            "sr": {
                "key": "sr",
                "title": "Suriname"
            },
            "sj": {
                "key": "sj",
                "title": "Svalbard and Jan Mayen"
            },
            "sz": {
                "key": "sz",
                "title": "Swaziland"
            },
            "se": {
                "key": "se",
                "title": "Sweden"
            },
            "ch": {
                "key": "ch",
                "title": "Switzerland"
            },
            "sy": {
                "key": "sy",
                "title": "Syrian Arab Republic"
            },
            "tw": {
                "key": "tw",
                "title": "Taiwan, Province of China"
            },
            "tj": {
                "key": "tj",
                "title": "Tajikistan"
            },
            "tz": {
                "key": "tz",
                "title": "Tanzania, United Republic of"
            },
            "th": {
                "key": "th",
                "title": "Thailand"
            },
            "tl": {
                "key": "tl",
                "title": "Timor-Leste"
            },
            "tg": {
                "key": "tg",
                "title": "Togo"
            },
            "tk": {
                "key": "tk",
                "title": "Tokelau"
            },
            "to": {
                "key": "to",
                "title": "Tonga"
            },
            "tt": {
                "key": "tt",
                "title": "Trinidad and Tobago"
            },
            "tn": {
                "key": "tn",
                "title": "Tunisia"
            },
            "tr": {
                "key": "tr",
                "title": "Turkey"
            },
            "tm": {
                "key": "tm",
                "title": "Turkmenistan"
            },
            "tc": {
                "key": "tc",
                "title": "Turks and Caicos Islands"
            },
            "tv": {
                "key": "tv",
                "title": "Tuvalu"
            },
            "ug": {
                "key": "ug",
                "title": "Uganda"
            },
            "ua": {
                "key": "ua",
                "title": "Ukraine"
            },
            "ae": {
                "key": "ae",
                "title": "United Arab Emirates"
            },
            "gb": {
                "key": "gb",
                "title": "United Kingdom"
            },
            "us": {
                "key": "us",
                "title": "United States"
            },
            "um": {
                "key": "um",
                "title": "United States Minor Outlying Islands"
            },
            "uy": {
                "key": "uy",
                "title": "Uruguay"
            },
            "uz": {
                "key": "uz",
                "title": "Uzbekistan"
            },
            "vu": {
                "key": "vu",
                "title": "Vanuatu"
            },
            "ve": {
                "key": "ve",
                "title": "Venezuela"
            },
            "vn": {
                "key": "vn",
                "title": "Viet Nam"
            },
            "vg": {
                "key": "vg",
                "title": "Virgin Islands, British"
            },
            "vi": {
                "key": "vi",
                "title": "Virgin Islands, U.S."
            },
            "wf": {
                "key": "wf",
                "title": "Wallis and Futuna"
            },
            "eh": {
                "key": "eh",
                "title": "Western Sahara"
            },
            "ye": {
                "key": "ye",
                "title": "Yemen"
            },
            "zm": {
                "key": "zm",
                "title": "Zambia"
            },
            "zw": {
                "key": "zw",
                "title": "Zimbabwe"
            }
        }
    },

    getters: {
        dictionary: (state) => {
            return state
        },

        getFromDictionary: (state) => (path, field) => {
            const item = _.get(state, path)

            if (field) {
                return item ? _.get(item, field) : ''
            }

            return item ? item : ''
        },
    }
}
