import Vue from 'vue'

export default {
    state: {
        profiles: {}
    },

    mutations: {
        'profiles:set'(state, { profile, platform_id }) {
            Vue.set(state.profiles, platform_id ? platform_id : profile.platform_id, profile)
        },

        'profiles:setAll'(state, { profiles }) {
            Vue.set(state, 'profiles', profiles)
        },

        'profiles:delete'(state, { platform_id }) {
            Vue.delete(state.profiles, platform_id)
        },
    },

    getters: {
        userProfiles(state) {
            return state.profiles
        }
    }
}
