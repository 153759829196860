/* ============
 * Vuex Store
 * ============
 *
 * The store of the application
 *
 * http://vuex.vuejs.org/en/index.html
 */

import Vue from 'vue'
import Vuex from 'vuex'
import createLogger from 'vuex/dist/logger'
import createPersistedState from 'vuex-persistedstate'

// Modules
import authentication from './authentication'
import colors from './colors'
import self from './self'
import notifications from './notifications'
import modal from './modal'
import profiles from './profiles'
import thresholds from './thresholds'
import dictionary from './dictionary'

Vue.use(Vuex)

// Only persist certain paths to localstorage
const plugins = [
    createPersistedState({
        paths: [
            'authentication',
            'self',
            'profiles'
        ]
    })
]

// Only use the logger in development
if (process.env.NODE_ENV !== 'production') {
    plugins.push(createLogger())
}

export default new Vuex.Store({
    /**
     * Assign the modules to the store
     */
    modules: {
        authentication,
        colors,
        self,
        notifications,
        modal,
        profiles,
        thresholds,
        dictionary,
    },

    /**
     * If strict mode should be enabled
     */
    strict: false,

    /**
     * Plugins used in the store
     */
    plugins,
})
