import Vue from 'vue'
import App from './App.vue'
import store from './store'
import Router from './router'

// Plugins
import Argon from './plugins/argon-kit'
import Analytics from './plugins/analytics'
import FontAwesome from './plugins/fontAwesome'
import Bootstrap from './plugins/bootstrap'

// Dependencies
import VuexRouterSync from 'vuex-router-sync'
// import LoadScript from 'vue-plugin-load-script'
import Velocity from 'velocity-animate'
import VueIntercom from 'vue-intercom'
import Notifications from 'vue-notification'
import Vue2TouchEvents from 'vue2-touch-events'

import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'
import Tracker from '@openreplay/tracker'


if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: 'https://31ae66917fd045669da90d9d7a831307@sentry.io/5135722',
        integrations: [new Integrations.Vue({
            Vue,
            attachProps: true,
            logErrors: true
        })]
    })
}

const tracker = new Tracker({
    projectKey: process.env.VUE_APP_OPENREPLAY_KEY,
})

Vue.prototype.$openReplayTracker = tracker

tracker.start()

const router = Router(store)

// Analytics
if (process.env.VUE_APP_ANALYTICS_ENABLED == 'true') {
    Vue.use(Analytics(router))
}

// Intercom (live chat)
if (process.env.VUE_APP_INTERCOM_ENABLED) {
    Vue.use(VueIntercom, {
        appId: process.env.VUE_APP_INTERCOM_KEY
    })
}

VuexRouterSync.sync(store, router)
Vue.config.productionTip = false

Vue.config.apiURL = process.env.VUE_APP_ROOT_API
Vue.use(Argon)
Vue.use(FontAwesome)
// Vue.use(LoadScript)
Vue.use(Bootstrap)
Vue.use(Notifications, { velocity: Velocity })
Vue.use(Vue2TouchEvents)

Vue.config.devtools = true

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
