<template>
    <div class="window-switcher-wrapper ml-auto d-flex">
        <div class="window-switcher d-flex my-auto">
            <div
                v-for="metricWindowOption in metricWindows"
                class="window d-flex px-3 text-center"
                :class="{
                    'active': values.metricWindow === metricWindowOption.value
                }"
                @click="setMetricWindow(metricWindowOption.value)"
            >
                <div class="window-inner">
                    <span>{{ metricWindowOption.label }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        metricWindows: {
            type: Array,
        },
    },

    data() {
        return {
            values: {
                metricWindow: 'day_30',
            },
        }
    },

    created() {
        const savedMetricWindow = localStorage.getItem('home-metric-window')

        if (savedMetricWindow) {
            this.values.metricWindow = savedMetricWindow
        }
    },

    methods: {
        setMetricWindow(newValue) {
            localStorage.setItem('home-metric-window', newValue)
            this.values.metricWindow = newValue
            this.$emit('switched', newValue)
        },
    },
}
</script>

<style lang="scss" scoped>
.window-switcher {
    border: 2px solid #2A2B33;
    padding: 3px;
    // max-width: 156px;
    height: 36px;
    border-radius: 6px;

    .window {
        width: 50%;
        height: 100%;
        border-radius: 4px;
        color: #fff;
        transition: 0.25s all;

        &.active {
            background: #FCD878;
            color: #000;
        }

        &:not(.active) {
            cursor: pointer;

            &:hover {
                background: rgba(white, 0.05);
            }
        }

        .window-inner {
            margin: auto;
            font-size: 12px;
            line-height: 14px;
            font-weight: 700;
            white-space: nowrap;
        }
    }
}
</style>
