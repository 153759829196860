<template>
<div>
    <b-table
        id="streams-with-chapters-table"
        ref="table"
        class="table-panels"
        :busy.sync="isBusy"
        :items="items"
        :fields="fields"
        sort-by="ended_at"
        :sort-desc="true"
        fixed
        borderless
        sort-icon-right
        :per-page="pagination.perPage"
        :current-page="pagination.currentPage"
    >
        <template #cell(chapters)="data">
            <div class="row no-gutters row-games">
                <div class="col" v-for="chapter in data.item.chapters">
                    <img class="box-art" :src="chapter.game.avatarURL" :alt="chapter.game.name" v-tooltip="chapter.game.name">
                </div>
            </div>
        </template>
    </b-table>

    <div class="d-flex">
        <b-pagination
            class="mx-auto"
            :total-rows="totalRows"
            :per-page="pagination.perPage"
            v-model="pagination.currentPage"
         />
    </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import numeral from 'numeral'

export default {
    components: {},

    props: {
        items: {
            type: Array,
        },
    },

    data() {
        return {
            isBusy: false,

            pagination: {
                perPage: 10,
                currentPage: 1,
                totalRows: 0,
            },

            fields: [{
                key: 'chapters[0].title',
                label: 'Title',
                sortable: true,
                formatter: (value, key, item) => {
                    if (value) {
                        return value
                    } else {
                        return '---'
                    }
                },
            }, {
                key: 'statistics.avg_viewers',
                label: 'ACV',
                sortable: true,
                formatter: (value, key, item) => {
                    if (value) {
                        return numeral(value).format('0,0')
                    } else {
                        return '---'
                    }
                },
            }, {
                key: 'statistics.peak_viewers.value',
                label: 'Peak Viewers',
                sortable: true,
                formatter: (value, key, item) => {
                    if (value) {
                        return numeral(value).format('0,0')
                    } else {
                        return '---'
                    }
                },
            }, {
                key: 'statistics.views_gain',
                label: 'Impressions',
                sortable: true,
                formatter: (value, key, item) => {
                    if (value) {
                        return numeral(value).format('0,0')
                    } else {
                        return '---'
                    }
                },
            }, {
                key: 'duration_seconds',
                label: 'Duration',
                sortable: true,
                sortDirection: 'desc',
                formatter: (value, key, item) => {
                    const hours = Math.floor(value / (60 * 60))
                    const minutes = Math.round((value % (60 * 60)) / 60)

                    let result = []
                    if (hours > 0) result.push(`${hours}h`)
                    if (minutes > 0) result.push(`${minutes}m`)
                    return result.join(' ')
                }
            }, {
                key: 'started_at',
                label: 'Started At',
                sortable: true,
                sortDirection: 'desc',
                formatter: (value, key, item) => {
                    return moment(value).calendar()
                },
            }, {
                key: 'chapters',
                label: 'Games',
                sortable: false,
            }],
        }
    },

    computed: {
        ...mapGetters([
            'getColors'
        ]),

        totalRows() {
            return _.size(this.items)
        },
    },

    methods: {}
}
</script>

<style lang="scss">
.table-panels {
    border-collapse:separate;
    border-spacing: 0 0.5rem;
    transition: opacity 0.25s;

    &[aria-busy="true"] {
        opacity: 0.5;
    }

    thead {
        th {
            font-weight: 400;
            line-height: 1.3;
            color: #f6f6f8;
            font-size: 1rem;
        }
    }

    tbody {
        tr {
            background: rgba(white, 0.05);
            border-radius: 5px;
            margin-bottom: 5px;

            &:hover {
                background-color: rgba(white, 0.075);
            }

            td {
                vertical-align: middle;
            }

            td:first-child {
                border-top-left-radius: 12px;
                border-bottom-left-radius: 12px;
            }

            td:last-child {
                border-top-right-radius: 12px;
                border-bottom-right-radius: 12px;
            }
        }
    }
}

#streams-with-chapters-table {
    .row-games {
        justify-content: flex-end;
        flex-wrap: nowrap;

        .col {
            flex-grow: 0;
        }
    }

    .box-art {
        width: 51px;
        height: 71.25px;
        border-radius: 5px;
    }
}
</style>
