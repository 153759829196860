<template>
    <div class="page-moderation">
        <div class="row">
            <div class="col-md-3 offset-md-3 px-1">
                <small class="text-uppercase font-weight-bold">User Name</small>
                <input
                    aria-describedby="addon-right addon-left"
                    placeholder="User Name"
                    class="form-control mt-1"
                    v-model="target"
                    @keyup.enter="retrieve()"
                >
            </div>

            <div class="col px-1 d-flex mt-4">
                <button type="button" class="btn btn-primary submit" @click.prevent="retrieve()">Submit</button>
            </div>
        </div>

        <div class="row mt-5">
            <div class="col">
                <table class="table">
                    <thead>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Rank</th>
                        <th>Rating</th>
                        <th></th>
                    </thead>
                    <tbody>
                        <tr v-for="user in names" v-if="names.length > 0">
                            <td><a :href="`/admin/users/${user.id}`" @click.prevent="goToUser(user.id)">{{ user.id }}</a></td>
                            <td>{{ user.name }}</td>
                            <td>{{ user.rank || 'None' | capitalize }}</td>
                            <td>{{ user.rating || 'None' }}</td>
                            <td class="text-right">
                                <button v-if="self.user.is_administrator" class="btn btn-sm btn-warning ml-1" @click="loginAsUser(user.id)">Login as User</button>
                            </td>
                        </tr>
                        <tr v-if="names.length === 0 && state === 'idle'">
                            <td colspan="6" class="text-center">Type a user name to find results.</td>
                        </tr>
                        <tr v-if="names.length === 0 && state === 'retrieved'">
                            <td colspan="6" class="text-center">Failed to retrieve users similar to the provided user name.</td>
                        </tr>
                        <tr v-if="state === 'retrieving'">
                            <td colspan="6" class="text-center">Retrieving users...</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'

export default {
    name: 'moderation',
    metaInfo() {
        return {
            title: 'Users - Moderation'
        }
    },
    data() {
        return {
            target: '',
            names: [],
            state: 'idle'
        }
    },
    computed: {
        ...mapGetters([
            'self'
        ])
    },
    created() {
        if (!this.self.user.is_moderator) {
            this.$router.push({ name: 'landing' })
        }
    },
    methods: {
        retrieve() {
            if (!this.target) return

            this.state = 'retrieving'

            this.axios.get(`/admin/users/search/${this.target}`).then((response) => {
                this.names = _.orderBy(response.data.payload, item => item.rating || 0, ['desc'])
                this.state = 'retrieved'
            }).catch((error) => {
                console.error(error)
            })
        },

        goToUser(user_id) {
            this.$router.push({ name: 'admin-user-single', params: { id: user_id } })
        },

        loginAsUser(id) {
            this.axios.get(`/admin/users/${id}/passport`)
                .then(({ data }) => {
                    const jwtToken = _.get(data, 'payload.passport.payload')
                    this.$store.commit('authentication:login', jwtToken)
                    window.location.href = '/'
                })
        },
    }
}
</script>
<style scoped lang="scss">
    .table {
        tr {
            background: rgba(255, 255, 255, 0.05);
        }

        th {
            border: none;
        }
    }

    .form-control {
        border-color: rgba(255, 255, 255, 0.05) !important;
    }
</style>
