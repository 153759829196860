<template>
    <div>
        <div class="row mb-2">
            <div class="col-lg-9">
                <h3 class="display-4">Link Analytics</h3>
                <p>Past 30 days clicks per unique link. Use the dropdown menu to choose which link to view.</p>
            </div>

            <div class="col-lg-3 d-flex align-self-center" v-if="data">
                <b-form-select v-model="selected" @change="setData">
                    <template v-for="(details, name) in data.day">
                        <b-form-select-option :value='name'>{{ name }}</b-form-select-option>
                    </template>
                </b-form-select>
            </div>
        </div>
        <template v-if="showGraph">
            <div class="row mb-4">
                <div class="col-lg-12">
                    <div class="total-stats py-3 px-3 d-flex justify-content-center">
                        <div class="mx-4 d-flex flex-column align-items-center">
                            <div>Clicks</div>
                            <h4 class="display-4 m-0">{{ data.totals[selected].clicks }}</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div ref="chartdiv" class="chart"></div>
        </template>
        <template v-else>
            <div class="chart d-flex justify-content-center align-items-center">
                <h5 class="display-5">There are no link analytics to show</h5>
            </div>
        </template>
    </div>
</template>

<script>
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'
import am4themes_dark from '@amcharts/amcharts4/themes/dark'
import moment from 'moment'

am4core.useTheme(am4themes_dark)
am4core.useTheme(am4themes_animated)

export default {
    name: "link-analytics",

    props: [
        'data',
        'show'
    ],

    data() {
        return {
            chart: false,
            selected: null,
            showGraph: false
        }
    },

    created() {
        // if there are no current active links then dont show anything
        if (Object.entries(this.data.day).length == 0) {
            this.showGraph = false
        } else {
            this.showGraph = true
            this.selected = Object.entries(this.data.day)[0][0]
        }
    },

    mounted() {
        this.render()
    },


    methods: {
        setData() {
            let plotData = []

            for (let [key, value] of Object.entries(this.data.day[this.selected])) {
                plotData.push({
                    date: key,
                    clicks: value.clicks
                })
            }

            this.chart.data = plotData
        },
        render() {
            if (this.chart) {
                this.chart.dispose()
            }

            this.chart = am4core.create(this.$refs.chartdiv, am4charts.XYChart)
            this.chart.logo.disabled = true
            this.colorSet = new am4core.ColorSet()

            this.setData()

            this.chart.dateFormatter.inputDateFormat = "yyyy-MM-dd"

            let dateAxis = this.chart.xAxes.push(new am4charts.DateAxis())
            dateAxis.tooltip.label.fill = am4core.color("#ffffff")
            dateAxis.tooltip.background.fill = am4core.color('#000')
            dateAxis.tooltip.background.cornerRadiusTopLeft = 5
            dateAxis.tooltip.background.cornerRadiusTopRight = 5
            dateAxis.tooltip.background.strokeOpacity = 0
            dateAxis.tooltip.label.minWidth = 40
            dateAxis.tooltip.label.minHeight = 40
            dateAxis.tooltip.label.textAlign = "middle"
            dateAxis.tooltip.label.textValign = "middle"

            let valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis())
            if (this.data.maxClicks == 0) {
                valueAxis.max = 1
            } else {
                valueAxis.max = this.data.maxClicks
            }

            valueAxis.min = 0
            // valueAxis.tooltip.disabled = true
            // valueAxis.title.text = "Clicks"

            let series = this.chart.series.push(new am4charts.ColumnSeries())
            series.tooltipText="{clicks} click(s)"
            series.tooltip.getFillFromObject = false
            series.tooltip.background.fill = am4core.color('#000')
            series.tooltip.background.fillOpacity = 0.85
            series.tooltip.background.cornerRadiusTopLeft = 5
            series.tooltip.background.cornerRadiusTopRight = 5
            series.tooltip.background.strokeOpacity = 0
            series.tooltip.label.minWidth = 40
            series.tooltip.label.minHeight = 40
            series.tooltip.label.textAlign = "middle"
            series.tooltip.label.textValign = "middle"

            // series.columns.template.fill = am4core.color('#fcd878')
            series.columns.template.strokeWidth = 0

            series.dataFields.dateX = 'date'
            series.dataFields.valueY = 'clicks'

            this.chart.cursor = new am4charts.XYCursor()
            this.chart.cursor.behavior = "panXY"
            this.chart.cursor.xAxis = dateAxis
            this.chart.cursor.snapToSeries = series

        },
    },

    beforeDestroy() {
        if (this.chart) {
            this.chart.dispose()
        }
    }
}
</script>

<style lang="scss">
.chart {
    height: 300px !important;
}
</style>
