<template>
    <div class="slot-list-section">
        <div class="row row-panels mb-sm" v-for="(slots, slotDay) in slotsByDay">
            <div class="col-lg-12 mb-2">
                <p class="lead m-0">{{ slotDay | moment('dddd, MMMM Do YYYY') }}</p>
            </div>

            <div class="col-lg-3 mb-2" v-for="(slot, index) in slots">
                <div
                    class="panel p-3 position-relative"
                    @click="onSlotClick(slot)"
                    :class="{
                        'active': slot.is_selected,
                        'clickable': !slot.is_selected,
                        'hoverable':  !slot.is_selected,
                    }"
                >
                    <div v-if="isActionsEnabled" class="delete py-2 px-3" @click="onSlotDeleteClick(slot)">
                        <font-awesome-icon :icon="['fas', 'xmark']" />
                    </div>

                    <div class="row">
                        <div class="col-lg-6">
                            <small class="font-weight-bold">Start</small>
                            <p class="m-0">{{ slot.start_at | moment('hh:mm A') }}</p>
                        </div>
                        <div class="col-lg-6">
                            <small class="font-weight-bold">End</small>
                            <p class="m-0">{{ slot.end_at | moment('hh:mm A') }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4" v-if="!slots || slots.length === 0">
                <div class="panel p-3">
                    <p class="m-0">No broadcast slots have been added to this campaign yet.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash'
    import moment from 'moment'

    export default {
        name: 'slot-list-section',

        props: {
            slots: {
                type: Array,
            },

            isActionsEnabled: {
                type: Boolean,
                default: () => false,
            },
        },

        data() {
            return {
            }
        },

        computed: {
            slotsByDay() {
                return _(this.slots).groupBy((slot) => {
                    return moment(slot.start_at).format('YYYY-MM-DD')
                }).mapValues((slots) => {
                    return _.orderBy(slots, ['start_at'], ['asc'])
                }).value()
            },
        },

        methods: {
            onSlotClick(slot) {
                if (!slot.is_selected) {
                    this.$emit('slot-clicked', slot)
                }
            },

            onSlotDeleteClick(slot) {
                if (slot.is_selected) {
                    this.$emit('slot-delete-clicked', slot)
                }
            },
        }
    }
</script>

<style lang="scss">
.slot-list-section {
    .row-panels {
        .panel {
            .delete {
                display: none;
                position: absolute;
                top: 0;
                right: 0;
                z-index: 10;
                opacity: 0.9;

                &:hover {
                    opacity: 1;
                }
            }

            &.active {
                background: #fcd878 !important;
                color: #000;

                .delete {
                    display: block;
                    cursor: pointer;
                }
            }
        }
    }
}
</style>
