<template>
<div class="form-resume-settings">

    <div class="row mt-md">
        <div class="col-lg-4">
            <h5 class="h-5">Contact</h5>
            <p>Information used to contact you about sponsorship opportunities.</p>

            <b-alert
                variant="danger"
                show
                v-for="error in updateErrorsByGroup.contact"
                v-if="updateErrorsByGroup.contact && updateErrorsByGroup.contact.length"
                class="mb-2"
            >{{ error.message }}</b-alert>
        </div>

        <div class="col-lg-7 offset-lg-1">
            <b-form-group
                label="Business email"
            >
                <b-form-input
                    v-model="values.business_email"
                    :trim="true"
                    type="email"
                    required
                />
            </b-form-group>
        </div>
    </div>

    <div class="row pt-md mt-md border-top">
        <div class="col-lg-4">
            <h5 class="h-5">Demographics</h5>
            <p>Used to select you for specific opportunities for your local region, locale or age group.</p>

            <b-alert
                variant="danger"
                show
                v-for="error in updateErrorsByGroup.demographics"
                v-if="updateErrorsByGroup.demographics && updateErrorsByGroup.demographics.length"
                class="mb-2"
            >{{ error.message }}</b-alert>
        </div>

        <div class="col-lg-7 offset-lg-1">
            <b-form-group
                class="mb-sm"
                label="Language"
            >
                <b-form-select v-model="values.language">
                    <b-form-select-option :value="null">Select a language</b-form-select-option>
                    <b-form-select-option :value="locale.key" v-for="locale in dictionary.locales">{{ locale.title }}</b-form-select-option>
                </b-form-select>
            </b-form-group>

            <b-form-group
                class="mb-sm"
                label="Country"
            >
                <country-select
                    v-model="values.country"
                    :country="values.country"
                    topCountry="US"
                    className="form-control custom-select"
                />

                <region-select
                    v-if="values.country"
                    v-model="values.region"
                    :country="values.country"
                    :region="values.region"
                    className="form-control custom-select mt-2"
                />
            </b-form-group>

            <b-form-group
                class="mb-sm"
                label="Date of birth"
            >
                <b-form-input type="date" v-model="values.birthday" />
            </b-form-group>
        </div>
    </div>

    <div class="row pt-md mt-md border-top">
        <div class="col-lg-4">
            <h5 class="h-5">Personality</h5>
            <p>Helps us bring you product sponsorships that are in line with your interests.</p>

            <b-alert
                variant="danger"
                show
                v-for="error in updateErrorsByGroup.personality"
                v-if="updateErrorsByGroup.personality && updateErrorsByGroup.personality.length"
                class="mb-2"
            >{{ error.message }}</b-alert>
        </div>

        <div class="col-lg-7 offset-lg-1">
            <div class="mb-sm attributes-wrapper">
                <div class="d-flex">
                    <h4 class="col-form-label mb-0 w-50">Attributes</h4>
                    <div class="col-form-label points-left w-50 text-right">{{ (settings.attributes.budget - allocatedAttributePoints) }} points left</div>
                </div>

                <div class="panel outline px-4 py-3">
                    <div class="row">
                        <div class="col-lg-12 attribute d-flex">
                            <h5 class="m-0">
                                Humor
                            </h5>

                            <div class="circles d-flex">
                                <div
                                    class="circle"
                                    v-for="i in 10"
                                    :class="{
                                        'active': i <= values.attributes.humor
                                    }"
                                />
                            </div>

                            <div class="actions d-flex">
                                <font-awesome-icon :icon="['fas', 'plus']" @click="assignAttributePoint('humor', 'add')" />
                                <font-awesome-icon :icon="['fas', 'minus']" @click="assignAttributePoint('humor', 'subtract')" class="ml-2" />
                            </div>
                        </div>
                        <div class="col-lg-12 attribute mt-4 d-flex">
                            <h5 class="m-0">Skill</h5>

                            <div class="circles d-flex">
                                <div
                                    class="circle"
                                    v-for="i in 10"
                                    :class="{
                                        'active': i <= values.attributes.skill
                                    }"
                                />
                            </div>

                            <div class="actions d-flex">
                                <font-awesome-icon :icon="['fas', 'plus']" @click="assignAttributePoint('skill', 'add')" />
                                <font-awesome-icon :icon="['fas', 'minus']" @click="assignAttributePoint('skill', 'subtract')" class="ml-2" />
                            </div>
                        </div>
                        <div class="col-lg-12 attribute mt-4 d-flex">
                            <h5 class="m-0">Informative</h5>

                            <div class="circles d-flex">
                                <div
                                    class="circle"
                                    v-for="i in 10"
                                    :class="{
                                        'active': i <= values.attributes.informative
                                    }"
                                />
                            </div>

                            <div class="actions d-flex">
                                <font-awesome-icon :icon="['fas', 'plus']" @click="assignAttributePoint('informative', 'add')" />
                                <font-awesome-icon :icon="['fas', 'minus']" @click="assignAttributePoint('informative', 'subtract')" class="ml-2" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="interests-wrapper mb-sm">
                <h4 class="col-form-label m-0">Interests</h4>

                <div class="badges-toggleable d-flex flex-wrap">
                    <b-badge
                        v-for="key in options.interests"
                        :variant="isInterestSelected(key) ? 'primary' : null"
                        class="mb-1 ml-0 mr-1"
                        @click="toggleInterest(key)"
                    >
                        <span class="font-weight-normal">{{ getFromDictionary(`resume.interests.${key}`, 'emoji') }}</span> {{ getFromDictionary(`resume.interests.${key}`, 'title') }}
                    </b-badge>
                </div>

                <small class="form-text text-muted mt-2">Select the 3 things that interest you the most.</small>
            </div>
        </div>
    </div>

    <div class="row pt-md mt-md border-top">
        <div class="col-lg-4">
            <h5 class="h-5">Identity</h5>
            <p>At Streamforge, we are committed to helping companies hire in a more inclusive way. Self identifying is completely optional, and we'll handle your information with care.</p>

            <b-alert
                variant="danger"
                show
                v-for="error in updateErrorsByGroup.indentity"
                v-if="updateErrorsByGroup.identity && updateErrorsByGroup.identity"
                class="mb-2"
            >{{ error.message }}</b-alert>
        </div>

        <div class="col-lg-7 offset-lg-1">
            <b-form-group
                class="mb-sm"
                label="Pronouns"
            >
                <b-form-select v-model="values.pronouns">
                    <b-form-select-option value="none">Select an option</b-form-select-option>
                    <b-form-select-option value="he-him">He/Him</b-form-select-option>
                    <b-form-select-option value="she-her">She/Her</b-form-select-option>
                    <b-form-select-option value="they-them">They/Them</b-form-select-option>
                    <b-form-select-option value="self-describe">Self-describe</b-form-select-option>
                    <b-form-select-option value="abstain">Prefer not to say</b-form-select-option>
                </b-form-select>
            </b-form-group>

            <b-form-group
                class="mb-sm"
                label="Pronouns - Self-describe"
                v-if="values.pronouns && values.pronouns === 'self-describe'"
            >
                <b-form-input type="text" v-model="values.pronouns_self_describe" />
            </b-form-group>

            <b-form-group
                class="mb-sm"
                label="Gender identity"
            >
                <b-form-select v-model="values.gender">
                    <b-form-select-option value="none">Select an option</b-form-select-option>
                    <b-form-select-option value="male">Male</b-form-select-option>
                    <b-form-select-option value="female">Female</b-form-select-option>
                    <b-form-select-option value="non-binary">Non-binary</b-form-select-option>
                    <b-form-select-option value="self-describe">Self-describe</b-form-select-option>
                    <b-form-select-option value="abstain">Prefer not to say</b-form-select-option>
                </b-form-select>
            </b-form-group>

            <b-form-group
                class="mb-sm"
                label="Gender Identity - Self-describe"
                v-if="values.gender && values.gender === 'self-describe'"
            >
                <b-form-input type="text" v-model="values.gender_self_describe" />
            </b-form-group>
        </div>
    </div>

    <div class="row pt-md mt-md border-top">
        <div class="col-lg-4">
            <h5 class="h-5">Hardware</h5>
            <p>Help us understand how you make your content.</p>

            <b-alert
                variant="danger"
                show
                v-for="error in updateErrorsByGroup.hardware"
                v-if="updateErrorsByGroup.hardware && updateErrorsByGroup.hardware.length"
                class="mb-2"
            >{{ error.message }}</b-alert>
        </div>

        <div class="col-lg-7 offset-lg-1">
            <b-form-group
                class="mb-sm"
                label="Visual presentation"
            >
                <b-form-select v-model="values.presentation">
                    <b-form-select-option :value="null">Select an option</b-form-select-option>
                    <b-form-select-option value="webcam">Webcam that shows my face</b-form-select-option>
                    <b-form-select-option value="vtuber">Animated Avatar (VTuber)</b-form-select-option>
                    <b-form-select-option value="nocam">My face is not shown on camera</b-form-select-option>
                </b-form-select>
            </b-form-group>

            <b-form-group
                class="m-0"
                label="Which platforms do you produce content from?"
            >
                <b-form-checkbox
                    v-model="values.consoles.pc"
                    :value="true"
                    :unchecked-value="false"
                >
                    PC
                </b-form-checkbox>

                <b-form-checkbox
                    v-model="values.consoles.xbox_series_x"
                    :value="true"
                    :unchecked-value="false"
                >
                    Xbox Series X/S
                </b-form-checkbox>

                <b-form-checkbox
                    v-model="values.consoles.playstation_5"
                    :value="true"
                    :unchecked-value="false"
                >
                    Playstation 5
                </b-form-checkbox>

                <b-form-checkbox
                    v-model="values.consoles.nintendo_switch"
                    :value="true"
                    :unchecked-value="false"
                >
                    Nintendo Switch
                </b-form-checkbox>

                <b-form-checkbox
                    v-model="values.consoles.retro"
                    :value="true"
                    :unchecked-value="false"
                >
                    Retro
                </b-form-checkbox>

                <b-form-checkbox
                    v-model="values.consoles.mobile"
                    :value="true"
                    :unchecked-value="false"
                >
                    Mobile
                </b-form-checkbox>
            </b-form-group>
        </div>
    </div>

    <div class="row pt-md mt-md border-top">
        <div class="col-lg-4">
            <h5 class="h-5">Preferences</h5>
            <p>Help us bring the right opportunities to you.</p>

            <b-alert
                variant="danger"
                show
                v-for="error in updateErrorsByGroup.preferences"
                v-if="updateErrorsByGroup.preferences && updateErrorsByGroup.preferences.length"
                class="mb-2"
            >{{ error.message }}</b-alert>
        </div>

        <div class="col-lg-7 offset-lg-1">
            <b-form-group
                class="m-0"
                label="Types of opportunities I am open to"
            >
                <b-form-checkbox
                    v-model="values.deals.games"
                    :value="true"
                    :unchecked-value="false"
                >
                    Video games (new releases, DLC, etc.)
                </b-form-checkbox>

                <b-form-checkbox
                    v-model="values.deals.brands"
                    :value="true"
                    :unchecked-value="false"
                >
                    Brand deals (food, beverages, etc.)
                </b-form-checkbox>

                <b-form-checkbox
                    v-model="values.deals.podcast"
                    :value="true"
                    :unchecked-value="false"
                >
                    Podcast appearances (Streamforge Radio, etc.)
                </b-form-checkbox>

                <b-form-checkbox
                    v-model="values.deals.steam_broadcasts"
                    :value="true"
                    :unchecked-value="false"
                >
                    Steam broadcasts (streaming directly on developer store pages)
                </b-form-checkbox>
            </b-form-group>
        </div>
    </div>

    <div class="d-flex mt-lg">
        <b-button variant="primary" @click="onSave" size="lg" class="ml-auto px-5">Save</b-button>
    </div>
</div>
</template>

<script type="text/javascript">
    import _ from 'lodash'
    import moment from 'moment'
    import { mapGetters } from 'vuex'

    export default {
        name: 'form-resume-settings',

        props: {
            resume: {
                type: Object,
            },
            updateErrors: {
                type: Array,
            },
        },

        data() {
            return {
                defaults: {
                    business_email: null,
                    language: null,
                    country: null,
                    region: null,
                    birthday: null,

                    gender: null,
                    gender_self_describe: null,
                    pronouns: null,
                    pronouns_self_describe: null,

                    presentation: null,

                    interests: [],
                    attributes: {
                        humor: 0,
                        skill: 0,
                        informative: 0,
                    },
                    deals: {
                        brands: null,
                        games: null,
                    },
                    consoles: {
                        pc: null,
                        xbox_series_x: null,
                        playstation_5: null,
                        nintendo_switch: null,
                        retro: null,
                        mobile: null,
                    },
                },

                values: null,

                settings: {
                    attributes: {
                        budget: 15,
                    },
                },

                groups: {
                    contact: ['business_email'],
                    demographics: ['language', 'country', 'region', 'birthday'],
                    identity: ['gender', 'gender_self_describe', 'pronouns', 'pronouns_self_describe'],
                    personality: ['attributes', 'interests'],
                    hardware: ['presentation', 'consoles'],
                },

                options: {
                    interests: [
                        'food', 'fashion', 'beauty', 'finance',
                        'fitness', 'outdoors', 'music', 'technology',
                        'sports', 'tabletop',
                    ],
                },
            }
        },

        watch: {
            // values: {
            //     deep: true,
            //     handler(newValue, oldValue) {
            //         this.$emit('updated', newValue)
            //     },
            // },

            resume: {
                deep: true,
                handler(newValue, oldValue) {
                    if (newValue) this.mergeResumeWithDefaults()
                },
            }
        },

        created() {
            this.setDefaults()

            if (this.resume) {
                this.mergeResumeWithDefaults()
            }
        },

        computed: {
            ...mapGetters([
                'dictionary',
                'getFromDictionary',
            ]),

            allocatedAttributePoints() {
                return _.reduce(this.values.attributes, (result, points, key) => {
                    result += points
                    return result
                }, 0)
            },

            updateErrorsByGroup() {
                const updateErrorsByKey = _.keyBy(this.updateErrors, 'field')

                return _.mapValues(this.groups, (fields) => {
                    return _.reduce(fields, (results, fieldKey) => {
                        if (updateErrorsByKey[fieldKey]) {
                            results.push(updateErrorsByKey[fieldKey])
                        }

                        return results
                    }, [])
                })
            },
        },

        methods: {
            setDefaults() {
                this.values = _.cloneDeep(this.defaults)
            },

            mergeResumeWithDefaults() {
                this.values = _.assign(_.cloneDeep(this.defaults), this.resume)

                // if (this.resume.birthday) {
                //     this.values.birthday = moment(this.resume.birthday).format('YYYY-MM-DD')
                // }
            },

            onSave() {
                this.$emit('updated', this.values)
            },

            assignAttributePoint(attributeKey, action) {
                if (action === 'add') {
                    if (this.allocatedAttributePoints >= this.settings.attributes.budget) return
                    this.values.attributes[attributeKey]++
                } else if (action === 'subtract') {
                    if (this.values.attributes[attributeKey] === 0) return
                    this.values.attributes[attributeKey]--
                }
            },

            isInterestSelected(key) {
                return this.values.interests.includes(key)
            },

            toggleInterest(key) {
                if (this.isInterestSelected(key)) {
                    this.values.interests = _.filter(this.values.interests, interest => interest !== key)
                    return
                }

                if (this.values.interests.length >= 3) {
                    return
                    // this.values.interests.shift()
                }

                this.values.interests.push(key)
            },
        }
    }
</script>

<style lang="scss">
.form-resume-settings {

    .border-top {
        border-top: 1px solid rgba(white, 0.1) !important;
    }

    .custom-select, .form-control {
        border-color: #221F29;
    }

    .attributes-wrapper {
        .points-left {
            line-height: 20px;
            font-size: 14px;
        }

        .attribute {
            h5 {
                font-size: 14px;
                line-height: 16px;
                margin: 0;
                width: 30%;
            }

            .actions {
                width: 20%;
                justify-content: flex-end;

                svg {
                    transition: 0.25s all;
                    color: #fff;
                    cursor: pointer;
                    -webkit-touch-callout: none; /* iOS Safari */
                    -webkit-user-select: none; /* Safari */
                     -khtml-user-select: none; /* Konqueror HTML */
                       -moz-user-select: none; /* Old versions of Firefox */
                        -ms-user-select: none; /* Internet Explorer/Edge */
                            user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */

                    &:hover {
                        color: #FCD878;
                    }
                }
            }
        }

        .circles {
            justify-content: space-between;
            width: 50%;

            .circle {
                position: relative;
                margin: auto 0;
                height: 14px;
                width: 14px;
                border: 2px solid rgba(white, 0.1);
                border-radius: 50%;

                &.active {
                    border: 2px solid rgba(#FCD878, 0.3);

                    &:before {
                        content: '';
                        position: absolute;
                        top: 2px;
                        left: 2px;
                        width: 6px;
                        height: 6px;
                        background: #FCD878;
                        border-radius: 50%;
                    }
                }
            }
        }
    }

    .badges-toggleable {
        font-size: 1.5em;

        .badge {
            transition: 0.25s all;
            cursor: pointer;
            text-transform: capitalize;

            &:hover {
                background: #fcd878;
                color: #000;
            }
        }

        .badge-secondary {
            background-color: #000;
        }
    }
}
</style>
