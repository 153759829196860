<template>
<div class="card-task">
    <div class="panel p-3" v-if="task">
        <div class="task-header">
            <small>Task #{{ index + 1 }}</small>
            <p v-if="task.type === 'custom-text'" class="font-weight-bold lead mb-1 mt-0">{{ task.payload.title }}</p>
            <p v-else class="font-weight-bold lead mb-1 mt-0">{{ getTaskProperty(task.type, 'display_name') }}</p>
        </div>

        <div class="task-body" v-if="task.type === 'twitch-stream'">
            <p>Please make sure that you have your game category set correctly when going live.</p>

            <p class="mt-3 mb-0 font-weight-bold">Details</p>
            <div class="d-flex" v-if="task.payout_amount">
                <p class="m-0 w-50">Payout Amount</p>
                <p class="m-0 w-50 text-right">${{ task.payout_amount | numeral('0,0.00') }}</p>
            </div>

            <div class="d-flex" v-if="task.payload.game">
                <p class="m-0 w-50">Category</p>
                <p class="m-0 w-50 text-right">
                    <a target="blank" :href="`https://www.twitch.tv/directory/game/${task.payload.game.name}`">
                        {{ task.payload.game.name }}
                    </a>
                </p>
            </div>

            <div class="d-flex" v-if="task.payload.start_at">
                <p class="m-0 w-50">Start At</p>
                <p class="m-0 w-50 text-right">{{ task.payload.start_at | moment('calendar') }}</p>
            </div>

            <div class="d-flex" v-if="task.payload.duration_seconds">
                <p class="m-0 w-50">Duration</p>
                <p class="m-0 w-50 text-right">{{ toHumanDuration(task.payload.duration_seconds) }}</p>
            </div>
        </div>

        <div class="task-body" v-if="task.type === 'retrieve-key'">
            <p v-if="keyGroup">
                Type: {{ keyGroup.title }}
            </p>

            <p>Please retrieve a key using the button below.</p>

            <div class="d-flex mt-2">
                <b-button variant="primary" class="ml-auto" @click.prevent="onKeyModalClick">
                    <span v-if="task.payload && task.payload.key_id">View Key</span>
                    <span v-else>Get Key</span>
                </b-button>
            </div>
        </div>

        <div class="task-body" v-if="task.type === 'twitch-panel'">
            <p>Make sure to add the following panel image and link to your Twitch page.</p>

            <b-form-group label="Attribution Link" description="Please set this as the link for your panel image.">
                <b-form-input type="url" :value="`${clickthroughUrl}/${link.path}`" />
            </b-form-group>

            <b-form-group class="mt-2" label="Panel Image" description="Save this image and upload to it Twitch as a panel image.">
                <a :href="campaign.payload.attribution.panel_image_url">
                    <img :src="campaign.payload.attribution.panel_image_url" class="w-100">
                </a>
            </b-form-group>
        </div>

        <div v-if="task.notes">
            <p class="mt-3 mb-0 font-weight-bold">Notes</p>
            <p class="mb-0 text-break" v-html="task.notes" v-linkified />
        </div>
    </div>
</div>
</template>

<script type="text/javascript">
import {
    mapGetters
} from 'vuex'

export default {
    props: {
        task: {
            type: Object,
        },
        index: {
            type: Number,
        },
        campaign: {
            type: Object,
        },
        link: {
            type: Object,
        },
    },

    data() {
        return {
            taskTypes: {
                'twitch-stream': {
                    display_name: 'Twitch Stream',
                },
                'twitch-panel': {
                    display_name: 'Twitch Panel',
                },
                'retrieve-key': {
                    display_name: 'Retrieve Key',
                },
            },
            platformNameToAbbreviation: {
                twitch: 'tw',
                youtube: 'yt',
                twitter: 'tt',
                instagram: 'ig',
                tiktok: 'tk',
                facebook: 'fb',
            },
            clickthroughUrl: process.env.VUE_APP_CLICKTHROUGH_URL,
        }
    },

    computed: {
        keyGroup() {
            if (!this.task.payload || !this.task.payload.group_id) return false
            const keyGroups = _.get(this.campaign, 'game.key_groups')
            if (!keyGroups || _.size(keyGroups) === 0) return false
            const keyGroup = _.find(keyGroups, item => item.id === this.task.payload.group_id)
            if (!keyGroup) return false
            return keyGroup
        },
    },

    methods: {
        getTaskProperty(type, property) {
            const taskType = this.taskTypes[type]

            if (taskType) {
                return taskType[property]
            }

            return `---`
        },

        toHumanDuration(durationSeconds) {
            const hours = Math.floor(durationSeconds / (60 * 60))
            const minutes = Math.round((durationSeconds % (60 * 60)) / 60)

            let result = []
            if (hours > 0) result.push(`${hours}h`)
            if (minutes > 0) result.push(`${minutes}m`)
            return result.join(' ')
        },

        onKeyModalClick() {
            this.$emit('task-action', {
                taskId: this.task.id,
                campaign: this.campaign,
                keyId: this.task.payload ? this.task.payload.key_id : null,
            })
        },
    }
}
</script>

<style lang="scss">
.card-task {
    .text-break {
        word-wrap: break-word;
    }
}
</style>
