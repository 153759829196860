import Vue from 'vue'
import moment from 'moment'

export default {
    state: {
        modal: {
            id: null,
            type: null,
            visible: false,
            title: null,
            author: null,
            content: null,
            read_timeout: null,
            answer: null
        },

        updated_at: moment().format()
    },

    mutations: {
        'modal:set'(state, { modal }) {
            Vue.set(state, 'updated_at', moment().format())
            Vue.set(state, 'modal', modal)
        },

        'modal:clear'(state) {
            Vue.set(state, 'modal', {
                id: null,
                type: null,
                visible: false,
                title: null,
                author: null,
                content: null,
                read_timeout: null,
                answer: null
            })
        }
    },

    getters: {
        modal(state) {
            return state.modal
        }
    }
}
