<template>
    <div class="hire-modal-wrapper">
        <b-modal
            id="hire-modal"
            @ok="onSubmit"
            @hidden="onHidden"
            @show="onShow"
            :busy="true"
            ok-title="Submit"
            ok-only
            :ok-disabled="state.isOkayDisabled"
            :modal-class="[state.step]"
        >
            <template #modal-title>
                <h1 class="h4 m-0">Hire {{ meta.display_name }}</h1>
            </template>

            <template #modal-footer="{ ok, close }">
                <b-button variant="primary" @click="ok()" v-if="state.step === 'form'">
                    Submit
                </b-button>
                <b-button variant="primary" @click="close()" v-if="state.step ==='submitted'">
                    Return to profile
                </b-button>
            </template>

            <b-form @submit.stop.prevent="onSubmit">
                <div class="hire-form-wrapper" v-if="state.step === 'form'">
                    <div class="row">
                        <div class="col-lg-12 mb-4">
                            <div class="d-flex">
                                <div class="creator-avatar">
                                    <div class="avatar avatar-lg">
                                        <img v-lazy="meta.logo_url">
                                    </div>
                                </div>

                                <div class="creator-description ml-3 my-auto d-flex">
                                    <p class="m-0 my-auto">Please complete the form below to contact {{ meta.display_name }} for business opportunities.</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12">
                            <div class="row row-panels">
                                <div class="col-lg-6">
                                    <b-form-group
                                        label="First name"
                                    >
                                        <b-form-input
                                            type="text"
                                            v-model="values.firstname"
                                            required
                                            placeholder="Enter your first name"
                                            :state="errors.firstname.is_valid"
                                        />
                                        <b-form-invalid-feedback :state="errors.firstname.is_valid">
                                            {{ errors.firstname.message }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </div>

                                <div class="col-lg-6">
                                    <b-form-group
                                        label="Last Name"
                                    >
                                        <b-form-input
                                            type="text"
                                            v-model="values.lastname"
                                            required
                                            placeholder="Enter your last name"
                                            :state="errors.lastname.is_valid"
                                        />
                                        <b-form-invalid-feedback :state="errors.lastname.is_valid">
                                            {{ errors.lastname.message }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </div>

                                <div class="col-lg-12">
                                    <b-form-group
                                        label="Company Name"
                                    >
                                        <b-form-input
                                            type="text"
                                            v-model="values.company"
                                            required
                                            placeholder="Enter your company name"
                                            :state="errors.company.is_valid"
                                        />
                                        <b-form-invalid-feedback :state="errors.company.is_valid">
                                            {{ errors.company.message }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>

                                    <b-form-group
                                        label="Email address"
                                    >
                                        <b-form-input
                                            v-model="values.email"
                                            type="email"
                                            placeholder="Enter email address"
                                            required
                                            :state="errors.email.is_valid"
                                        />
                                        <b-form-text v-if="errors.email.is_valid === null">
                                            {{ getFromDictionary(`resume.hire_modal.email`, 'description') }}
                                        </b-form-text>
                                        <b-form-invalid-feedback :state="errors.email.is_valid">
                                            {{ errors.email.message }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>

                                    <b-form-group
                                        label="Message"
                                    >
                                        <b-form-textarea
                                            v-model="values.message"
                                            placeholder="Enter something..."
                                            rows="3"
                                            max-rows="6"
                                            :state="errors.message.is_valid"
                                        />
                                        <b-form-text v-if="errors.email.is_valid === null">
                                            {{ getFromDictionary(`resume.hire_modal.message`, 'description') }}
                                        </b-form-text>
                                        <b-form-invalid-feedback :state="errors.message.is_valid">
                                            {{ errors.message.message }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="submitted-wrapper" v-if="state.step === 'submitted'">
                    <div class="d-flex my-sm">
                        <div class="mx-auto">
                            <span class="fa-stack fa-5x checkmark">
                                <font-awesome-icon class="text-success fa-stack-2x checkmark-bg" :icon="['fas', 'circle']" />
                                <font-awesome-icon class="text-white fa-stack-1x checkmark-fg" :icon="['fas', 'check']" />
                            </span>
                        </div>
                    </div>

                    <p class="m-0">Thank you for expressing interest in hiring this creator. We will reach out shortly.</p>
                </div>
            </b-form>
        </b-modal>
    </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'

export default {
    name: 'hire-modal',

    props: {
        superview: {
            required: true,
        },
        platform_id: {
            required: true,
        },
    },

    data() {
        return {
            meta: {
                display_name: '',
                logo_url: '',
            },

            state: {
                step: 'form',
                isOkayDisabled: false,
            },

            values: {},

            errors: {},

            resetEvent: null,
        }
    },

    watch: {
        superview() {
            this.setPlatformMeta()
        },
    },

    computed: {
        ...mapGetters([
            'dictionary',
            'getFromDictionary',
        ]),

        // validation() {
        //     const result = {
        //         firstname: true,
        //         lastname: true,
        //         company: true,
        //         email: true,
        //         message: true,
        //     }
        //
        //     const isEmpty = (input) => input && input.length > 0
        //     const isShortTextLong = (input) => input && input.length > 32
        //     const isEmail =
        // },
    },

    created() {
        this.reset()
        this.setPlatformMeta()
    },

    methods: {
        reset() {
            const fields = ['firstname', 'lastname', 'company', 'email', 'message']

            this.values = _.reduce(fields, (result, key) => {
                result[key] = ''
                return result
            }, {})

            this.errors = _.reduce(fields, (result, key) => {
                result[key] = {
                    is_valid: null,
                    message: null,
                }
                return result
            }, {})
        },

        onSubmit(event) {
            // this.$emit('submit', this.values)
            event.preventDefault()
            this.state.isOkayDisabled = true

            const portalId = `6510159`
            const formId = `d1585b9c-574c-45b8-9a0f-efd1d68d1453`

            this.axios.post(`https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`, {
                "fields": [{
                    "objectTypeId": "0-1",
                    "name": "email",
                    "value": this.values.email,
                }, {
                    "objectTypeId": "0-1",
                    "name": "firstname",
                    "value": this.values.firstname,
                }, {
                    "objectTypeId": "0-1",
                    "name": "lastname",
                    "value": this.values.lastname,
                }, {
                    "objectTypeId": "0-1",
                    "name": "company",
                    "value": this.values.company,
                }, {
                    "objectTypeId": "0-1",
                    "name": "message",
                    "value": this.values.message,
                }],
                "context": {
                    "pageUri": window.location.href,
                    "pageName": document.title,
                },
                "legalConsentOptions": {
                    "consent": { // Include this object when GDPR options are enabled
                        "consentToProcess": true,
                        "text": "I agree to allow Streamforge to store and process my personal data.",
                        "communications": [{
                            "value": true,
                            "subscriptionTypeId": 999,
                            "text": "I agree to receive communications from Streamforge."
                        }]
                    }
                },
            }).then(({ data }) => {
                this.state.step = 'submitted'
                this.reset()
                this.state.isOkayDisabled = false

            }).catch((error) => {
                this.state.isOkayDisabled = false
                console.error(`Error submitting hire form`, error.response)

                const errorMessages = _.get(error, 'response.data.errors')

                if (errorMessages && errorMessages.length) {
                    _.forEach(errorMessages, (error) => {
                        if (error.errorType === 'REQUIRED_FIELD') {
                            _.forEach(this.errors, (value, key) => {
                                if (error.message.includes(`fields.${key}`)) {
                                    const label = this.getFromDictionary(`resume.hire_modal.${key}`, 'label')
                                    this.errors[key].is_valid = false
                                    this.errors[key].message = `${label} field is required.`
                                }
                            })
                        }

                        if (error.errorType === 'INVALID_EMAIL') {
                            this.errors['email'].is_valid = false
                            this.errors['email'].message = `Invalid email provided.`
                        }
                    })
                }
            })
        },

        onHidden(event) {
            this.state.step = 'form'
            history.pushState('', '', window.location.pathname)
        },

        onShow(event) {

        },

        setPlatformMeta() {
            if (!this.superview) {
                this.meta = {
                    display_name: 'Creator',
                    logo_url: null,
                }

                return
            }

            if (this.platform_id === 'twitch') {
                this.meta.display_name = this.superview.channel.display_name
                this.meta.logo_url = this.superview.channel.logo
            }

            if (this.platform_id === 'youtube') {
                this.meta.display_name = this.superview.channel.snippet.title
                this.meta.logo_url = this.superview.channel.snippet.thumbnails.medium.url
            }
        },
    },
}
</script>

<style lang="scss">
#hire-modal {
    // &.submitted {
    //     .modal-footer {
    //         display: none;
    //     }
    // }

    .modal-header .close {
        color: #fff;
    }

    .hire-form-wrapper {
        textarea {
            border: 1px solid #12141d;
        }
    }

    .modal-footer .btn {
        width: 100%;
    }

    .checkmark {
        .checkmark-bg {
            animation: stacked-icon-bubble-bg 1s cubic-bezier(1.000, 0.008, 0.565, 1.650) forwards;
        }

        .checkmark-fg {
            animation: stacked-icon-bubble-fg 1s cubic-bezier(0.895, 0.030, 0.685, 0.220) forwards;
        }
    }
}

</style>
