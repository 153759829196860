import {
    library
} from '@fortawesome/fontawesome-svg-core'

// import {
//     faEye,
//     faAngleLeft,
//     faAngleRight,
//     faTh,
//     faBars,
//     faTimes,
//     faFeatherAlt,
//     faUser,
//     faCircleNotch,
//     faBell,
//     faChevronDown,
//     faCaretUp,
//     faCaretRight,
//     faCaretDown,
//     faCaretLeft,
//     faExclamation,
//     faChartLine,
//     faMicrophoneAlt,
//     faDoorOpen,
//     faExternalLinkAlt,
//     faShieldAlt,
//     faSignOutAlt,
//     faUserCog,
//     faSearch,
//     faThumbsUp,
//     faThumbsDown,
//     faCog,
//     faTools,
//     faBolt,
//     faScroll,
//     faAngleDoubleUp,
//     faStreetView,
//     faStopwatch,
//     faInfoCircle,
//     faComments,
//     faGamepad,
//     faRedo,
//     faArrowLeft,
//     faArrowDown,
//     faEllipsisH,
//     faGlobe,
//     faUndo,
//     faSave,
//     faEraser,
//     faUserCircle,
//     faLock,
//     faCalendarAlt,
//     faCrown,
//     faUserPlus,
//     faCheck,
//     faSitemap,
//     faTrophy,
//     faFire,
//     faUserSecret,
//     faQuestion,
//     faDungeon,
//     faMountain,
//     faUserClock,
//     faHome,
//     faExternalLinkSquareAlt,
//     faCertificate,
//     faFilm,
//     faCircle,
//     faPlay,
//     faExpand,
//     faDesktop,
//     faGlobeEurope,
//     faGlobeAmericas,
//     faGlobeAfrica,
//     faGlobeAsia,
//     faCopy,
//     faTags,
//     faKey,
//     faUsers,
//     faStore,
//     faTasks,
//     faChartBar,
//     faPlug,
//     faThLarge,
//     faCompass,
//     faTrashAlt,
//     faEnvelopeOpen,
//     faEnvelopeOpenText,
//     faGraduationCap,
//     faLink,
//     faArrowRight,
//     faChevronUp,
//     faStar,
//     faHandshake,
//     faTicketAlt,
//     faGifts,
//     faCommentDots,
//     faQuestionCircle,
//     faVolumeUp,
//     faVolumeMute,
//     faChevronRight,
//     faChevronLeft,
//     faStream,
//     faRssSquare,
//     faHeart,
//     faPlus,
//     faGripLines,
//     faPen,
//     faAddressCard,
//     faBook,
//     faEdit,
// } from '@fortawesome/free-solid-svg-icons'

// import {
//     faDiscord,
//     faTwitter,
//     faInstagram,
//     faYoutube,
//     faTwitch,
//     faPlaystation,
//     faXbox,
//     faSteam,
//     faPaypal,
// } from '@fortawesome/free-brands-svg-icons'

import {
    FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'
// require('../assets/vendor/font-awesome/css/font-awesome.min.css')

// library.add(
//     faDiscord,
//     faTwitter,
//     faInstagram,
//     faYoutube,
//     faTwitch,
//     faEye,
//     faAngleLeft,
//     faAngleRight,
//     faTh,
//     faBars,
//     faTimes,
//     faFeatherAlt,
//     faUser,
//     faCircleNotch,
//     faExclamation,
//     faBell,
//     faChevronDown,
//     faCaretUp,
//     faCaretRight,
//     faCaretDown,
//     faCaretLeft,
//     faChartLine,
//     faMicrophoneAlt,
//     faDoorOpen,
//     faExternalLinkAlt,
//     faShieldAlt,
//     faSignOutAlt,
//     faUserCog,
//     faSearch,
//     faThumbsUp,
//     faThumbsDown,
//     faCog,
//     faTools,
//     faBolt,
//     faScroll,
//     faAngleDoubleUp,
//     faStreetView,
//     faStopwatch,
//     faInfoCircle,
//     faComments,
//     faGamepad,
//     faRedo,
//     faArrowLeft,
//     faArrowDown,
//     faEllipsisH,
//     faGlobe,
//     faUndo,
//     faSave,
//     faEraser,
//     faUserCircle,
//     faLock,
//     faCalendarAlt,
//     faCrown,
//     faUserPlus,
//     faCheck,
//     faSitemap,
//     faTrophy,
//     faFire,
//     faUserSecret,
//     faQuestion,
//     faDungeon,
//     faMountain,
//     faUserClock,
//     faCircle,
//     faHome,
//     faExternalLinkSquareAlt,
//     faCertificate,
//     faFilm,
//     faPlay,
//     faExpand,
//     faPlaystation,
//     faXbox,
//     faDesktop,
//     faSteam,
//     faGlobeEurope,
//     faGlobeAmericas,
//     faGlobeAfrica,
//     faGlobeAsia,
//     faCopy,
//     faTags,
//     faKey,
//     faUsers,
//     faStore,
//     faTasks,
//     faChartBar,
//     faPlug,
//     faThLarge,
//     faCompass,
//     faTrashAlt,
//     faEnvelopeOpen,
//     faEnvelopeOpenText,
//     faGraduationCap,
//     faLink,
//     faArrowRight,
//     faChevronUp,
//     faStar,
//     faHandshake,
//     faTicketAlt,
//     faGifts,
//     faCommentDots,
//     faQuestionCircle,
//     faVolumeUp,
//     faVolumeMute,
//     faChevronRight,
//     faChevronLeft,
//     faStream,
//     faRssSquare,
//     faHeart,
//     faPlus,
//     faGripLines,
//     faPen,
//     faAddressCard,
//     faBook,
//     faPaypal,
//     faEdit,
// )

import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'

library.add(
    fas,
    fab,
)

export default {
    install(Vue) {
        Vue.component('font-awesome-icon', FontAwesomeIcon)
        Vue.config.productionTip = false
    }
}
