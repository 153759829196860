import Vue from 'vue'
import _ from 'lodash'

export default {
    state: {
        ranks: {
            lookup: {},
            ordered: [],
        },
    },

    mutations: {
        'thresholds:ranks'(state, { ranks }) {
            Vue.set(state.ranks, 'lookup', _.keyBy(ranks, 'name'))
            Vue.set(state.ranks, 'ordered', ranks)
        },
    },

    getters: {
        ranksThresholds: state => state.ranks,

        nextRankThreshold: state => (currentRankIndex) => {
            if (currentRankIndex === 0) {
                return _.first(state.ranks.ordered)
            }

            return state.ranks.ordered[currentRankIndex - 1]
        },
    }
}
