<template>
    <div>
        <!-- Notification Manager -->
    </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
    name: 'notification-manager',

    computed: {
        ...mapGetters([
            'isAuthenticated'
        ])
    },

    data() {
        return {
            intervals: {
                getNotifications: {
                    timeout: 1000 * 60 * 15,
                    reference: null
                }
            }
        }
    },

    created() {
        // this.bootstrap()
    },

    watch: {
        // isAuthenticated(value) {
        //     if (value === true) {
        //         this.bootstrap()
        //     }
        //
        //     if (value === false) {
        //         this.clear()
        //         this.$store.commit()
        //     }
        // }
    },

    methods: {
        bootstrap() {
            this.clear()
            this.getNotifications()
            this.intervals.getNotifications.reference = setInterval(this.getNotifications, this.intervals.getNotifications.timeout)
        },

        clear() {
            if (this.intervals.getNotifications.reference) clearInterval(this.intervals.getNotifications.reference)
        },

        getNotifications() {
            return this.axios.get(`/notifications`).then((response) => {
                this.$store.commit('notifications:set', { notifications: response.data.payload })
            }).catch((error) => {
                console.log(`[Notification Manager]: Failed to retrieve notifications: `, error.message)
            })
        },
    }
}
</script>
