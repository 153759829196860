export default {
    state: {
        default: '#eeeeee',
        inactive: '#909090',

        ranks: {
            bronze: '#d7995b',
            silver: '#aaaaaa',
            gold: '#d7b740',
            platinum: '#b1d9df',
            ruby: '#ff7e79',
            emerald: '#74f695',
            sapphire: '#4499ff',
            diamond: '#98c2ff'
        },

        socials: {
            twitch: '#6441a5',
            twitter: '#38A1F3',
            instagram: '#bc2a8d',
            youtube: '#ff0000',
            discord: '#7289da',
            paypal: '#00457c',
        }
    },

    getters: {
        getColor: (state) => (type, item) => {
            if (!state[type] || !state[type][item]) return state.default
            return state[type][item]
        },

        getColors: (state) => {
            return state
        },

        colors: state => state,

        getRankByIndex: (state) => (index) => {
            const ranks = _.map(state.ranks, (color, name) => {
                return {
                    name,
                    color,
                }
            }).reverse()

            return ranks[index]
        },
    }
}
