var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"panel panel-campaign h-100"},[(_vm.getCampaignBackground(_vm.campaign))?_c('div',{staticClass:"panel-background",style:({
            'background-image': ("url(" + (_vm.getCampaignBackground(_vm.campaign)) + ")")
        })}):_vm._e(),_c('div',{staticClass:"panel-foreground p-3 h-100"},[_c('h2',{staticClass:"display-4 mb-0"},[_vm._v(_vm._s(_vm.campaign.game.name))]),_c('div',{staticClass:"tag-labels"},[(_vm.campaign.supercampaign)?_c('span',{staticClass:"label genre"},[_vm._v(" "+_vm._s(_vm.campaign.supercampaign.stage)+" ")]):_vm._e(),_c('span',{staticClass:"label genre"},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":_vm.getFromDictionary(("campaign.types." + (_vm.campaign.type)), 'icon')}}),_vm._v(" "+_vm._s(_vm.getFromDictionary(("campaign.types." + (_vm.campaign.type)), 'label'))+" ")],1)]),(_vm.campaign.supercampaign)?_c('p',{staticClass:"mt-3 mb-md"},[_vm._v(_vm._s(_vm.campaign.supercampaign.description))]):_vm._e(),_c('div',{staticClass:"d-flex mt-auto"},[_c('router-link',{staticClass:"btn btn-primary ml-auto",attrs:{"to":{
                name: 'campaign',
                params: {
                    id: _vm.campaign.id
                }
            }}},[_vm._v(" View ")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }