// import Badge from "../components/Base/Badge"
// import BaseAlert from "../components/Base/BaseAlert"
import BaseButton from "../components/Base/BaseButton"
// import BaseCheckbox from "../components/Base/BaseCheckbox"
// import BaseInput from "../components/Base/BaseInput"
// import BasePagination from "../components/Base/BasePagination"
// import BaseProgress from "../components/Base/BaseProgress"
// import BaseRadio from "../components/Base/BaseRadio"
// import BaseSlider from "../components/Base/BaseSlider"
// import BaseSwitch from "../components/Base/BaseSwitch"
// import Card from "../components/Base/Card"
// import Icon from "../components/Base/Icon"
import JsonView from 'vue-json-component'
import CountryRegionSelect from 'vue-country-region-select'

// Vue Swiper
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'

// Twitch Player Embed
// import TwitchPlayer from 'vue-twitch-player'

// Content Editable
// import ContentEditable from 'vue-contenteditable/src/contenteditable.vue'


export default {
    install(Vue) {
        // Vue.component(Badge.name, Badge)
        // Vue.component(BaseAlert.name, BaseAlert)
        Vue.component(BaseButton.name, BaseButton)
        // Vue.component(BaseInput.name, BaseInput)
        // Vue.component(BaseCheckbox.name, BaseCheckbox)
        // Vue.component(BasePagination.name, BasePagination)
        // Vue.component(BaseProgress.name, BaseProgress)
        // Vue.component(BaseRadio.name, BaseRadio)
        // Vue.component(BaseSlider.name, BaseSlider)
        // Vue.component(BaseSwitch.name, BaseSwitch)
        // Vue.component(Card.name, Card)
        // Vue.component(Icon.name, Icon)
        Vue.component('swiper', swiper)
        Vue.component('swiper-slide', swiperSlide)
        // Vue.component('twitch-player', TwitchPlayer)
        // Vue.component('content-editable', ContentEditable)
        Vue.use(JsonView)
        Vue.use(CountryRegionSelect)
    }
}
