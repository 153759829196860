import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'

// Layout
import UserSidebar from './layout/UserSidebar'
import UserFooter from './layout/UserFooter'

// Dashboard Pages
import DashboardWrapper from './views/dashboard/DashboardWrapper'
import HomeView from './views/HomeView'
import CampaignView from './views/CampaignView'
import AnalyticsView from './views/AnalyticsView'
import SettingsView from './views/SettingsView'
import ConnectionsView from './views/ConnectionsView'
import StreamCardsView from './views/StreamCardsView'
import CampaignInviteView from './views/CampaignInviteView'
import CreatorView from './views/CreatorView'
import ResumeView from './views/ResumeView'
import CampaignInquiryView from './views/CampaignInquiryView'

import SponsorshipsView from './views/campaign/SponsorshipsView'
import GameKeysView from './views/campaign/GameKeysView'
import SteamBroadcastsView from './views/campaign/SteamBroadcastsView'

// Informational Pages
import LandingView from './views/LandingView'

// Utility Pages
import LoginView from './views/authentication/LoginView'
import RegisterView from './views/authentication/RegisterView'
import AccountLinkView from './views/authentication/AccountLinkView'
import LogoutView from './views/authentication/LogoutView'

// Admin Pages
import AdminWrapper from './views/administration/AdminWrapper'
import AdminUserList from './views/administration/UserList'
import AdminUserView from './views/administration/UserView'

// Fallback Pages
import NotFoundView from './views/http-status/NotFoundView'


Vue.use(Router)
Vue.use(Meta)


const router = new Router({
    linkExactActiveClass: 'active',
    mode: 'history',

    routes: [
        {
            path: '/',
            name: 'landing',
            components: {
                default: LandingView
            },
            meta: {
                layout: 'fullscreen'
            }
        },

        {
            path: '/register',
            name: 'register',
            components: {
                default: RegisterView,
            },
            meta: {
                layout: 'fullscreen'
            }
        },

        {
            path: '/register/twitch/callback',
            name: 'register-twitch',
            components: {
                default: RegisterView,
            },
            meta: {
                layout: 'fullscreen'
            }
        },

        {
            path: '/register/youtube/callback',
            name: 'register-youtube',
            components: {
                default: RegisterView,
            },
            meta: {
                layout: 'fullscreen'
            }
        },

        {
            path: '/login',
            name: 'login',
            components: {
                default: LoginView,
            },
            meta: {
                layout: 'fullscreen'
            }
        },

        {
            path: '/login/twitch/callback',
            name: 'login-twitch',
            components: {
                default: LoginView,
            },
            meta: {
                layout: 'fullscreen'
            }
        },

        {
            path: '/login/youtube/callback',
            name: 'login-youtube',
            components: {
                default: LoginView,
            },
            meta: {
                layout: 'fullscreen'
            }
        },

        {
            path: '/link',
            name: 'link',
            components: {
                default: AccountLinkView,
            },
            meta: {
                layout: 'fullscreen'
            }
        },

        {
            path: '/link/twitch/callback',
            name: 'link-twitch',
            components: {
                default: AccountLinkView,
            },
            meta: {
                layout: 'fullscreen'
            }
        },

        {
            path: '/link/youtube/callback',
            name: 'link-youtube',
            components: {
                default: AccountLinkView,
            },
            meta: {
                layout: 'fullscreen'
            }
        },

        {
            path: '/link/twitter/callback',
            name: 'link-twitter',
            components: {
                default: AccountLinkView,
            },
            meta: {
                layout: 'fullscreen'
            }
        },

        {
            path: '/link/discord/callback',
            name: 'link-discord',
            components: {
                default: AccountLinkView,
            },
            meta: {
                layout: 'fullscreen'
            }
        },

        {
            path: '/link/paypal/callback',
            name: 'link-paypal',
            components: {
                default: AccountLinkView,
            },
            meta: {
                layout: 'fullscreen'
            }
        },

        {
            path: '/logout',
            name: 'logout',
            components: {
                default: LogoutView,
            },
            meta: {
                layout: 'fullscreen'
            }
        },

        {
            path: '/i/:invite_code',
            name: 'campaign-invite',
            components: {
                default: CampaignInviteView,
            },
            meta: {
                title: 'Campaign Invite',
                layout: 'fullscreen',
                authentication: false,
            },
        },

        {
            path: '/c/:participant_id',
            name: 'campaign-inquiry',
            components: {
                default: CampaignInquiryView,
            },
            meta: {
                title: 'Campaign',
                layout: 'fullscreen',
                authentication: false,
            },
        },



        /**
         * Dashboard
         */
        {
            path: '/',
            components: {
                header: UserSidebar,
                default: DashboardWrapper,
                footer: UserFooter
            },
            meta: {
                authentication: true,
                title: 'Dashboard'
            },
            children: [{
                path: 'home',
                name: 'home',
                component: HomeView,
                meta: {
                    title: 'Home'
                }
            }, {
                path: 'analytics',
                name: 'analytics',
                component: AnalyticsView,
                meta: {
                    title: 'Analytics'
                }
            }, {
                path: 'sponsorships',
                name: 'sponsorships',
                component: SponsorshipsView,
                meta: {
                    title: 'Sponsorships'
                }
            }, {
                path: 'game-keys',
                name: 'game-keys',
                component: GameKeysView,
                meta: {
                    title: 'Game Keys'
                }
            }, {
                path: 'steam-broadcasts',
                name: 'steam-broadcasts',
                component: SteamBroadcastsView,
                meta: {
                    title: 'Steam Broadcasts'
                }
            }, {
                path: 'campaigns/:id',
                name: 'campaign',
                component: CampaignView,
                meta: {
                    title: 'Campaign'
                }
            }, {
                path: 'settings',
                name: 'settings',
                component: SettingsView,
                meta: {
                    title: 'Settings'
                }
            }, {
                path: 'connections',
                name: 'connections',
                component: ConnectionsView,
                meta: {
                    title: 'Connections'
                }
            }, {
                path: 'streamcards',
                name: 'streamcards',
                component: StreamCardsView
            }, {
                path: 'connect',
                name: 'resume',
                component: ResumeView,
            }]
        },

        {
            path: '/admin',
            name: 'admin',
            components: {
                header: UserSidebar,
                default: AdminWrapper,
                footer: UserFooter
            },
            meta: {
                authentication: true,
                title: 'Admin'
            },
            children: [{
                path: 'users/:id',
                name: 'admin-user-single',
                component: AdminUserView
            }, {
                path: 'users',
                name: 'admin-user-list',
                component: AdminUserList
            }]
        }, {
            path: '/overview',
            name: 'dashboard-overview',
            redirect: {
                name: 'home'
            }
        }, {
            path: '/404',
            name: 'not-found',
            components: {
                default: NotFoundView,
            },
            meta: {
                layout: 'fullscreen'
            }
        },

        // Creator Profile Routes
        {
            path: '/twitch/:channel_name',
            name: 'creator-platform-twitch',
            components: {
                default: CreatorView,
            },
            meta: {
                layout: 'fullwidth',
                platform_id: 'twitch',
            },
        }, {
            path: '/youtube/:channel_name',
            name: 'creator-platform-youtube',
            components: {
                default: CreatorView,
            },
            meta: {
                layout: 'fullwidth',
                platform_id: 'youtube',
            },
        }, {
            path: '/:channel_name',
            name: 'creator-registered',
            components: {
                default: CreatorView,
            },
            meta: {
                layout: 'fullwidth'
            },
        },

        {
            path: '/*',
            name: 'catch-all',
            components: {
                default: NotFoundView,
            },
            meta: {
                layout: 'fullscreen'
            },
        }
    ],

    scrollBehavior: (to) => {
        if (to.hash) {
            return {
                selector: to.hash
            }
        } else {
            return {
                x: 0,
                y: 0
            }
        }
    }
})

const createRouter = (store) => {
    router.beforeEach((to, from, next) => {
        if (to.matched.some(m => m.meta.authentication) && !store.state.authentication.is_authenticated) {
            /*
             * If the user is not authenticated and visits
             * a page that requires authentication, redirect to the login page
             * but save the attempted path to localstorage so we can redirect
             * the user there after login
             */
            store.commit('authentication:redirect', to.fullPath)

            next({
                name: 'logout'
            })
        } else if (to.matched.some(m => m.meta.guest) && store.state.authentication.is_authenticated) {
            /*
             * If the user is authenticated and visits
             * an guest page, redirect to the dashboard page
             */
            next({
                name: 'logout'
            })
        } else {
            next()
        }

        let isDelegated = false

        for (let matched = (to.matched || []), i = matched.length; i--;) {
            let route = matched[i]

            if (route.beforeEnter) {
                isDelegated = true
                route.beforeEnter(to, from, next)
            }
        }

        !isDelegated && next()
    })

    return router
}

export default createRouter
