var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page page-account-link"},[_c('div',{staticClass:"spacer"}),_c('div',{staticClass:"authentication-loader"},[_c('transition',{attrs:{"name":"fade"}},[(!_vm.error)?_c('div',{staticClass:"icons-wrapper"},[_c('div',{staticClass:"icons"},[_c('div',{staticClass:"loading-icon",staticStyle:{"animation-delay":"0s"}},[_c('font-awesome-icon',{style:({
                                'color': ((_vm.colors['socials']['discord']) + " !important")
                            }),attrs:{"icon":['fab', 'discord'],"size":"8x"}})],1),_c('div',{staticClass:"loading-icon",staticStyle:{"animation-delay":"1s"}},[_c('font-awesome-icon',{style:({
                                'color': ((_vm.colors['socials']['twitch']) + " !important")
                            }),attrs:{"icon":['fab', 'twitch'],"size":"8x"}})],1),_c('div',{staticClass:"loading-icon",staticStyle:{"animation-delay":"2s"}},[_c('font-awesome-icon',{style:({
                                'color': ((_vm.colors['socials']['twitter']) + " !important")
                            }),attrs:{"icon":['fab', 'twitter'],"size":"8x"}})],1),_c('div',{staticClass:"loading-icon",staticStyle:{"animation-delay":"3s"}},[_c('font-awesome-icon',{style:({
                                'color': ((_vm.colors['socials']['youtube']) + " !important")
                            }),attrs:{"icon":['fab', 'youtube'],"size":"8x"}})],1),_c('div',{staticClass:"loading-icon",staticStyle:{"animation-delay":"4s"}},[_c('font-awesome-icon',{style:({
                                'color': ((_vm.colors['socials']['instagram']) + " !important")
                            }),attrs:{"icon":['fab', 'instagram'],"size":"8x"}})],1)])]):_vm._e()]),_c('transition',{attrs:{"name":"fade"}},[(_vm.error)?_c('div',{staticClass:"error text-center"},[_c('font-awesome-icon',{staticClass:"platform-icon mb-4",style:({
                        'color': ((_vm.getColor('socials', _vm.platform_id)) + " !important")
                    }),attrs:{"icon":['fab', _vm.platform_id],"size":"8x"}}),_c('h2',{staticClass:"display-4 mb-1"},[_vm._v("Error Linking Account")]),_c('p',{staticClass:"lead mt-3 mb-4"},[_vm._v("There was an error while linking your account. Please try again and if the error persists, please reach out to the support team using the chat bubble in the bottom right of your dashboard.")]),_c('div',{staticClass:"actions"},[_c('div',{staticClass:"action-wrapper"},[_c('a',{staticClass:"btn btn-follow btn-icon btn-primary",on:{"click":function($event){$event.preventDefault();return _vm.exit()}}},[_c('span',{staticClass:"btn-inner--icon mr-2"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'sign-out-alt'],"size":"lg"}})],1),_c('span',{staticClass:"nav-link-inner--text"},[_vm._v("Return")])])]),_c('div',{staticClass:"action-wrapper"},[_c('a',{staticClass:"btn btn-follow btn-icon btn-primary",on:{"click":function($event){$event.preventDefault();return _vm.retryUrl()}}},[_c('span',{staticClass:"btn-inner--icon mr-2"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'redo'],"size":"lg"}})],1),_c('span',{staticClass:"nav-link-inner--text"},[_vm._v("Retry")])])])])],1):_vm._e()])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }