<template>
    <footer class="footer pt-5 pb-3 mt-auto" :class="{'footer-full': $route.meta.layout && $route.meta.layout === 'fullwidth'}">
        <div class="legal">
            <a href="https://streamforge.com/terms-of-service" target="_blank">Terms of Service</a>
            <a href="https://streamforge.com/privacy-policy" target="_blank" class="ml-3">Privacy Policy</a>
            <a href="https://www.amcharts.com/" target="_blank" class="ml-3" v-if="chartPaths.includes($route.name)">Charts by AmCharts</a>
        </div>
    </footer>
</template>
<script>
export default {
    data() {
        return {
            chartPaths: [
                'streamcards'
            ]
        }
    }
}
</script>
<style lang="scss">
.footer {
    display: flex;
    position: relative;
    flex-direction: column;

    .legal {
        font-size: 0.8em;

        a {
            color: #bec2cc;
        }
    }
}

.footer-full {
    margin-left: 2.5vw;
}
</style>
