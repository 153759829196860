<template>
    <div class="panel panel-campaign h-100">
        <div
            class="panel-background"
            v-if="getCampaignBackground(campaign)"
            :style="{
                'background-image': `url(${getCampaignBackground(campaign)})`
            }"
        />

        <div class="panel-foreground p-3 h-100">
            <h2 class="display-4 mb-0">{{ campaign.game.name }}</h2>

            <div class="tag-labels">
                <span class="label genre" v-if="campaign.supercampaign">
                    {{ campaign.supercampaign.stage }}
                </span>

                <span class="label genre">
                    <font-awesome-icon :icon="getFromDictionary(`campaign.types.${campaign.type}`, 'icon')" class="mr-1" />
                    {{ getFromDictionary(`campaign.types.${campaign.type}`, 'label') }}
                </span>
            </div>

            <p class="mt-3 mb-md" v-if="campaign.supercampaign">{{ campaign.supercampaign.description }}</p>

            <div class="d-flex mt-auto">
                <router-link class="btn btn-primary ml-auto" :to="{
                    name: 'campaign',
                    params: {
                        id: campaign.id
                    }
                }">
                    View
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import {
    mapGetters
} from 'vuex'

export default {
    props: {
        campaign: {
            type: Object,
        },
    },

    computed: {
        ...mapGetters([
            'getFromDictionary',
        ]),
    },

    methods: {
        getCampaignBackground(campaign) {
            return _.get(campaign, 'payload.showcase.screenshots[0].url')
        },
    }
}
</script>

<style lang="scss" scoped>
.panel-campaign {
    position: relative;
    overflow: hidden;

    .panel-background {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-position: center;
        background-size: cover;
        z-index: -1;
    }

    .panel-foreground {
        background: linear-gradient(to top, rgba(30, 32, 41, 1) 0%, rgba(30, 32, 41, 0.95) 50%, rgba(30, 32, 41, 0.8) 100%);

        p {
            font-size: 1.05em;
            color: #ddd;
            max-height: 100px;
            overflow: hidden;
            -o-text-overflow: ellipsis;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
        }
    }

    .locked-wrapper {
        background: rgba(black, 0.9);
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }
}
</style>
