<template>
    <div class="slot-application-modal-wrapper" v-if="campaign && selectedSlot">
        <b-modal id="slot-application-modal" @ok="onSubmit" @hide="onHide">
            <template #modal-title>
                <h1 class="h4 m-0">Slot Application</h1>
            </template>

            <template #modal-footer="{ ok }">
                <b-button
                    v-if="type === 'create'"
                    variant="success"
                    @click="ok()"
                    :disabled="!values.is_time_confirmed || !values.is_punctuality_confirmed"
                >
                    Submit
                </b-button>
                <b-button
                    v-if="type === 'delete'"
                    variant="danger"
                    @click="ok()"
                >
                    Delete
                </b-button>
            </template>

            <form @submit.stop.prevent="onSubmit">
                <div class="row">
                    <div class="col-lg-12 mb-3" v-if="type === 'create'">
                        <p>You are hereby applying for the below selected time slot. If you are chosen, you will be notified. Times shown are <strong>{{ timezoneText }}</strong>.</p>
                    </div>

                    <div class="col-lg-12 mb-3" v-if="type === 'delete'">
                        <p>I confirm that I would like to withdraw my application for the following slot.</p>
                    </div>

                    <div class="col-lg-4 text-right">
                        <small class="font-weight-bold">Start</small>
                        <p class="m-0">{{ selectedSlot.start_at | moment('MM/DD hh:mm A') }}</p>
                    </div>

                    <div class="col-lg-3 d-flex">
                        <font-awesome-icon class="m-auto" :icon="['fas', 'arrow-right']" />
                    </div>

                    <div class="col-lg-4">
                        <small class="font-weight-bold">End</small>
                        <p class="m-0">{{ selectedSlot.end_at | moment('MM/DD hh:mm A') }}</p>
                    </div>

                    <div class="col-lg-12 mt-sm" v-if="type === 'create'">
                        <b-form-checkbox
                            v-model="values.is_time_confirmed"
                            :value="true"
                            :unchecked-value="false"
                        >I confirm that I am available for a sponsored stream during the specified time slot above that has been adjusted to my browser's timezone.
                        </b-form-checkbox>
                    </div>

                    <div class="col-lg-12 mt-3" v-if="type === 'create'">
                        <b-form-checkbox
                            v-model="values.is_punctuality_confirmed"
                            :value="true"
                            :unchecked-value="false"
                        >I confirm that if chosen, I will not be late to stream for my broadcast slot.
                        </b-form-checkbox>
                    </div>
                </div>
            </form>
        </b-modal>
    </div>
</template>

<script>
import _ from 'lodash'

export default {
    name: 'slot-application-modal',

    props: {
        type: {
            type: String,
            default: () => 'create',
        },
        campaign: {
            type: Object,
        },
        selectedSlot: {
            type: Object,
        },
        timezoneText: {
            type: String,
        },
    },

    data() {
        return {
            defaults: {
                is_terms_agreed: false,
                is_punctuality_confirmed: false,
            },

            values: null,
        }
    },

    created() {
        this.resetState()
    },

    methods: {
        resetState() {
            this.values = _.cloneDeep(this.defaults)
        },

        onKeyupEnter() {
            this.onSubmit()
            this.onHide()
        },

        onSubmit() {
            let data

            this.$emit('submit', this.type, this.selectedSlot)
            this.resetState()
        },

        onHide(event) {
            this.$emit('close', event)
            this.resetState()
        },
    }
}
</script>

<style lang="scss">
.slot-application-modal-wrapper {
}
</style>
