<template>
<SlideYUpTransition :duration="animationDuration">
    <div class="modal fade" @click.self="closeModal" :class="[{'show d-block': show}, {'d-none': !show}, {'modal-mini': type === 'mini'}]" v-show="show" tabindex="-1" role="dialog" :aria-hidden="!show">

        <div class="modal-dialog modal-dialog-centered" :class="[{'modal-notice': type === 'notice'}, modalClasses]">
            <div class="modal-content" :class="[gradient ? `bg-gradient-${gradient}` : '',modalContentClasses]">

                <div class="modal-header" :class="[headerClasses]" v-if="$slots.header">
                    <slot name="header"></slot>
                    <slot name="close-button">
                        <button type="button" class="close" v-if="showClose" @click="closeModal" data-dismiss="modal" aria-label="Close">
                            <span :aria-hidden="!show">×</span>
                        </button>
                    </slot>
                </div>

                <div class="modal-body" :class="bodyClasses">
                    <slot name='body'></slot>
                </div>

                <div class="modal-footer" :class="footerClasses" v-if="$slots.footer">
                    <slot name="footer"></slot>
                </div>
            </div>
        </div>

    </div>
</SlideYUpTransition>
</template>
<script>
import {
    SlideYUpTransition
} from "vue2-transitions";

export default {
    name: "modal",
    components: {
        SlideYUpTransition
    },
    props: {
        show: Boolean,
        showClose: {
            type: Boolean,
            default: true
        },
        type: {
            type: String,
            default: "",
            validator(value) {
                let acceptedValues = ["", "notice", "mini"];
                return acceptedValues.indexOf(value) !== -1;
            },
            description: 'Modal type (notice|mini|"") '
        },
        modalClasses: {
            type: [Object, String],
            description: "Modal dialog css classes"
        },
        modalContentClasses: {
            type: [Object, String],
            description: "Modal dialog content css classes"
        },
        gradient: {
            type: String,
            description: "Modal gradient type (danger, primary etc)"
        },
        headerClasses: {
            type: [Object, String],
            description: "Modal Header css classes"
        },
        bodyClasses: {
            type: [Object, String],
            description: "Modal Body css classes"
        },
        footerClasses: {
            type: [Object, String],
            description: "Modal Footer css classes"
        },
        animationDuration: {
            type: Number,
            default: 500,
            description: "Modal transition duration"
        }
    },
    methods: {
        closeModal() {
            this.$emit("update:show", false);
            this.$emit("close");
        }
    },
    watch: {
        show(val) {
            let documentClasses = document.body.classList;
            if (val) {
                documentClasses.add("modal-open");
            } else {
                documentClasses.remove("modal-open");
            }
        }
    }
};
</script>
<style lang="scss">
.modal.show {
    background-color: rgba(0, 0, 0, 0.3);

    .modal-content {
        background-color: #1e2029;
    }

    .modal-header {
        border-bottom: 1px solid rgba(255, 255, 255, 0.05);

        .modal-title {
            font-size: 1.25em;
        }

        .close span {
            color: rgba(255, 255, 255, 0.5) !important;
            font-size: 1.25em;

            &:hover {
                color: #fcd878 !important;
            }
        }
    }

    .modal-footer {
        border-top: 1px solid rgba(255, 255, 255, 0.05);
        justify-content: center;
    }
}
</style>
