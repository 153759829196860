<template>
<div class="page page-login">
    <div class="brand">
        <a href="/">
            <img src="/img/brand/streamforge-logo-text.png" height="50px">
        </a>
    </div>

    <div class="container my-auto">
        <div class="row my-md">
            <div class="col-lg-4 offset-lg-4">
                <div class="panel">
                    <h2>Welcome!</h2>
                    <div class="h2 text-gray">How would you like to sign up?</div>

                    <a :href="registerUrl('twitch')" class="w-100 mt-3">
                        <button class="btn btn-twitch w-100">
                            <span class="btn-inner--icon">
                                <font-awesome-icon :icon="['fab', 'twitch']" size="lg" />
                            </span>
                            <span class="btn-inner--text ml-2">Twitch</span>
                        </button>
                    </a>

                    <a :href="registerUrl('youtube')" class="w-100 mt-3">
                        <button class="btn btn-youtube w-100">
                            <span class="btn-inner--icon">
                                <font-awesome-icon :icon="['fab', 'youtube']" size="lg" />
                            </span>
                            <span class="btn-inner--text ml-2">Youtube</span>
                        </button>
                    </a>
                </div>

                <div class="text-small form-subtext">
                    <div class="text-center">Already have an account?
                        <router-link :to="{ name: 'login' }">Sign In</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'

export default {
    computed: {
        ...mapGetters([
            'isAuthenticated',
            'redirect',
        ]),
    },

    created() {
        const platformIds = ['twitch', 'youtube']

        _.forEach(platformIds, (platformId) => {
            if (this.$route.name === `register-${platformId}`) {
                if (this.$route.query.code && this.$route.query.scope) {
                    const params = {
                        code: this.$route.query.code,
                        scope: this.$route.query.scope,
                    }

                    this.axios.get(`/register/${platformId}/callback`, { params })
                        .then(this.handleRegisterSuccess)
                        .catch(this.handleRegisterFailure)
                }
            }
        })

        if (this.isAuthenticated) {
            this.$router.push({ name: 'home' })
        }
    },

    methods: {
        registerUrl(platformId) {
            return `${process.env.VUE_APP_ROOT_API}/register/${platformId}`
        },

        handleRegisterSuccess({ data }) {
            const jwtToken = _.get(data, 'payload.passport.payload')
            this.$store.commit('authentication:login', jwtToken)

            if (this.redirect) {
                const redirectDestinationPath = this.redirect
                this.$store.commit('authentication:redirect', false)
                this.$router.push({ path: redirectDestinationPath })
            } else {
                this.$router.push({ name: 'home' })
            }
        },

        handleRegisterFailure(error) {
            const errorMessage = _.get(error, 'response.data.message')
            let message = `Something went wrong on our end. Please try logging in again.`
            if (errorMessage) message = errorMessage

            this.$notify({
                group: 'global',
                type: 'error',
                title: 'Error - Login',
                text: message,
                duration: 10000,
                speed: 1000,
            })
        },
    }
}
</script>
<style lang="scss" media="screen">
.page-login {
    .brand {
        display: fixed;
        top: 0;
        left: 0;
        width: 250px;
        padding: 25px;
    }

    .panel {
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-align: start;
        -webkit-align-items: flex-start;
        -ms-flex-align: start;
        align-items: flex-start;
        background-color: hsla(0, 0%, 100%, .05);
        direction: ltr;
        border-radius: 12px;
        padding: 36px;
    }

    h2, .h2 {
        font-size: 32px;
        line-height: 40px;
        font-weight: 600;
        letter-spacing: -.02em;
    }

    .text-gray {
        color: #8f9094;
    }

    .button-login {
        margin-top: 24px;

        button {
            cursor: pointer;
            display: flex;
            height: 60px;
            padding-right: 36px;
            padding-left: 36px;
            -webkit-justify-content: center;
            -ms-flex-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            -webkit-align-items: center;
            -ms-flex-align: center;
            align-items: center;
            border: 1px solid transparent;
            border-radius: 6px;
            background-color: #ff715b;
            box-shadow: inset 0 0 20px 20px transparent;
            transition: box-shadow .2s ease;
            text-align: center;
            color: #fff;
            -webkit-box-pack: center;
            -webkit-transition: box-shadow .2s ease;
            font-weight: 600;
            width: 100%;

            &:hover {
                box-shadow: inset 0 0 20px 20px rgba(0,0,0,.1);
            }
        }
    }

    .form-subtext {
        margin-top: 24px;
        font-weight: 600;

        a {
            color: #fff;
        }
    }
}
</style>
