<template>
    <div class="showcase-slider px-md">
        <swiper ref="swiper" :options="sliderOptions" key="items">
            <swiper-slide
                v-for="(video, index) in videos"
                class="col-asset"
                :class="{ 'selected': selected === video.youtube_id }"
            >
                <div class="spacer"></div>

                <div
                    class="image-asset"
                    :style="`background-image: url(https://img.youtube.com/vi/${video.youtube_id}/0.jpg)`"
                ></div>

                <div class="video-asset" v-if="selected && selected === video.youtube_id">
                    <iframe
                        frameborder="0"
                        scrolling="no"
                        marginheight="0"
                        marginwidth="0"
                        width="100%"
                        height="100%"
                        type="text/html"
                        :src="`https://www.youtube.com/embed/${video.youtube_id}?autoplay=1&fs=0&iv_load_policy=3&showinfo=0&rel=0&cc_load_policy=0&start=0&end=0`"
                    ></iframe>
                </div>

                <div class="label py-3 px-4">{{ video.name }}</div>

                <div class="action py-3 px-4" @click="expandAsset(video.youtube_id)">
                    <font-awesome-icon :icon="['fas', 'play']" size="lg" />
                </div>
            </swiper-slide>

            <swiper-slide
                v-for="(screenshot, index) in screenshots"
                class="col-asset"
                :class="{ 'selected': selected === screenshot.id }"
            >
                <div class="spacer"></div>

                <div
                    class="image-asset"
                    :style="`background-image: url(${screenshot.url})`"
                ></div>

                <div class="label py-3 px-4">{{ screenshot.name }}</div>
            </swiper-slide>

            <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
            <div class="swiper-button-next swiper-button-white" slot="button-next"></div>
        </swiper>
    </div>
</template>

<script>
export default {
    name: 'showcase-slider',

    props: {
        videos: {
            type: Array,
        },

        screenshots: {
            type: Array,
        },
    },

    data() {
        return {
            selected: null,

            sliderOptions: {
                spaceBetween: 5,
                loop: false,
                direction: 'horizontal',
                pagination: true,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
                centeredSlides: false,
                allowTouchMove: false,
                breakpointsInverse: true,
                breakpoints: {
                    2160: {
                        slidesPerView: 3,
                        slidesPerGroup: 1
                    },
                    1440: {
                        slidesPerView: 2,
                        slidesPerGroup: 1
                    },
                    1200: {
                        slidesPerView: 2,
                        slidesPerGroup: 1
                    },
                    992: {
                        slidesPerView: 2,
                        slidesPerGroup: 1
                    },
                    768: {
                        slidesPerView: 2,
                        slidesPerGroup: 1
                    },
                    576: {
                        slidesPerView: 1,
                        slidesPerGroup: 1
                    }
                }
            }
        }
    },

    methods: {
        expandAsset(id) {
            if (this.selected === id) this.selected = false
            else this.selected = id
        },
    }
}
</script>

<style lang="scss">
.showcase-slider {
    .col-asset {
        overflow: hidden;
        border-radius: 5px;
        position: relative;
        display: flex;

        &:hover {
            .action {
                animation: float-subtle 1.5s infinite;
            }
        }

        .spacer {
            width: 100%;
            height: 0;
            position: relative;
            overflow: hidden;
            padding: 28.125% 0;
        }

        &.selected {
            .action, .label {
                display: none;
            }

            .image-asset:before {
                opacity: 0;
                z-index: -1;
            }
        }

        .image-asset, .video-asset {
            height: 100%;
            width: 100%;
            background-size: cover;
            background-position: center;
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 5px;

            iframe {
                border-radius: 5px;
            }
        }

        .image-asset {
            &:before {
                content: '';
                position: absolute;
                width: 100%;
                height: 50%;
                bottom: 0;
                left: 0;
                background: linear-gradient(to top, rgba(0, 0, 0, 0.75), transparent);
                z-index: 1;
                border-radius: 5px;
                transition: 0.5s all;
            }
        }

        .label {
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 4;
            transition: all 0.25s;
            color: #eee;
            text-transform: uppercase;
            font-weight: 700;
            letter-spacing: -0.5px;
            text-shadow: 0px 8px 15px rgba(0, 0, 0, 1);
            font-family: 'Inter', sans-serif;
            line-height: 1em;
            transition: all 0.5s;
        }

        .action {
            position: absolute;
            bottom: 0;
            right: 0;
            z-index: 5;
            cursor: pointer;
            transition: all 0.5s;
            color: #ddd;

            svg {
                filter: drop-shadow(0px 8px 15px rgba(0, 0, 0, 0.75));
            }

            &:hover {
                transform: scale(1.1);
                color: #fff;
            }
        }
    }

    .swiper-container {
        overflow: visible;

        &:hover {
            .swiper-button-prev, .swiper-button-next {
                opacity: 1;

                &.swiper-button-disabled {
                    opacity: 0.5;
                }
            }
        }


        .swiper-button-prev, .swiper-button-next {
            padding: 0 20px;
            transition: all 0.25s;
            opacity: 0;
            outline: 0;
            z-index: 25;

            &:hover {
                transform: scale(1.25);
            }
        }

        .swiper-button-next {
            right: -50px;
        }

        .swiper-button-prev {
            left: -50px;
        }
    }
}
</style>
