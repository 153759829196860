<template>
    <div class="chart-wrapper" ref="chartdiv"></div>
</template>

<script>
    import _ from 'lodash'
    import moment from 'moment'

    import * as am4core from '@amcharts/amcharts4/core'
    import * as am4charts from '@amcharts/amcharts4/charts'
    import am4themes_animated from '@amcharts/amcharts4/themes/animated'
    import am4themes_dark from '@amcharts/amcharts4/themes/dark'

    am4core.useTheme(am4themes_dark)
    am4core.useTheme(am4themes_animated)

    export default {
        name: 'recent-streams-bar-chart',

        props: {
            timeseries: Array,
        },

        data() {
            return {
                chart: false,
                fields: [{
                    text: 'AVG Viewers',
                    key: 'avg_viewers',
                }, {
                    text: 'Hours Streamed',
                    key: 'hours_streamed',
                }],
            }
        },

        mounted() {
            this.render(true)
        },

        watch: {
            timeseries(value) {
                if (value && value.length) {
                    this.render(false)
                }
            },
        },

        methods: {
            render(animate = false) {
                this.chart = am4core.create(this.$refs.chartdiv, am4charts.XYChart)

                this.chart.colors.list = [
                    am4core.color("#523CAE"),
                    am4core.color("#4976BD"),
                ]

                this.chart.data = _.map(this.timeseries, (item) => {
                    return {
                        start_date: moment(item.date).format(),
                        end_date: moment(item.date).add(7, 'days').format(),
                        avg_viewers: Math.ceil(item.avg_viewers),
                        hours_streamed: Math.round(item.hours_streamed),
                    }
                })

                this.chart.dateFormatter.inputDateFormat = "YYYY-MM-DDTHH:mm:ssZ"
                // chart.dateFormatter.dateFormat = "hh:mm a"
                this.chart.paddingRight = 0
                this.chart.paddingLeft = 0
                this.chart.paddingTop = 0
                this.chart.paddingBottom = 0

                let dateAxis = this.chart.xAxes.push(new am4charts.DateAxis())
                dateAxis.tooltip.disabled = true
                dateAxis.renderer.grid.template.strokeWidth = 0
                // dateAxis.renderer.minGridDistance = 60
                // dateAxis.renderer.grid.template.location = 0
                // dateAxis.title.text = 'Time';
                // dateAxis.dateFormats.setKey('minute', 'hh:mm a')
                // dateAxis.dateFormats.setKey('hour', 'hh:mm a')
                // dateAxis.periodChangeDateFormats.setKey("minute", "[bold]hh:mm a[/]")
                // dateAxis.periodChangeDateFormats.setKey("hour", "[bold]hh:mm a[/]")

                // dateAxis.dateFormatter = new am4core.DateFormatter()
                // dateAxis.dateFormatter.dateFormat = "MMM DD"
                dateAxis.baseInterval = { timeUnit: "week", count: 1 }


                // AVG Viewers
                let avgViewersValueAxis = this.chart.yAxes.push(new am4charts.ValueAxis())
                avgViewersValueAxis.tooltip.disabled = true
                avgViewersValueAxis.syncWithAxis = this.chart.yAxes.getIndex(0)
                // avgViewersValueAxis.renderer.grid.template.strokeWidth = 0
                avgViewersValueAxis.renderer.grid.template.strokeDasharray = "8,4"

                const avgViewersSeries = this.chart.series.push(new am4charts.LineSeries())
                avgViewersSeries.name = 'AVG Viewers'
                avgViewersSeries.yAxis = avgViewersValueAxis
                avgViewersSeries.stroke = am4core.color('#405dc7')
                avgViewersSeries.strokeWidth = 3
                avgViewersSeries.tensionX = 0.75
                avgViewersSeries.strokeWidth = 3

                // const avgViewersSeriesStrokeGradient = new am4core.LinearGradient()
                // avgViewersSeriesStrokeGradient.addColor(am4core.color('rgba(64, 93, 199, 1)'))
                // avgViewersSeriesStrokeGradient.addColor(am4core.color('rgba(128, 63, 229, 1)'))
                // avgViewersSeriesStrokeGradient.rotation = 180
                // avgViewersSeries.stroke = avgViewersSeriesStrokeGradient

                avgViewersSeries.stroke = am4core.color('#523CAE')
                // avgViewersSeries.tooltip.disabled = true
                // avgViewersSeries.tootip.fillOpacity = 0.5
                // avgViewersSeries.tooltip.stroke = am4core.color(this.color)
                // avgViewersSeries.tooltip.strokeWidth = 1
                // avgViewersSeries.tooltip.fill = am4core.color(this.color)
                avgViewersSeries.fillOpacity = 1

                // avgViewersSeries.tooltip.getFillFromObject = false
                // avgViewersSeries.tooltip.background.fill = am4core.color('#000')
                // avgViewersSeries.tooltip.background.fillOpacity = 0.85
                // avgViewersSeries.tooltip.background.cornerRadiusTopLeft = 5
                // avgViewersSeries.tooltip.background.cornerRadiusTopRight = 5
                // avgViewersSeries.tooltip.background.strokeOpacity = 0
                // avgViewersSeries.tooltip.label.minWidth = 40
                // avgViewersSeries.tooltip.label.minHeight = 40
                // avgViewersSeries.tooltip.label.textAlign = "middle"
                // avgViewersSeries.tooltip.label.textValign = "middle"

                // Set up tooltip
                avgViewersSeries.adapter.add("tooltipText", (text, target, key) => {
                    const startDate = moment(target.tooltipDataItem.dataContext.start_date).format('MMM DD, YYYY')
                    const endDate = moment(target.tooltipDataItem.dataContext.end_date).format('MMM DD, YYYY')

                    let tooltipText = `[bold]${startDate} - ${endDate}[/]\n`

                    // console.log(text, target.dataItem, key, target.dataItem.values.value.value, target.tooltipDataItem.dataContext)


                    this.chart.series.each((item) => {
                        if (!item.isHidden) {
                            tooltipText += "[" + item.stroke.hex + "]●[/] " + item.name + ": {" + item.dataFields.valueY + "}\n"
                        }
                    })

                    return tooltipText
                })

                avgViewersSeries.tooltip.getFillFromObject = false
                avgViewersSeries.tooltip.background.fill = am4core.color("#000")
                avgViewersSeries.tooltip.background.opacity = 0.9
                avgViewersSeries.tooltip.label.fill = am4core.color("#fff")
                avgViewersSeries.tooltip.fillOpacity = 1
                avgViewersSeries.tooltip.background.cornerRadius = 0
                avgViewersSeries.tooltip.background.strokeWidth = 0

                // Prevent cross-fading of tooltips
                avgViewersSeries.tooltip.defaultState.transitionDuration = 0
                avgViewersSeries.tooltip.hiddenState.transitionDuration = 0
                avgViewersSeries.zIndex = 5

                const avgViewersSeriesFillGradient = new am4core.LinearGradient()
                avgViewersSeriesFillGradient.addColor(am4core.color('#523CAE'), 0.25)
                avgViewersSeriesFillGradient.addColor(am4core.color('#523CAE'), 0)
                avgViewersSeriesFillGradient.rotation = 90
                avgViewersSeries.fill = avgViewersSeriesFillGradient

                avgViewersSeries.dataFields.dateX = "start_date"
                avgViewersSeries.dataFields.valueY = "avg_viewers"


                // Hours Streamed
                let hoursStreamedValueAxis = this.chart.yAxes.push(new am4charts.ValueAxis())
                hoursStreamedValueAxis.tooltip.disabled = true
                hoursStreamedValueAxis.renderer.opposite = true
                // hoursStreamedValueAxis.title.text = "Hours Streamed"
                hoursStreamedValueAxis.renderer.grid.template.strokeWidth = 0

                let hoursStreamedSeries = this.chart.series.push(new am4charts.ColumnSeries())
                hoursStreamedSeries.name = 'Hours Streamed'
                hoursStreamedSeries.yAxis = hoursStreamedValueAxis

                // hoursStreamedSeries.tooltip.getFillFromObject = false
                // hoursStreamedSeries.tooltip.background.fill = am4core.color('#000')
                // hoursStreamedSeries.tooltip.background.fillOpacity = 0.85
                // hoursStreamedSeries.tooltip.background.cornerRadiusTopLeft = 5
                // hoursStreamedSeries.tooltip.background.cornerRadiusTopRight = 5
                // hoursStreamedSeries.tooltip.background.strokeOpacity = 0
                // hoursStreamedSeries.tooltip.label.minWidth = 40
                // hoursStreamedSeries.tooltip.label.minHeight = 40
                // hoursStreamedSeries.tooltip.label.textAlign = "middle"
                // hoursStreamedSeries.tooltip.label.textValign = "middle"

                // hoursStreamedSeries.columns.template.tooltipY = 0
                // hoursStreamedSeries.columns.template.fill = am4core.color('#cda562')

                const hoursStreamedSeriesFillGradient = new am4core.LinearGradient()
                hoursStreamedSeriesFillGradient.addColor(am4core.color('rgba(66, 99, 198, 1)'))
                hoursStreamedSeriesFillGradient.addColor(am4core.color('rgba(106, 200, 151, 1)'))
                hoursStreamedSeriesFillGradient.rotation = 90
                hoursStreamedSeries.columns.template.fill = hoursStreamedSeriesFillGradient
                hoursStreamedSeries.columns.template.stroke = am4core.color('#000000')
                hoursStreamedSeries.columns.template.strokeWidth = 1


                // hoursStreamedSeries.columns.template.propertyFields.fill = "color"
                // hoursStreamedSeries.columns.template.strokeWidth = 0
                hoursStreamedSeries.columns.template.column.cornerRadiusTopLeft = 2.5
                hoursStreamedSeries.columns.template.column.cornerRadiusTopRight  = 2.5
                hoursStreamedSeries.columns.template.width = am4core.percent(20)
                // hoursStreamedSeries.columns.template.fill = am4core.color("#4263C6")
                hoursStreamedSeries.columns.template.strokeWidth = 0
                hoursStreamedSeries.dataFields.dateX = "start_date"
                hoursStreamedSeries.dataFields.valueY = "hours_streamed"
                hoursStreamedSeries.zIndex = 10

                this.chart.legend = new am4charts.Legend()
                this.chart.legend.position = 'top'
                this.chart.legend.valueLabels.template.align = "left"
                this.chart.legend.paddingBottom = 30
                this.chart.cursor = new am4charts.XYCursor()
                this.chart.cursor.maxTooltipDistance = 0
                this.chart.cursor.behavior = "none"
            }
        },

        beforeDestroy() {
            if (this.chart) {
                this.chart.dispose()
            }
        }
    }
</script>

<style lang="scss">
.chart-wrapper {
    width: 100%;
    height: 100%;
}
</style>
