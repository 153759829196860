<template>
    <div class="page-moderation">
        <div class="row row-tab-links mb-5">
            <div class="col-lg-4 d-flex">
                <div class="tab-link mr-5"
                    v-for="tab in tabs"
                    :class="{ 'active': activeTab === tab.key }"
                    @click="setTab(tab.key)">
                    <span>{{ tab.title }}</span>
                </div>
            </div>
        </div>

        <div class="overview-wrapper" v-if="activeTab === 'properties'">
            <div class="row row-payload">
                <div class="col-lg-12 mb-5">
                    <h1 class="m-0">Moderation</h1>
                    <p class="mt-2">You are viewing all data properties for {{ payload ? payload.user.name : 'this user' }}.</p>

                    <div class="mt-3">
                        <router-link :to="{ name: 'admin-user-list' }" class="btn btn-primary">
                            Back to User Search
                        </router-link>

                        <button v-if="self.user.is_administrator" class="btn btn-warning ml-1" @click="loginAsUser">Login as User</button>

                    </div>
                </div>

                <div class="col-lg-12" v-if="payload">
                    <json-view :data="payload" :styles="styles" />
                </div>
                <div class="col-lg-12 text-center" v-if="state === 'retrieving'">
                    <font-awesome-icon class="fa-spin" :icon="['fas', 'circle-notch']" size="4x" />
                </div>
            </div>
        </div>

        <div class="bounties-wrapper" v-if="activeTab === 'bounties'">
            <div class="row row-panels p-2">
                <div class="col-lg-12 mb-4">
                    <h1 class="m-0">Bounties</h1>
                    <p class="mt-2">You are viewing all bounties for {{ payload ? payload.user.name : 'this user' }}. Please never release more than 2 keys for a user based on claims of it not working.</p>
                </div>

                <div class="col-lg-4 p-2 mb-4 d-flex flex-column" v-for="bounty in payload.bounties">
                    <div class="panel p-3">
                        <h4 class="display-5">{{ bounty.campaign.title }}</h4>
                        <p class="my-1">Has key? {{ !!bounty.keycode_id }}</p>
                        <p class="my-1">Created At {{ bounty.created_at | moment('calendar') }}</p>
                    </div>

                    <div class="panel p-3 mt-2 d-flex" v-if="bounty.keycode_id">
                        <button class="btn btn-outline-warning w-50" @click="releaseKey(bounty, false)">Release Key</button>
                        <button class="btn btn-danger w-50" @click="releaseKey(bounty, true)">Delete Key</button>
                    </div>
                </div>

                <!-- <div class="col-lg-12" v-if="!payload.bounties || !payload.bounties.length">
                    <div class="panel p-3">
                        <p class="m-0 text-center">No bounties</p>
                    </div>
                </div> -->
            </div>
        </div>

        <div class="refreshes-wrapper" v-if="activeTab === 'refreshes'">
            <div class="row row-panels">
                <div class="col-lg-12 mb-4">
                    <h1 class="m-0">Refreshes</h1>
                    <p class="mt-2">These are the connected profiles of {{ payload ? payload.user.name : 'this user' }} and when they were refreshed.</p>
                </div>

                <div class="col-lg-4 overflow-hidden mb-3" v-for="(connection, platformkey) in payload.profiles">
                    <div class="panel p-3 d-flex flex-column">
                        <h3 class="display-5">{{ connection.platform_id | capitalize }}</h3>
                        <div v-for="(value, key) in connection.stashes">
                            <span v-if="key && key.includes('_at')">
                                <span class="font-weight-bold mr-2">{{ key }}:</span>
                                <span class="text-muted">{{ getFromNow(value) }}</span>
                            </span>
                            <span v-else>
                                <span class="font-weight-bold mr-2">{{ key }}:</span>
                                <span class="text-muted">{{ value }}</span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="ledger-wrapper" v-if="activeTab === 'ledger'">
            <div class="row row-panels">
                <div class="col-lg-12 mb-4">
                    <h1 class="m-0">Ledger</h1>
                    <p class="mt-2">These are the point gains of {{ payload ? payload.user.name : 'this user' }}.</p>
                </div>

                <div class="col-lg-12">
                    <table class="table">
                        <tbody>
                            <tr v-for="entry in payload.ledgers">
                                <td>{{ entry.method }}</td>
                                <td>{{ entry.source }}</td>
                                <td>{{ entry.amount | numeral('0,0') }}</td>
                                <td>{{ entry.created_at | moment('calendar') }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="col-lg-12" v-if="!payload.ledgers || !payload.ledgers">
                    <div class="panel p-3">
                        <p class="m-0 text-center">No ledger entries</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import _ from 'lodash'
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
    name: 'moderation',
    metaInfo() {
        return {
            title: 'User - Moderation'
        }
    },
    components: {
    },
    data() {
        return {
            state: 'idle',
            payload: null,
            styles: {
                key: '#ffffff',
                valueKey: '#ffffff',
                boolean: '#d28d26'
            },

            activeTab: 'properties',
            tabs: [{
                title: 'Properties',
                key: 'properties'
            }, {
                title: 'Quests',
                key: 'quests'
            }, {
                title: 'Bounties',
                key: 'bounties'
            }, {
                title: 'Refreshes',
                key: 'refreshes'
            }, {
                title: 'Ledger',
                key: 'ledger'
            }]
        }
    },
    computed: {
        ...mapGetters([
            'self'
        ])
    },
    created() {
        if (!this.self.user.is_moderator) {
            this.$router.push({ name: 'landing' })
        }

        this.retrieve()
    },
    methods: {
        getFromNow(timestamp) {
            return moment(timestamp).fromNow()
        },

        retrieve() {
            this.state = 'retrieving'

            this.axios.get(`/admin/users/${this.$route.params.id}`).then((response) => {
                this.payload = response.data.payload
                this.payload.profiles = _.keyBy(this.payload.profiles, item => `${item.platform_id}-${item.id.split('-')[0]}`)
                this.payload.quests = _.keyBy(this.payload.quests, item => `${item.slug}-${item.id.split('-')[0]}`)
                this.payload.bounties = _.keyBy(this.payload.bounties, item => `${item.id.split('-')[0]}`)
                this.payload.notifications = _.keyBy(this.payload.notifications, item => `${item.type}-${item.id.split('-')[0]}`)
                this.state = 'retrieved'
            }).catch((error) => {
                console.error(error)

                this.$notify({
                    group: 'global',
                    type: 'error',
                    title: 'User Retrieval Error',
                    text: error.response.data.message,
                    duration: 10000,
                    speed: 1000
                })
            })
        },

        loginAsUser() {
            this.axios.get(`/admin/users/${this.$route.params.id}/passport`)
                .then(({ data }) => {
                    const jwt = data.payload.payload
                    window.location.href = `/auth/discord/callback?token=${jwt}`
                })
        },

        postQuestComplete(id) {
            this.axios.post(`/admin/quests/${id}/complete`).then(({ data }) => {
                console.log(data)

                const existingQuest = _.find(this.payload.quests, item => item.id === data.payload.id)
                _.assign(existingQuest, data.payload)
            }).catch((error) => {
                console.error(error)

                this.$notify({
                    group: 'global',
                    type: 'error',
                    title: 'Quest Complete Error',
                    text: error.response.data.message,
                    duration: 10000,
                    speed: 1000
                })
            })
        },

        setTab(newTab) {
            this.activeTab = newTab
        },

        releaseKey(bounty, isDelete) {
            this.axios.post(`/admin/keycodes/${bounty.id}/release`, {
                params: {
                    delete: isDelete ? 'true' : 'false'
                }
            }).then(({ data }) => {
                console.log(data)

                const existingBounty = _.find(this.payload.bounties, item => item.id === data.payload.id)
                _.assign(existingBounty, data.payload)
            }).catch((error) => {
                console.error(error)

                this.$notify({
                    group: 'global',
                    type: 'error',
                    title: 'Key Release Error',
                    text: error.response.data.message,
                    duration: 10000,
                    speed: 1000
                })
            })
        }
    }
}
</script>
<style lang="scss">
.page-moderation {
    #json-view {
        overflow: auto;
    }

    .tab-content {
        opacity: 0;
        animation: fade-up 0.25s forwards;
    }

    .row-tab-links {
        .tab-link {
            position: relative;
            font-size: 0.95em;
            font-weight: 600;
            cursor: pointer;
            transition: 0.25s all;
            opacity: 0.75;

            &:hover {
                color: #fff;
                opacity: 1;
            }

            &.active {
                color: #fff;
                opacity: 1;

                &:before {
                    content: '';
                    position: absolute;
                    bottom: -3px;
                    height: 1.5px;
                    width: 100%;
                    left: 0;
                    background: #fcd878;
                }
            }
        }
    }
}
</style>
