import VueMoment from 'vue-moment'
import VueNumeral from 'vue-numeral-filter'
import VuePluralize from '@vuejs-community/vue-filter-pluralize'

const GlobalFilters = {
    install(Vue) {
        Vue.filter('capitalize', function(value) {
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        })

        Vue.filter('titleize', function(value) {
            return value.replace(/(?:^|\s|-)\S/g, x => x.toUpperCase())
        })

        Vue.filter('unslugify', function(value) {
            return value.replace(/-/g, ' ')
        })

        Vue.filter('uppercase', function(value) {
            return value.toUpperCase()
        })

        Vue.use(VueMoment)
        Vue.use(VueNumeral)
        Vue.use(VuePluralize)
    }
}

export default GlobalFilters
