// import '../assets/vendor/nucleo/css/nucleo.css'
import '../assets/scss/argon.scss'
// import '../../node_modules/axios-progress-bar/dist/nprogress.css'
import globalComponents from './globalComponents'
import globalDirectives from './globalDirectives'
import globalFilters from './globalFilters'
import VueLazyload from 'vue-lazyload'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VuexRouterSync from 'vuex-router-sync'
import { loadProgressBar } from 'axios-progress-bar'
import vueSmoothScroll from 'vue2-smooth-scroll'
import VTooltip from 'v-tooltip'

export default {
    install(Vue) {
        Vue.use(globalComponents)
        Vue.use(globalDirectives)
        Vue.use(globalFilters)
        Vue.use(VueLazyload)
        Vue.use(vueSmoothScroll)
        Vue.use(VTooltip)

        const axiosInstance = axios.create({
            baseURL: process.env.VUE_APP_ROOT_API,
            headers: {
                'Content-Type': 'application/json'
            },
            validateStatus: (status) => {
                return (status >= 200 && status < 300) || status === 401
            },
        })

        loadProgressBar(null, axiosInstance)
        Vue.use(VueAxios, axiosInstance)
    }
}
