<template>
    <div class="loader" :style="style">
        <circle-loader
            v-if="type === 'circle'"
            :width="loaderWidth"
            :maxWidth="maxWidth"
            :height="loaderHeight"
            :animation="animation"
            :color="color"
        />
        <rectangle-loader
            v-else
            :width="loaderWidth"
            :maxWidth="maxWidth"
            :height="loaderHeight"
            :animation="animation"
            :type="type"
            :color="color"
            :rounded="rounded"
            :radius="radius"
        />
    </div>
</template>

<script>
import CircleLoader from './Circle.vue';
import RectangleLoader from './Rectangle.vue';
export default {
    components: {
        CircleLoader,
        RectangleLoader,
    },
    props: {
        type: {
            type: String,
            default: 'rect',
        },
        size: {
            type: [Number, String],
        },
        animation: {
            type: String,
            default: 'wave',
        },
        height: {
            type: [Number, String],
        },
        width: {
            type: [Number, String],
        },
        maxWidth: {
            type: [Number, String],
        },
        color: {
            type: String,
            default: '#12141D',
        },
        rounded: {
            type: Boolean,
            default: true,
        },
        radius: {
            type: [Number, String],
            default: 5,
        },
        fitContent: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        loaderWidth() {
            if (this.size) {
                return this.size;
            } else {
                return this.width;
            }
        },
        loaderHeight() {
            if (this.size) {
                return this.size;
            } else {
                return this.height;
            }
        },
        style() {
            if (this.fitContent) {
                return {
                    width: 'fit-content'
                }
            }

            else return {
                width: '100%'
            }
        }
    },
};
</script>

<style>
/* Animation definitions */
@keyframes fade {
    0% {
        opacity: 1;
    }
    25% {
        opacity: 0.6;
    }
    50% {
        opacity: 1;
    }
    75% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}
@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(0.85);
    }
    100% {
        transform: scale(1);
    }
}
@keyframes pulse-x {
    0% {
        transform: scaleX(1);
    }
    50% {
        transform: scaleX(0.75);
    }
    100% {
        transform: scaleX(1);
    }
}
@keyframes pulse-y {
    0% {
        transform: scaleY(1);
    }
    50% {
        transform: scaleY(0.75);
    }
    100% {
        transform: scaleY(1);
    }
}
@keyframes wave {
    0% {
        transform: translateX(-100%);
    }
    50% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(100%);
    }
}
/* Animation classes */
.animation--fade {
    animation: fade 2s linear .5s infinite;
}
.animation--wave::before {
    background: linear-gradient(
        90deg,
        transparent,
        rgba(255, 255, 255, 0.075),
        transparent
    );
    animation: wave 1.5s linear .5s infinite;
}
.animation--pulse-x {
    animation: pulse-x 1.5s linear .5s infinite;
}
.animation--pulse-y {
    animation: pulse-y 1.5s linear .5s infinite;
}
.animation--pulse {
    animation: pulse 1.5s linear .5s infinite;
}
</style>