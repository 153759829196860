<template>
    <section class="page page-settings">
        <div class="row mb-sm">
            <div class="col-lg-8">
                <h1 class="display-2">
                    <font-awesome-icon
                    :icon="['fas', 'cog']"
                    class="my-auto mr-3 text-primary"/>Settings</h1>
                <p>General settings about your account, more to come soon!</p>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-8">
                <h4 class="display-4">Account Deletion</h4>
                <p>
                    Here you can delete your account from our website if you wish to do so.
                </p>

                <p>
                    This action will not work if you redeemed a game key in the last 30 days. Clicking the login button after this action is completed will regenerate a new account, so do not do that afterwards if you wish to remain off the platform.
                </p>

                <p class="font-weight-bold">Having issues? We'd love to help out or answer any questions you may have.</p>

                <p class="text-primary" v-if="deleteState.message">{{ deleteState.message }}</p>


                <button type="button" v-if="!deleteState.confirm" @click="deleteState.confirm = true" class="btn btn-danger mt-3">Delete</button>

                <button type="button" v-if="deleteState.confirm" @click="deleteSelf" class="btn btn-danger mt-3">Yes</button>
               <button type="button" v-if="deleteState.confirm" @click="deleteState.confirm = false" class="btn btn-primary mt-3">No</button>

                <button type="button" v-if="!deleteState.confirm" @click="talkWithSupport()" class="btn btn-primary ml-1 mt-3">Talk with Support</button>
            </div>
        </div>
    </section>

</template>
<script>
import { mapGetters } from 'vuex'
import _ from 'lodash'
import { is } from '@amcharts/amcharts4/.internal/themes/ITheme'

export default {
    name: 'settings-view',

    metaInfo() {
        return {
            title: 'Settings'
        }
    },

    data() {
        return {
            deleteState: {
                confirm: false,
                message: false
            },
        }
    },


    methods: {
        talkWithSupport() {
            this.$intercom.show()
        },

        deleteSelf() {
            this.axios.delete('/me').then(({ status, data }) => {
                this.$store.commit('authentication:logout')
                console.log(`deleted!`)
            }).catch(({ message, response }) => {
                console.error(`deleteSelf |`, message)

                if (response && response.status) {
                    if (response.status === 400) {
                        this.deleteState.message = response.data.message
                        return
                    }

                    if (response.status === 429) {
                        this.deleteState.message = `Too many requests, please try again in a little while.`
                        return
                    }
                }
            })
        },
    }
}
</script>
<style lang="scss">
.page-settings {

}
</style>
