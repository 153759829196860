<template>
    <div class="page page-campaign-invite">
        <div
            class="backdrop"
            v-if="backdrop"
            :style="{
                'background-image': `url(${backdrop})`
            }"
        >
            <div class="overlay-gradient" />
        </div>

        <div class="brand p-4">
            <img src="/img/brand/streamforge-logo-text.png" height="50px">
        </div>

        <section class="section section-lg section-wizard" v-if="campaign">
            <div class="container">
                <div class="row d-flex justify-content-center">
                    <div class="col-lg-6">
                        <div class="row row-panels mb-3">
                            <div class="col-lg-12 mb-sm" v-if="isAuthenticated">
                                <div class="d-flex">
                                    <img class="avatar avatar-xl mx-auto" :src="self.avatar" :alt="self.user.name">
                                </div>

                                <p class="font-weight-bold text-center mt-3 mb-0">Logged in as {{ self.user.name }}</p>
                                <p class="not-you-wrapper d-flex m-0">
                                    <a class="text-center text-primary mx-auto" href="/login" @click.prevent="onNotYouClick">Not you?</a>
                                </p>
                            </div>

                            <div class="col-lg-12">
                                <div class="panel py-3 px-4">
                                    <p class="mt-2 mb-sm">
                                        You have been invited to be a part of the <span class="text-primary font-weight-bold">{{ campaign.title }}</span>
                                        campaign. We are very excited to bring you aboard!
                                    </p>

                                    <div class="authenticated-wrapper" v-if="isAuthenticated">
                                        <div class="d-flex justify-content-end">
                                            <button class="btn btn-primary" @click="onAcceptClick">
                                                Accept Invite
                                            </button>
                                        </div>
                                    </div>

                                    <div class="unauthenticated-wrapper" v-else>
                                        <h2 class="h3 m-0">What's Next?</h2>

                                        <ul class="lead mt-2 mb-3">
                                            <li>Register a Streamforge account</li>
                                            <li>Link the accounts you'll create the content on</li>
                                            <li>View the tasks to complete for the campaign</li>
                                        </ul>

                                        <div class="d-flex justify-content-end mt-md">
                                            <button class="btn btn-outline-primary" @click="onLoginClick">
                                                Login
                                            </button>

                                            <button class="btn btn-primary" @click="onLoginClick">
                                                Register
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script type="text/javascript">
    import _ from 'lodash'
    import { mapGetters } from 'vuex'

    export default {
        name: 'campaign-invite',

        metaInfo() {
            return {
                title: 'Campaign Invite'
            }
        },

        data() {
            return {
                campaign: null,
                participant: null,
            }
        },

        created() {
            this.$store.commit('authentication:redirect', false)
            this.getCampaignByInviteCode()
        },

        computed: {
            ...mapGetters([
                'self',
                'colors',
                'onboarding',
                'isAuthenticated'
            ]),

            backdrop() {
                if (!this.campaign) return false

                if (_.has(this.campaign, 'payload.game.cover_url')) {
                    return this.campaign.payload.game.cover_url
                }

                return false
            },
        },

        methods: {
            getCampaignByInviteCode() {
                const inviteCode = this.$route.params.invite_code

                this.axios.get(`/crm/campaigns/invites/${inviteCode}`).then(({ data }) => {
                    if (data.payload.campaign) {
                        this.campaign = data.payload.campaign

                        if (data.payload.campaign.participant) {
                            this.participant = data.payload.campaign.participant
                        }
                    }
                }).catch((error) => {

                    return

                    // If no invite was found, redirect to root
                    this.$router.push({ name: 'not-found' })
                })
            },

            onAcceptClick() {
                const inviteCode = this.$route.params.invite_code

                this.axios.put(`/crm/campaigns/invites/${inviteCode}/accept`).then(({ data }) => {
                    this.$router.push({
                        name: 'campaign',
                        params: {
                            id: this.campaign.id,
                        },
                    })
                }).catch((error) => {
                    console.log(error.message)
                })
            },

            onLoginClick() {
                this.$store.commit('authentication:redirect', {
                    name: this.$route.name,
                    params: {
                        invite_code: this.$route.params.invite_code,
                    },
                })

                window.location = `${process.env.VUE_APP_ROOT_API}/auth/discord`
            },

            onNotYouClick() {
                this.$store.commit('authentication:redirect', {
                    name: this.$route.name,
                    params: {
                        invite_code: this.$route.params.invite_code,
                    },
                })

                this.$router.push({ name: 'logout' })
            }
        }
    }
</script>

<style lang="scss">
.page-campaign-invite {
    position: relative;

    .backdrop {
        position: absolute;
        top: 0;
        left: 0;
        height: 100vh;
        width: 50vw;
        background-repeat: no-repeat;
        background-size: cover;
        opacity: 0.5;

        .overlay-gradient {
            background: linear-gradient(to left, rgba(18, 20, 29, 1) 10%, rgba(18, 20, 29, 0.9) 75%, rgba(18, 20, 29, 0.8) 100%);
            width: 100%;
            height: 100%;
        }
    }

    .brand {
        z-index: 5;
    }
}
</style>
