<template>
    <div class="page page-account-link">
        <div class="spacer"></div>

        <div class="authentication-loader">
            <transition name="fade">
                <div class="icons-wrapper" v-if="!error">
                    <div class="icons" >
                        <div class="loading-icon" style="animation-delay: 0s;">
                            <font-awesome-icon
                                :icon="['fab', 'discord']"
                                size="8x"
                                :style="{
                                    'color': `${colors['socials']['discord']} !important`
                                }"
                            />
                        </div>

                        <div class="loading-icon" style="animation-delay: 1s;">
                            <font-awesome-icon
                                :icon="['fab', 'twitch']"
                                size="8x"
                                :style="{
                                    'color': `${colors['socials']['twitch']} !important`
                                }"
                            />
                        </div>

                        <div class="loading-icon" style="animation-delay: 2s;">
                            <font-awesome-icon
                                :icon="['fab', 'twitter']"
                                size="8x"
                                :style="{
                                    'color': `${colors['socials']['twitter']} !important`
                                }"
                            />
                        </div>

                        <div class="loading-icon" style="animation-delay: 3s;">
                            <font-awesome-icon
                                :icon="['fab', 'youtube']"
                                size="8x"
                                :style="{
                                    'color': `${colors['socials']['youtube']} !important`
                                }"
                            />
                        </div>

                        <div class="loading-icon" style="animation-delay: 4s;">
                            <font-awesome-icon
                                :icon="['fab', 'instagram']"
                                size="8x"
                                :style="{
                                    'color': `${colors['socials']['instagram']} !important`
                                }"
                            />
                        </div>
                    </div>
                </div>
            </transition>

            <transition name="fade">
                <div class="error text-center" v-if="error">
                    <font-awesome-icon
                        class="platform-icon mb-4"
                        :icon="['fab', platform_id]"
                        size="8x"
                        :style="{
                            'color': `${getColor('socials', platform_id)} !important`
                        }"
                    />

                    <h2 class="display-4 mb-1">Error Linking Account</h2>
                    <p class="lead mt-3 mb-4">There was an error while linking your account. Please try again and if the error persists, please reach out to the support team using the chat bubble in the bottom right of your dashboard.</p>

                    <div class="actions">
                        <div class="action-wrapper">
                            <a
                                class="btn btn-follow btn-icon btn-primary"
                                @click.prevent="exit()"
                            >
                                <span class="btn-inner--icon mr-2">
                                    <font-awesome-icon :icon="['fas', 'sign-out-alt']" size="lg" />
                                </span>

                                <span class="nav-link-inner--text">Return</span>
                            </a>
                        </div>

                        <div class="action-wrapper">
                            <a
                                class="btn btn-follow btn-icon btn-primary"
                                @click.prevent="retryUrl()"
                            >
                                <span class="btn-inner--icon mr-2">
                                    <font-awesome-icon :icon="['fas', 'redo']" size="lg" />
                                </span>
                                <span class="nav-link-inner--text">Retry</span>
                            </a>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>
<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'

export default {
    name: 'account-link-view',

    data() {
        return {
            error: false,
            errorMessage: false,
            platform_id: false
        }
    },

    computed: {
        ...mapGetters([
            'tokens',
            'isAuthenticated',
            'self',
            'onboarding',
            'redirect',
            'colors',
            'getColor',
            'userProfiles',
        ])
    },

    created() {
        const platformIds = ['twitch', 'youtube', 'discord', 'twitter', 'paypal']

        _.forEach(platformIds, (platformId) => {
            if (this.$route.name === `link-${platformId}`) {
                this.axios.get(`/link/${platformId}/callback`, {
                    params: this.$route.query,
                    withCredentials: true,
                }).then(this.handleLoginSuccess).catch(this.handleLoginFailure)
            }
        })

        setTimeout(() => {
            if (!this.error) this.error = `Authentication timed out.`
        }, 1000 * 5)
    },

    methods: {
        exit() {
            this.$router.push({ name: 'connections' })
        },

        retryUrl() {
            const platformId = _.last(this.$route.name.split('-'))
            return `${process.env.VUE_APP_ROOT_API}/link/${platformId}`
        },

        handleLoginSuccess({ data }) {
            // Persist the profile
            this.$store.commit('profiles:set', {
                platform_id: data.payload.profile.platform_id,
                profile: data.payload.profile.payload
            })

            if (this.redirect) {
                const redirectDestinationPath = this.redirect
                this.$store.commit('authentication:redirect', false)
                this.$router.push({ path: redirectDestinationPath })
            } else {
                this.$router.push({ name: 'connections' })
            }
        },

        handleLoginFailure(error) {
            const errorMessage = _.get(error, 'response.data.message')
            let message = `Something went wrong on our end. Please try linking the account again.`
            if (errorMessage) message = errorMessage

            this.error = message

            this.$notify({
                group: 'global',
                type: 'error',
                title: 'Error - Account Link',
                text: message,
                duration: 10000,
                speed: 1000,
            })
        },
    }
}
</script>

<style lang="scss">
.page-account-link {
    .spacer {
        display: flex;
        min-height: 100%;
    }

    .authentication-loader {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        display: flex;
        z-index: 1000;

        .icons-wrapper {
            display: flex;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;

            .icons {
                margin: auto;
                position: relative;
                display: flex;
                flex-direction: row;

                .loading-icon {
                    margin: 0 25px;
                    animation: bounce 5s infinite;
                    animation-timing-function: cubic-bezier(0.280, 0.840, 0.420, 1);
                }
            }
        }

        .error {
            margin: auto;

            .platform-icon {
                &:hover {
                    animation: bounce 3s infinite;
                    animation-timing-function: cubic-bezier(0.280, 0.840, 0.420, 1);
                }
            }

            p {
                max-width: 500px;
            }

            .actions {
                display: flex;
                justify-content: center;

                .action-wrapper {
                    a {
                        color: #111;
                        font-weight: 600;
                    }

                    &:not(:last-child) {
                        margin-right: 1em;
                        border-right: 1px solid rgba(255, 255, 255, 0.1);
                        padding-right: 1em;
                    }
                }
            }
        }
    }
}
</style>
