<template>
    <div class="page page-campaigns">
        <div class="row mb-sm">
            <div class="col-lg-8">
                <h1 class="display-2">
                    <font-awesome-icon
                        :icon="['fab', 'steam']"
                        class="my-auto mr-3 text-primary"/>Steam Broadcasts
                </h1>
                <p>Here is a list of all Steam broadcast opportunities available to you. If you are selected for a campaign, you will be able to view all relevant information regarding your upcoming broadcast.</p>
            </div>
        </div>

        <div class="row row-panels" v-if="campaigns && campaigns.length > 0">
            <div class="col-lg-4 mb-4" v-for="campaign in unlockedCampaigns">
                <campaign-card :campaign="campaign" />
            </div>
        </div>

        <div class="row row-panels" v-if="campaigns && campaigns.length === 0">
            <div class="col-lg-12">
                <div class="panel p-3">
                    <p class="m-0 text-center">It looks like there are no steam broadcast opportunities at the moment.</p>
                </div>
            </div>
        </div>

        <div class="row row-panels" v-else>
            <div class="col-lg-4 mb-4" v-if="!campaigns" v-for="item in 9">
                <div :style="{ padding: '24px', width: '100%', height: '430px', 'background-color': 'rgba(255, 255, 255, 0.05)', 'border-radius': '12px'}">
                    <skeleton-loader :style="{ 'margin-bottom': '1rem' }" :width="'75%'" :height="'1.75em'"></skeleton-loader>
                    <skeleton-loader :style="{ 'margin-bottom': '2rem' }" :width="'3.5rem'" :height="'1.4rem'"></skeleton-loader>
                    <skeleton-loader :style="{ 'margin-bottom': '0.75rem' }" :width="'100%'" :height="'1.25rem'"></skeleton-loader>
                    <skeleton-loader :style="{ 'margin-bottom': '0.75rem' }" :width="'100%'" :height="'1.25rem'"></skeleton-loader>
                    <skeleton-loader :style="{ 'margin-bottom': '9rem' }" :width="'80%'" :height="'1.25em'"></skeleton-loader>
                    <skeleton-loader :style="{ 'margin-bottom': '2rem' }" :width="'100%'" :height="'3rem'"></skeleton-loader>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash'
    import moment from 'moment'
    import numeral from 'numeral'
    import { mapGetters } from 'vuex'

    import BasePagination from '@/components/Base/BasePagination'
    import SkeletonLoader from '@/components/SkeletonLoader/Loader'
    import CampaignCard from '@/components/Cards/CampaignCard'

    export default {
        name: 'campaigns',

        metaInfo() {
            return {
                title: 'Campaigns'
            }
        },

        components: {
            BasePagination,
            SkeletonLoader,
            CampaignCard,
        },

        data() {
            return {
                campaigns: null,
            }
        },

        computed: {
            ...mapGetters([
                'self',
                'colors',
                'getRankByIndex',
            ]),

            unlockedCampaigns() {
                return _.map(this.campaigns, (campaign) => {
                    campaign.is_unlocked = this.isUnlocked(campaign)

                    if (campaign.payload && campaign.payload.min_rank_index) {
                        campaign.min_rank = this.getRankByIndex(campaign.payload.min_rank_index)
                    }

                    const hasScreenshots = campaign.payload
                        && campaign.payload.showcase
                        && campaign.payload.showcase.screenshots
                        && campaign.payload.showcase.screenshots.length

                    if (hasScreenshots) {
                        campaign.background = campaign.payload.showcase.screenshots[0]
                    }

                    return campaign
                })
            },
        },

        created() {
            this.getCampaigns()
        },

        methods: {
            getCampaigns() {
                this.axios.get(`/crm/campaigns`).then(({ data }) => {
                    this.campaigns = _.filter(data.payload.campaigns, item => item.type === 'steam-broadcast')
                }).catch((error) => {

                })
            },

            isUnlocked(campaign) {
                let userRankIndex = _.get(this.self, 'superviews.twitch.rank.index')
                let minRankIndex = campaign.payload.min_rank_index

                if (!userRankIndex) {
                    return false
                }

                if (!minRankIndex || minRankIndex >= userRankIndex) {
                    return true
                }

                return false
            },
        }
    }
</script>

<style lang="scss">
.page-campaigns {
    .row-panels {
        .panel {
            position: relative;
            overflow: hidden;

            .panel-background {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                background-position: center;
                background-size: cover;
                z-index: -1;
            }

            .panel-foreground {
                background: linear-gradient(to top, rgba(30, 32, 41, 1) 0%, rgba(30, 32, 41, 0.95) 50%, rgba(30, 32, 41, 0.8) 100%);

                p {
                    font-size: 1.05em;
                    color: #ddd;
                    max-height: 100px;
                    overflow: hidden;
                    -o-text-overflow: ellipsis;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                }
            }

            .locked-wrapper {
                background: rgba(black, 0.9);
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
            }
        }
    }
}
</style>
