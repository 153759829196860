<template>
    <swiper ref="swiper" :options="options.swiper" key="items" class="mb-5">

        <!-- Patch Notes -->
        <!-- <swiper-slide>
            <div class="row row-panels">
                <div class="col-lg-12">
                    <div class="panel panel-news p-4 px-lg-xl d-flex">
                        <div class="panel-icon d-flex my-auto">
                            <svg
                                aria-hidden="true"
                                focusable="false"
                                data-prefix="fad"
                                data-icon="newspaper"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 576 512"
                                class="svg-inline--fa fa-newspaper m-auto"
                            >
                                <g class="fa-group">
                                    <path fill="currentColor" style="opacity: 0.5;" d="M544 64H96a32 32 0 0 0-32 32v322.21c0 .36-.05.73-.09 1.09 0 .21 0 .42-.08.63s-.08.66-.13 1-.08.46-.12.68-.1.61-.16.91-.11.46-.16.69-.13.59-.21.87-.13.46-.19.69l-.24.84-.24.68c-.09.28-.18.55-.28.82s-.17.45-.26.67-.21.53-.32.79-.2.45-.3.67-.23.51-.35.76l-.33.65c-.13.25-.25.49-.39.74l-.36.63-.42.72-.39.61c-.15.23-.3.46-.46.69l-.42.6-.48.66c-.15.2-.3.39-.46.58s-.33.43-.51.64l-.48.55-.54.61-.51.53c-.18.2-.37.39-.57.59l-.53.5-.6.56-.55.48-.62.52-.58.46-.65.49-.6.43-.68.46c-.2.14-.41.27-.62.4l-.69.43-.65.37c-.23.13-.47.27-.71.39l-.67.34c-.24.13-.48.25-.73.36l-.69.31-.74.32-.71.28-.77.29-.72.24-.78.25-.75.21-.79.21-.76.17-.81.17-.77.14-.82.12-.79.1-.83.09-.81.06h-1.65H528a48 48 0 0 0 48-48V96a32 32 0 0 0-32-32zM304 372a12 12 0 0 1-12 12H140a12 12 0 0 1-12-12v-8a12 12 0 0 1 12-12h152a12 12 0 0 1 12 12zm0-96a12 12 0 0 1-12 12H140a12 12 0 0 1-12-12v-8a12 12 0 0 1 12-12h152a12 12 0 0 1 12 12zm208 96a12 12 0 0 1-12 12H348a12 12 0 0 1-12-12v-8a12 12 0 0 1 12-12h152a12 12 0 0 1 12 12zm0-96a12 12 0 0 1-12 12H348a12 12 0 0 1-12-12v-8a12 12 0 0 1 12-12h152a12 12 0 0 1 12 12zm0-96a12 12 0 0 1-12 12H140a12 12 0 0 1-12-12v-40a12 12 0 0 1 12-12h360a12 12 0 0 1 12 12z" class="fa-secondary"></path>
                                    <path fill="currentColor" d="M292 352H140a12 12 0 0 0-12 12v8a12 12 0 0 0 12 12h152a12 12 0 0 0 12-12v-8a12 12 0 0 0-12-12zm0-96H140a12 12 0 0 0-12 12v8a12 12 0 0 0 12 12h152a12 12 0 0 0 12-12v-8a12 12 0 0 0-12-12zm208 96H348a12 12 0 0 0-12 12v8a12 12 0 0 0 12 12h152a12 12 0 0 0 12-12v-8a12 12 0 0 0-12-12zm0-96H348a12 12 0 0 0-12 12v8a12 12 0 0 0 12 12h152a12 12 0 0 0 12-12v-8a12 12 0 0 0-12-12zM0 128v287.33c0 17.44 13.67 32.18 31.1 32.67A32 32 0 0 0 64 416V96H32a32 32 0 0 0-32 32z" class="fa-primary"></path>
                                </g>
                            </svg>
                        </div>

                        <div class="mx-sm d-flex">
                            <div class="my-auto">
                                <h2 class="display-4 mb-1">New Patch Released!</h2>
                                <p class="m-0">We just launched a new patch with some exciting new changes! Make sure to stay updated by reading more about the changes on our blog.</p>
                            </div>
                        </div>

                        <div class="mx-3 d-flex">
                            <a target="_blank" href="https://streamforge.gg/post/patch-0-2" class="my-auto">
                                <button type="button" class="btn btn-lg btn-primary">Read More</button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </swiper-slide> -->

        <!-- Twitch Team Invite -->
        <!-- <swiper-slide>
            <div class="row row-panels">
                <div class="col-lg-12">
                    <div class="panel panel-news p-4 d-flex justify-content-center align-items-center flex-column d-sm-flex flex-sm-row panel-twitch">
                        <div class="panel-icon d-flex mxy-auto">
                            <font-awesome-icon :icon="['fab', 'twitch']" size="lg" class="m-auto text-muted" />
                        </div>

                        <div class="mx-sm d-flex">
                            <div class="my-2">
                                <h2 class="display-4 mb-1">Twitch Team</h2>
                                <p class="m-0 text-muted">Get invited to one of the largest and most active teams on Twitch</p>
                            </div>
                        </div>

                        <div class="mx-3 my-3 d-flex">
                            <button type="button" class="btn btn-lg" @click.prevent="postTeamInvite">GET INVITED TO THE TEAM</button>
                        </div>
                    </div>
                </div>
            </div>
        </swiper-slide> -->

        <!-- Twitter Account -->
        <!-- <swiper-slide>
            <div class="row row-panels">
                <div class="col-lg-12">
                    <div class="panel panel-news p-4 d-flex justify-content-center align-items-center flex-column d-sm-flex flex-sm-row panel-twitter">
                        <div class="panel-icon d-flex mxy-auto">
                            <font-awesome-icon :icon="['fab', 'twitter']" size="lg" class="m-auto text-muted" />
                        </div>

                        <div class="mx-sm d-flex">
                            <div class="my-2">
                                <h2 class="display-4 mb-1">Follow @streamforgeGG</h2>
                                <p class="m-0 text-muted">Stay up to date by following Streamforge on Twitter</p>
                            </div>
                        </div>

                        <div class="mx-3 my-3 d-flex">
                            <a target="_blank" href="https://twitter.com/streamforgeGG" class="my-auto">
                                <button type="button" class="btn btn-lg">Follow</button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </swiper-slide> -->

        <!-- TikTok Account -->
        <swiper-slide>
            <div class="row row-panels">
                <div class="col-lg-12">
                    <div class="panel panel-news p-4 d-flex justify-content-center align-items-center flex-column d-sm-flex flex-sm-row panel-tiktok">
                        <div class="panel-icon d-flex mxy-auto">
                            <font-awesome-icon :icon="['fab', 'tiktok']" size="lg" class="m-auto text-muted" />
                        </div>

                        <div class="mx-sm d-flex">
                            <div class="my-2">
                                <h2 class="display-4 mb-1">@Streamforge is on TikTok</h2>
                                <p class="m-0 text-muted">Follow us to get curated Twitch clips daily</p>
                            </div>
                        </div>

                        <div class="mx-3 my-3 d-flex">
                            <a target="_blank" href="https://www.tiktok.com/@streamforge" class="my-auto">
                                <button type="button" class="btn btn-lg">FOLLOW US ON TIKTOK</button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </swiper-slide>

        <!-- Discord Account -->
        <swiper-slide>
            <div class="row row-panels">
                <div class="col-lg-12">
                    <div class="panel panel-news p-4 d-flex justify-content-center align-items-center flex-column d-sm-flex flex-sm-row panel-discord">
                        <div class="panel-icon d-flex mxy-auto">
                            <font-awesome-icon :icon="['fab', 'discord']" size="lg" class="m-auto text-muted" />
                        </div>

                        <div class="mx-sm d-flex">
                            <div class="my-2">
                                <h2 class="display-4 mb-1">Where our community lives</h2>
                                <p class="m-0 text-muted">Start networking with fellow creators</p>
                            </div>
                        </div>

                        <div class="mx-3 my-3 d-flex">
                            <a target="_blank" href="https://discord.gg/streamforge" class="my-auto">
                                <button type="button" class="btn btn-lg">JOIN OUR DISCORD</button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </swiper-slide>

        <div class="swiper-pagination mt-3" slot="pagination"></div>
    </swiper>
</template>

<script>
export default {
    data() {
        return {
            options: {
                swiper: {
                    autoHeight: true,
                    spaceBetween: 3,
                    loop: true,
                    direction: 'horizontal',
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true
                    },
                    autoplay: {
                        delay: 7500,
                        disableOnInteraction: true
                    },
                    centeredSlides: false,
                    allowTouchMove: true,
                    breakpointsInverse: true,
                    breakpoints: {
                        576: {
                            slidesPerView: 1,
                            slidesPerGroup: 1
                        }
                    }
                }
            }
        }
    },

    methods: {
        postTeamInvite() {
            this.axios.post(`/team/twitch/invite`).then(() => {
                this.$notify({
                    group: 'global',
                    type: 'success',
                    title: 'Team Invite',
                    text: `You have successfully been invited to the Twitch team. Check your creator dashboard on Twitch.tv!`,
                    duration: 10000,
                    speed: 1000
                })
            }).catch((error) => {
                if (error.response.status === 429) {
                    this.$notify({
                        group: 'global',
                        type: 'error',
                        title: 'Error - Team Invite',
                        text: `Please come back and try this action later.`,
                        duration: 10000,
                        speed: 1000
                    })
                } else {
                    this.$notify({
                        group: 'global',
                        type: 'error',
                        title: 'Error - Team Invite',
                        text: error.response.data.message,
                        duration: 10000,
                        speed: 1000
                    })
                }
            })
        },

        bannerClick(label, action, target) {
            if (action === 'redirect') {
                window.open(target, `_blank`)
            }
        },

        debounce(func, wait, immediate) {
            let timeout

            return function() {
                const context = this, args = arguments
                const later = function() {
                    timeout = null
                    if (!immediate) func.apply(context, args)
                }

                const callNow = immediate && !timeout
                clearTimeout(timeout)
                timeout = setTimeout(later, wait)
                if (callNow) func.apply(context, args)
            }
        },

        bannerMouseOver: _.debounce(function(label, target) {
            console.log(`Banner mouseover`, label, target)

            // this.$mam.onUserHover({
            //     category: 'banner',
            //     label: `banner-hover-${label}`,
            //     value: target
            // })
        }, 5000, {
            leading: true
        })
    }
}
</script>

<style lang="scss">
.swiper-container {
    .swiper-pagination-bullets {
        position: relative !important;

        .swiper-pagination-bullet {
            background: rgba(white, 0.05);
            opacity: 1;
            height: 10px;
            width: 10px;
            transition: 0.25s all;

            &:hover {
                background: #fcd878;
                opacity: 0.25;
            }
        }

        .swiper-pagination-bullet-active {
            background: #fcd878;
            opacity: 0.75;
        }
    }
}

.panel-twitch {
    background: rgba(#6441a5, 0.65) !important;
}

.panel-twitter {
    background: rgba(#38A1F3, 0.65) !important;
}

.panel-tiktok {
    background: rgba(black, 1) !important;
}

.panel-discord {
    background: rgba(#738ADB, 0.65) !important;

    #discord-button {
        color: #fff;
        border-color: #fff;
        border-width: 3px;
        padding: 1rem 2rem;

        &:hover {
            background: rgba(#738ADB, 0.3) !important;
        }
    }
}

.panel-tutorial, .panel-news {
    .panel-icon {
        height: 80px;
        min-width: 80px;
        border-radius: 50%;
        background: rgba(white, 0.05);

        svg {
            font-size: 2.25em;
        }
    }
}

</style>
